import {ExhibitionCardSizes} from "../exhibition/helpers";
import {SmallExperienceCard} from "./SmallExperienceCard";
import {MediumExperienceCard} from "./MediumExperienceCard";
import {LargeExperienceCard} from "./LargeExperienceCard";

export const SizedExperienceCard = ({readMode, size, children}) => {

    if(size === ExhibitionCardSizes.SMALL) {
        return (
            <SmallExperienceCard
                readMode={readMode}
            >
                {children}
            </SmallExperienceCard>
        )
    } else if(size === ExhibitionCardSizes.MEDIUM) {
        return (
            <MediumExperienceCard>
                {children}
            </MediumExperienceCard>
        )
    } else if (size === ExhibitionCardSizes.LARGE) {
        return (
            <LargeExperienceCard
                readMode={readMode}
            >
                {children}
            </LargeExperienceCard>
        )
    } else {
        console.error(`Unsupported card size: ${size}`)
        return null
    }
}