import React from "react";
import Masonry from "react-masonry-css";
import {InfoCard} from "../infoCard/InfoCard";
import {useAppTranslation} from "../app/appContext";
import {createUseStyles} from "react-jss";
import {useNavigate} from "react-router";
import {Typography} from "../typography/Typography";

const useStyles = createUseStyles((theme) => ({
    grid: {
        marginTop: "48px",
        display: "flex",
        maxWidth: "1200px",
        width: "100%",
    },
    gridColumn: {
        margin: "0 24px",
        backgroundClip: "padding-box",
        "&:first-child": { margin: "0 24px 0 0" },
        "&:last-child": { margin: "0 0 0 24px" },
        "@media (max-width: 1000px)": {
            width: "50%!important",
        },
        "@media (max-width: 1024px)": {
            margin: "0 16px",
            "&:first-child": { margin: "0 16px 0 0" },
            "&:last-child": { margin: "0 0 0 16px" },
        },
        "@media (max-width: 700px)": {
            width: "100%!important",
            margin: "0 0",
            "&:first-child": { margin: "0" },
            "&:last-child": { margin: "0" },
        },

        "@media (max-width: 375px)": {
            margin: "0 0",
            "&:first-child": { margin: "0" },
            "&:last-child": { margin: "0" },
        },
    },
    exhibitionCard: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "48px",
    },
    byline: {
        margin: "5px 0 0 0",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        textDecoration: "none",
        color: theme.colors.black,
    },
    museumLogo: {
        width: 16,
        height: 16,
        borderRadius: "30px",
        backgroundColor: theme.colors.white,
        margin: "2px 8px 2px 0",
    },
    initials: {
        width: 16,
        height: 16,
        borderRadius: "30px",
        backgroundColor: theme.colors.white,
        padding: "2px",
        marginRight: "8px",
        fontSize: "8px",
        lineHeight: "16px",
        textDecoration: "none",
    },
    museumName: {
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

export const ExhibitionList = ({
    exhibitions,
    museums,
    showMuseumLink = true,
    className = "",
}) => {
    const t = useAppTranslation();
    const classes = useStyles();
    const navigate = useNavigate()

    const exhibitionClickHandler = (museumId, exhibitionId) =>
        navigate(`/museum/${museumId}/exhibition/${exhibitionId}`);
    return (
        <Masonry
            breakpointCols={{
                default: 3,
                1000: 2,
                700: 1,
            }}
            className={`${classes.grid} ${className}`}
            columnClassName={classes.gridColumn}
        >
            {exhibitions.map((exhibition) => {
                const museum = museums.find(
                    (museum) => museum.id === exhibition.museumId
                );
                return (
                    <div
                        key={exhibition.uniqueId}
                        className={classes.exhibitionCard}
                    >
                        <InfoCard
                            title={exhibition.name}
                            mediaRef={exhibition.preview}
                            content={exhibition.infoText}
                            color={exhibition.color}
                            buttonText={t("infoCardButtonText", "Se mer")}
                            buttonClick={() =>
                                exhibitionClickHandler(
                                    exhibition.museumId,
                                    exhibition.uniqueId
                                )
                            }
                            animationDirection="horizontal"
                            className={classes.card}
                        />
                        {museum && showMuseumLink && (
                            <a
                                className={classes.byline}
                                href={`/museum/${museum.id}`}
                            >
                                {museum.profile ? (
                                    <img
                                        className={classes.museumLogo}
                                        src={`https://${museum.profile}`}
                                        alt="logo"
                                    />
                                ) : (
                                    <Typography
                                        tag="span"
                                        className={classes.initials}
                                    >
                                        {museum.name &&
                                            museum.name
                                                .toUpperCase()
                                                .split(" ")
                                                .slice(-2)
                                                .map((n) => n[0])
                                                .join("")}
                                    </Typography>
                                )}
                                <Typography
                                    tag="span"
                                    size="sm"
                                    type="mono"
                                    className={classes.museumName}
                                >
                                    {museum.name}
                                </Typography>
                            </a>
                        )}
                    </div>
                );
            })}
        </Masonry>
    );
};
