import { useEffect, useState } from "react";

export const useScrollHideMenu = (
    disabled = false,
    ignoreCondition = false,
    initialScrollClass = ""
) => {
    const [scrollClass, setScrollClass] = useState(initialScrollClass);
    const [lastScoll, setLastScroll] = useState(0);
    useEffect(() => {
        if (!disabled) {
            const setScrollDirection = () => {
                const currentScroll = window.pageYOffset;
                if (currentScroll <= 0) {
                    setScrollClass("");
                }

                if (currentScroll > lastScoll && scrollClass !== "scrollDown") {
                    setScrollClass("scrollDown");
                } else if (
                    currentScroll < lastScoll &&
                    scrollClass === "scrollDown"
                ) {
                    setScrollClass("scrollUp");
                }
                setLastScroll(currentScroll);
            };
            document.addEventListener("scroll", setScrollDirection);
            return () =>
                document.removeEventListener("scroll", setScrollDirection);
        }
    }, [scrollClass, lastScoll, setScrollClass, setLastScroll, disabled]);

    document.body.className = !ignoreCondition ? scrollClass : "hidden";
};
