import {Box, Paper} from "@mui/material";
import {EditExperienceMenu} from "./EditExperienceMenu";
import {useAdminProps} from "../admin/adminContext";

export const EditExperience = ({contentId, className = "", children}) => {
    const {
        mobileView,
        adminMode
    } = useAdminProps()

    if(mobileView || !adminMode) {
        return (
            <>
                {children}
            </>
        )
    } else {
        return (
            <Box
                sx={{
                    width: "100%",
                    height: "100%"
                }}
                className={className}
            >
                <Paper
                    sx={{
                        width: 34,
                        ':hover': {
                            boxShadow: theme => theme.shadows[4]
                        },
                        position: "absolute",
                        ml: 3,
                        mt: 3
                    }}
                    elevation={1}
                >
                    <EditExperienceMenu
                        contentId={contentId}
                    />
                </Paper>
                {children}
            </Box>
        )
    }
}