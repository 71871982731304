export const colors = {
    black: "#0C0B0F",
    white: "#FFFFFF",

    athensLightGrey: "#F5F5F7",

    apricot: "#F39680",
    carnation: "#F95A5A",

    peach: "#FDCA96",
    sandy: "#F2AC64",
    zambia: "#FF990E",

    satinLinen: "#F0EDE6",
    raffia: "#EBDDB7",
    hillary: "#AEA386",
    santaFe: "#B16D52",

    sisal: "#D3CBBA",
    springRainGreen: "#ACCBB1",

    emerald: "#63CB9C",

    seaFoamPaleGreen: "#5EE5CC",
    seaFoamDarkGreen: "#00C8A4",

    peranoBlue: "#9EBFF0",
    steelBlue: "#4485C1",
    azureBlue: "#325AAC",

    coldPalePurple: "#979AD8",
    coldDarkPurple: "#7C7FC9",

    vmGray500: "#6A686E",
    vmGray800: "#282530",
    vmGray900: "#1B1821",

    coolGray50: "#F9FAFB",
    coolGray100: "#F3F4F6",
    coolGray200: "#E5E7EB",
    coolGray300: "#D1D5DB",
    coolGray400: "#9CA3AF",
    coolGray500: "#6B7280",
    coolGray600: "#4B5563",
    coolGray700: "#374151",
    coolGray800: "#1F2937",
    coolGray900: "#111827",
};
export const fontSize = {
    xs: "0.75rem",
    sm: "0.875rem",
    base: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
    xl2: "1.5rem",
    xl3: "1.875rem",
    xl4: "2.25rem",
    xl5: "3rem",
    xl6: "3.75rem",
    xl7: "4.5rem",
    xl8: "6rem",
};

export const lineHeight = {
    leadingNone: 1,
    leadingTight: 1.25,
    leadingSnug: 1.375,
    leadingNormal: 1.5,
    leadingRelaxed: 1.6,
    leadingLoose: 2,
    leadingXloose: 2.25,
    leadingXxloose: 2.5,
};

export const theme = {
    colors,
    fontSize,
    lineHeight,
};
