import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({
    mediumContent: {
        padding: "24px 16px",
        // padding: ({ siblings }) =>
        //     siblings === 0 ? "63px 72px" : "24px 16px",
        // alignItems: ({ siblings }) => (siblings > 0 ? "center" : ""),

        // "& p": {
        //     marginTop: ({ siblings }) => (siblings !== 0 ? "4px" : ""),
        // },
        // "@media (max-width: 720px)": {
        //     width: "initial",
        // }
    }
}))
export const ExperienceMediumContent = ({siblings, children}) => {
    const classes = useStyles({
        siblings: siblings
    })
    return (
        <div
            className={classes.mediumContent}
        >
            <div
                className={classes.paddedContent}
            >
                {children}
            </div>
        </div>
    )
}