import {Typography} from "../typography/Typography";
import React, {useState} from "react";
import {createUseStyles} from "react-jss";
import {useAppTranslation} from "../app/appContext";
import {useAdminProps} from "../admin/adminContext";

const useStyles = createUseStyles(({
    textSide: {
        marginLeft: "48px",
        "& h3": {
            fontSize: 36,
            lineHeight: "40px",
            fontWeight: "bold",
        },
        "@media (max-width: 1024px)": {
            margin: "24px 0 0 0",
        },
    },
    moreText: {
        border:"1.5708px dashed",
        borderColor: ({error}) => error ? "#d32f2f" : "#9E9E9E",
        '&:hover': {
            borderColor: "#3F51B5",
        },
        minHeight: "250px",
        maxWidth: "60%",
    },
    editMoreText: {
        background: "none",
        resize: "none",
        outline: "none",
        border:"2px solid",
        borderColor: ({error}) => error ? "#d32f2f" : "#3F51B5",
        width: "100%",
        marginTop: "16px",
        color: "inherit"
    }
}))

export const EditExhibitionInfoText = ({moreText, error}) => {
    const classes = useStyles({error: error})
    const [editing, setEditing] = useState(false)
    const {onChange} = useAdminProps()
    const t = useAppTranslation()
    const placeholder = t(
        'adminInfoTextHelper',
        "Her kan du skrive mer utfyllende om utstillingen, maks {{max}} tegn.",
        {max: 700})

    const infoTextChanged = event => {
        onChange({
            key: "moreText",
            value: event.target.value
        })
    }

    const moreBlock = editing
        ? (
            <Typography
                tag={"textarea"}
                size="lg"
                value={moreText}
                className={classes.editMoreText}
                autoFocus={true}
                onChange={infoTextChanged}
                onBlur={() => setEditing(false)}
                rows={12}
                placeholder={placeholder}
            />
        )
        : (
            <Typography
                tag="p"
                size="lg"
                className={classes.moreText}
                onClick={() => setEditing(true)}
            >
                {moreText || placeholder}
            </Typography>
        )


    return (
        <div className={classes.textSide}>
            <Typography tag="h2" size="xl4" bold>
                {t("exhibitionMoreTitle", "Mer om utstillingen")}
            </Typography>
            {moreBlock}
        </div>
    )
}