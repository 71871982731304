import {Typography} from "../typography/Typography";
import {createUseStyles} from "react-jss";
import {useState} from "react";
import {useAppTranslation} from "../app/appContext";
import {useAdminHeroProps} from "../admin/adminHeroContext";

const useStyles = createUseStyles(({colors, fontSize, lineHeight}) => ({
    heroTitle: {
        color: ({ blackFont }) => (blackFont ? colors.black : colors.white),
        margin: "0 0 20px 0",
        "@media (max-width: 1440px)": {
            fontSize: fontSize.xl7,
            lineHeight: lineHeight.leadingTight,
        },
        "@media (max-width: 1024px)": {
            fontSize: fontSize.xl6,
            lineHeight: lineHeight.leadingTight,
        },
        "@media (max-width: 550px)": {
            fontSize: fontSize.xl4,
            lineHeight: lineHeight.leadingTight,
        },
        border:"1.5708px dashed #9E9E9E",
        '&:hover': {
            borderColor: "#3F51B5",
        },
    },
    editHeroTitle: {
        margin: "0 0 20px 0",
        "@media (max-width: 1440px)": {
            fontSize: fontSize.xl7,
            lineHeight: lineHeight.leadingTight,
        },
        "@media (max-width: 1024px)": {
            fontSize: fontSize.xl6,
            lineHeight: lineHeight.leadingTight,
        },
        "@media (max-width: 550px)": {
            fontSize: fontSize.xl4,
            lineHeight: lineHeight.leadingTight,
        },
        background: "none",
        resize: "none",
        outline: "none",
        border:"2px solid #3F51B5",
        color: ({ blackFont }) => (blackFont ? colors.black : colors.white),
        width: "100%"
    }
}));

export const EditHeroTitle = ({title, blackFont}) => {
    const [editing, setEditing] = useState(false)
    const classes = useStyles({
        blackFont: blackFont,
    })
    const {onChange} = useAdminHeroProps()
    const t = useAppTranslation()
    const placeholder = t(
        'adminHeroTitleHelper',
        "Tekst når fremhevet, maks {{max}} tegn.",
        {
            max: 50
        })

    const heroTitleChanged = event => {
        onChange({
            key: "heroTitle",
            value: event.target.value
        })
    }

    if(editing) {
        return (
            <Typography
                className={classes.editHeroTitle}
                value={title}
                maxLength={50}
                autoFocus={true}
                onChange={heroTitleChanged}
                onBlur={() => setEditing(false)}
                placeholder={placeholder}
                size="xl8"
                bold
                rows={2}
                tag={"textarea"}
            />
        )
    } else {
        return (
            <Typography
                size="xl8"
                className={classes.heroTitle}
                onClick={() => setEditing(!editing)}
            >
                {title || placeholder}
            </Typography>
        )
    }
}