import React, {useState} from "react";
import {createUseStyles} from "react-jss";
import {ExhibitionCardSizes,} from "../exhibition/helpers";
import {getDmsUrl} from "@ekultur/dms-url-generator";
import {ExperienceCardImage} from "./ExperienceCardImage";
import {ExperienceCardContent} from "./ExperienceCardContent";
import {ExperienceCardText} from "./ExperienceCardText";
import {ExperienceCardButton} from "./ExperienceCardButton";
import {CardContentStack} from "./CardContentStack";
import {SizedExperienceCard} from "./SizedExperienceCard";

const useStyles = createUseStyles(({colors}) => ({
    exhibitionCard: {
        width: "100%",
        display: "flex",
        borderRadius: "24px",
        backgroundColor: `${colors.white}`,
        zIndex: 20,
        overflow: "hidden",
        color: colors.black,
    },
    betaLogo: {
        position: "absolute",
        paddingTop: 12,
        paddingLeft: 12,
    }
}))

export const ExperienceCard = ({
    exhibition,
    size = ExhibitionCardSizes.SMALL,
    siblings,
    className = "",
    onClick
}) => {
    const { name, ingress, preview, previewDmsId } = exhibition;
    const classes = useStyles()
    const [readMode, setReadMode] = useState(false)

    const previewImage = preview ? preview : getDmsUrl(previewDmsId, "01")

    return (

        <div className={`${classes.exhibitionCard} ${className}`}>
            <SizedExperienceCard
                size={size}
                readMode={readMode}
            >
                <ExperienceCardImage
                    size={size}
                    previewImage={previewImage}
                />
                <ExperienceCardContent
                    size={size}
                    siblings={siblings}
                >
                    <ExperienceCardText
                        size={size}
                        text={ingress}
                        name={name}
                        readMode={readMode}
                        onToggleReadMode={() => setReadMode(prev => !prev)}
                    />
                    <CardContentStack>
                        <ExperienceCardButton
                            size={size}
                            siblings={siblings}
                            onClick={onClick}
                            exhibition={exhibition}
                        />
                    </CardContentStack>
                </ExperienceCardContent>
            </SizedExperienceCard>
        </div>

    );
};
