import {useMatch} from "react-router";
import {Header} from "../header/Header";
import {useAppState} from "../app/appContext";
import {lazy, Suspense} from "react";
import {LoadingLogo} from "../loading/LoadingLogo";
import {theme} from "../globalJss";
import {PortalNav} from "../header/PortalNav";

const MuseumPageHeader = lazy(() =>
    import("./MuseumPageHeader").then((module) => ({
        default: module.MuseumPageHeader,
    }))
);
const MuseumPageBody = lazy(() =>
    import("./MuseumPageBody").then((module) => ({
        default: module.MuseumPageBody,
    }))
);

export const MuseumPage = () => {
    const { museums } = useAppState();

    const match = useMatch({
        path: "/museum/:id",
        exact: true,
    });
    if (match) {
        const museum = museums.find((m) => m.id === Number(match.params.id));
        return (
            <Suspense fallback={<LoadingLogo />}>
                <Header>
                    <PortalNav
                        color={museum?.light_color || theme.colors.satinLinen}
                        includeBreadcrumb
                        match={match}
                        rootsOnly
                    />
                </Header>
                <MuseumPageHeader museum={museum} museums={museums} />
                <MuseumPageBody museum={museum} museums={museums} />
            </Suspense>
        );
    } else {
        return null;
    }
};
