import {Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper} from "@mui/material";
import {ArrowDropDown, Image} from "@mui/icons-material";
import React from "react";
import {useAppTranslation} from "../app/appContext";
import {useAdminHeroProps} from "../admin/adminHeroContext";
import {FocusPointDialog} from "../media/FocusPointDialog";
import {FocusImgPicker} from "../media/FocusImgPicker";

export const HeroBackgroundButton = () => {
    const t = useAppTranslation()
    const [open, setOpen] = React.useState(false)
    const [showFocusPointDialog, setShowFocusPointDialog] = React.useState(false)
    const anchorRef = React.useRef(null);

    const {
        onChange,
        onMediaSelect,
        data,
    } = useAdminHeroProps()

    const updatedHero = dmsId => {
        if(data.hero) {
            const {url, ...currentHero} = data.hero
            return {
                ...currentHero,
                dmsId: dmsId
            }
        } else {
            return {
                dmsId: dmsId,
                focusX: 0,
                focusY: 0
            }
        }
    }

    const onHeroSelected = dmsId => {
        onChange({
            key: "hero",
            value: updatedHero(dmsId)
        })
    }

    const handleChangeFocusPoint = focusPoint => {
        onChange({
            key: "hero",
            value: {
                ...data.hero,
                focusX: focusPoint.x,
                focusY: focusPoint.y
            }
        })
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }

        setOpen(false);
    }

    const onSelectImage = event => {
        handleClose(event)
        onMediaSelect("image", onHeroSelected)
    }

    const onSelectFocusPoint = event=> {
        setShowFocusPointDialog(true)
        handleClose(event)
    }

    return (
        <>
            <ButtonGroup
                variant="contained"
                color="primary"
                aria-label="hero image and focus point button"
                ref={anchorRef}
            >
                <Button
                    variant={"contained"}
                    onClick={onSelectImage}
                    startIcon={<Image />}
                >
                    {t("hero.selectImage", 'Velg bannerbilde')}
                </Button>
                <Button
                    size="small"
                    aria-controls={open ? 'hero-split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="hero image and focus"
                    aria-haspopup="menu"
                    onClick={handleToggle}
                >
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="hero-split-button-menu">
                                    <MenuItem
                                        onClick={onSelectImage}
                                    >
                                        {t("hero.selectImage", 'Velg bannerbilde')}
                                    </MenuItem>
                                    <MenuItem
                                        disabled={!data.hero}
                                        onClick={onSelectFocusPoint}
                                    >
                                        {t('selectFocusPoint', 'Velg fokuspunkt')}
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <FocusPointDialog
                open={showFocusPointDialog}
                onClose={() => setShowFocusPointDialog(false)}

            >
                <FocusImgPicker
                    mediaRef={data.hero}
                    onFocusChanged={handleChangeFocusPoint}
                />
            </FocusPointDialog>
        </>
    )
}