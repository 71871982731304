import {useBreakpoints} from "./useBreakpoints";

export const useCssBreakpoints = () => {
    const breakpoints = useBreakpoints()
    return {
        extraSmall: `@media (max-width: ${breakpoints.extraSmall}px)`,
        small: `@media (max-width: ${breakpoints.small}px)`,
        medium: `@media (max-width: ${breakpoints.medium}px)`,
        large: `@media (max-width: ${breakpoints.large}px)`
    }
}

export const up = breakpoint => {
    return `(min-width:${breakpoint}px)`
}

export const down = breakpoint => {
    return `(max-width:${breakpoint}px)`
}