import {useMediaQuery} from "../layout/useMediaQuery";
import {down} from "../layout/breakpoints";
import {useBreakpoints} from "../layout/useBreakpoints";
import {ExperienceRow} from "./ExperienceRow";

export const Experiences = ({exhibition, startExhibition}) => {
    const breakpoints = useBreakpoints()
    const matchSmallDown = useMediaQuery(down(breakpoints.small))
    const multiplyExhibitionContents = (row) => {
        const item = exhibition.exhibitionContents.filter(
            (ec) => ec.exhibitionType.type === row.type
        );
        return [...item, ...item, ...item, ...item];
    }

    if(exhibition.layout && 0 < exhibition.layout.length) {
        if(matchSmallDown) {
            const contentWithLayoutPriority = exhibition.exhibitionContents.map(ec => ({
                ...ec,
                priority: exhibition.layout.find(l => l.type === ec.exhibitionType.type).priority,
                size: exhibition.layout.find(l => l.type === ec.exhibitionType.type).size
            }))
            return (
                contentWithLayoutPriority
                    .sort((a, b) => a.priority - b.priority)
                    .map((ec) => (
                        <ExperienceRow
                            key={`exhibitionRow-${ec.contentId}`}
                            size={ec.size}
                            exhibitionContents={[ec]}
                            startExhibition={startExhibition}
                        />
                    ))
            )
        } else {
            return exhibition.layout
                .sort((a, b) => a.priority - b.priority)
                .map((row) => (
                    <ExperienceRow
                        key={`exhibitionRow-${row.size}-${row.priority}`}
                        size={row.size}
                        exhibitionContents={exhibition.exhibitionContents.filter((ec) => ec.exhibitionType.type ===row.type)}
                        startExhibition={startExhibition}
                    />
                ))
        }

    } else {
        return (
            exhibition.exhibitionContents
                .sort((a, b) => a.priority - b.priority)
                .map((ec) => (
                    <ExperienceRow
                        key={`exhibitionRow-${ec.contentId}`}
                        size={"large"}
                        exhibitionContents={[ec]}
                        startExhibition={startExhibition}
                    />
                ))
        )
    }
}