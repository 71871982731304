import {ExhibitionCardSizes, getIconByType} from "../exhibition/helpers";
import {Button} from "../button/Button";
import React from "react";
import {useAppTranslation} from "../app/appContext";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({
    startButton: {
        marginTop: ({ siblings, size }) => {
            if (size === ExhibitionCardSizes.SMALL && siblings === 0) {
                return "16px";
            } else {
                return "12px";
            }
        },
    },
}))

export const ExperienceCardButton = ({size, siblings, exhibition, onClick}) => {
    const t = useAppTranslation()
    const classes = useStyles({
        size: size,
        siblings: siblings
    })

    const buttonSize = {
        [ExhibitionCardSizes.SMALL]: "small",
        [ExhibitionCardSizes.MEDIUM]: "normal",
        [ExhibitionCardSizes.LARGE]: "large"
    }

    return (
        <Button
            type={buttonSize[size]}
            outline={false}
            className={classes.startButton}
            Icon={getIconByType(exhibition.exhibitionType?.type)}
            label={t(
                `exhibitionButton.${exhibition?.exhibitionType?.type}`
            )}
            onClick={() => onClick(exhibition)}
        />
    )
}