import {createContext, useContext, useReducer} from "react";

export const DURATION_CHANGED = 'durationChanged'
export const NEXT_CLICKED = 'nextClicked'
export const PREVIOUS_CLICKED = 'previousClicked'
export const ITEM_CLICKED = 'itemClicked'
export const HIGHLIGHTED_EXHIBITIONS_RECEIVED = 'highlightedExhibitionsReceived'

const HeroStateContext = createContext(undefined)
const HeroDispatchContext = createContext(undefined)

const initialState = {
    slideDuration: 12000,
    currentIndex: 0,
    exhibitions: [],
    duration: 12000,
    blackFont: false
}

const heroReducer = (state, action) => {
    console.debug({action})
    switch (action.type) {
        case HIGHLIGHTED_EXHIBITIONS_RECEIVED:
            return {
                ...state,
                exhibitions: action.exhibitions
            }
        case DURATION_CHANGED:
            return {
                ...state,
                duration: action.duration
            }
        case NEXT_CLICKED:
            const nextIndex = state.currentIndex + 1 < state.exhibitions.length ? state.currentIndex + 1 : 0
            return {
                ...state,
                currentIndex: nextIndex,
                duration: state.duration === 100000000 ? state.slideDuration : state.duration,
                blackFont: state.exhibitions[nextIndex]?.blackFont
            }
        case PREVIOUS_CLICKED:
            const previousIndex = state.currentIndex - 1 < 0 ? state.exhibitions.length - 1 : state.currentIndex - 1
            return {
                ...state,
                currentIndex: previousIndex,
                duration: state.duration === 100000000 ? state.slideDuration : state.duration,
                blackFont: state.exhibitions[previousIndex]?.blackFont
            }
        case ITEM_CLICKED:
            return {
                ...state,
                currentIndex: action.index,
                duration: state.duration === 100000000 ? state.slideDuration : state.duration,
                blackFont: state.exhibitions[action.index]?.blackFont
            }
        default:
            return state
    }
}

export const HeroProvider = ({children}) => {
    const [state, dispatch] = useReducer(heroReducer, initialState, undefined)
    return (
        <HeroStateContext.Provider value={state}>
            <HeroDispatchContext.Provider value={dispatch}>
                {children}
            </HeroDispatchContext.Provider>
        </HeroStateContext.Provider>
    )
}

export const useHeroState = () => {
    const context = useContext(HeroStateContext)
    if (context === undefined) {
        throw new Error('useHeroState must be used within a HeroProvider')
    } else {
        return context
    }

}

export const useHeroDispatch = () => {
    const context = useContext(HeroDispatchContext)
    if (context === undefined) {
        throw new Error('useHeroDispatch must be used within a HeroProvider')
    } else {
        return context
    }
}