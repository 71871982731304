import React, {useEffect} from "react";
import {createUseStyles} from "react-jss";
import cx from "classnames";
import {ProgressBar} from "./Progress";
import {Typography} from "../typography/Typography";
import {useAppState} from "../app/appContext";

import "react-slideshow-image/dist/styles.css";
import {fontSize, theme} from "../globalJss";
import {ITEM_CLICKED, NEXT_CLICKED, PREVIOUS_CLICKED, useHeroDispatch, useHeroState} from "./heroContext";
import {HeroNavigationButtons} from "./HeroNavigationButtons";

const useStyles = createUseStyles((theme) => ({
    heroCarousel: {
        width: "100%",
        height: "100%",
        backgroundColor: theme.colors.black,
        display: "flex",
        position: "relative",
    },
    steps: {
        zIndex: 100,
        display: "flex",
        alignSelf: "flex-end",
        margin: "0 10% 48px",
        cursor: "pointer",
        overflowX: "hidden",
        "& .step": {
            margin: "0 24px",
            whiteSpace: "nowrap",
            minHeight: "50px",
        },
        "& .step:first-child": {
            margin: "0 24px 0 0",
        },
        "& .step:last-child": {
            margin: "0 0 0 24px",
        },
        "@media (max-width: 1024px)": {
            margin: "0 2rem 24px",
        },
        "@media (max-width: 740px)": {
            overflowX: "scroll",
            margin: "0 2rem 8px",

            "& .step": {
                "& span": {
                    fontSize: fontSize.base,
                },
            },

            "&::-webkit-scrollbar": {
                width: "10px",
                margin: "-10px",
                "& *": { background: "transparent" },
            },
            "&::-webkit-scrollbar-track": {
                background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
                background: "transparent",
            },

            "&:hover": {
                "&::-webkit-scrollbar-thumb": {
                    background: `${theme.colors.black}33`,
                },
            },
        },
        "@media (max-width: 375px)": {
            margin: "0 1.5rem 8px",
            "& .step": {
                margin: "0 1rem",
                "&:first-child": { margin: "0 1rem 0 0" },
                "&:last-child": { margin: "0 0 0 1rem" },
            },
        },
    },
}));

export const HeroCarousel = ({
    autoPlay = true,
    classname = "",
    hideNavigationButtons=false,
    children
}) => {
    const { museums } = useAppState();
    const {duration, currentIndex, exhibitions}  = useHeroState()
    const classes = useStyles({
        hideNavigationButtons: hideNavigationButtons
    });
    const heroDispatch = useHeroDispatch()

    const previousSlide = () => {
        heroDispatch({type: PREVIOUS_CLICKED})
    };

    const nextSlide = () => {
        heroDispatch({type: NEXT_CLICKED})
    };

    const setSlide = (index) => {
        heroDispatch({type: ITEM_CLICKED, index: index - 1})
    };


    useEffect(() => {
        const interval = autoPlay
            ? setInterval(() => {
                  nextSlide();
              }, duration)
            : () => {};

        return () => clearInterval(interval);
    });

    return (
        <div className={`${classes.heroCarousel} ${classname}`}>
            {children}
            <div className={classes.steps}>
                {exhibitions.map((ex, i) => (
                    <div
                        key={i}
                        className={cx("step", {
                            active: i === currentIndex,
                        })}
                        onClick={() => setSlide(i + 1)}
                    >
                        <Typography
                            tag="span"
                            size="xl2"
                            bold={i === currentIndex}
                            style={{
                                color: ex?.blackFont
                                    ? theme.colors.black
                                    : theme.colors.white,
                            }}
                        >
                            {
                                museums.find(
                                    (m) => m.id === ex.exhibition?.museumId
                                )?.name
                            }
                        </Typography>
                        {i === currentIndex && (
                            <ProgressBar
                                duration={duration}
                                showLabel={false}
                            />
                        )}
                    </div>
                ))}
            </div>
            {!hideNavigationButtons &&
                <HeroNavigationButtons
                    previousSlide={previousSlide}
                    nextSlide={nextSlide}
                />
            }
        </div>
    );
};
