import {Typography} from "../typography/Typography";
import React, {useRef} from "react";
import {createUseStyles} from "react-jss";
import {CardContentStack} from "./CardContentStack";
import {useIsOverflow} from "../overflow/useIsOverflow";

const useStyles = createUseStyles(({lineHeight, fontSize, breakpoints}) => ({
    title: {
        fontSize: fontSize.xl3,
        [breakpoints.large]: {
            fontSize: fontSize.xl2,
        },
        lineHeight: lineHeight.leadingSnug,
    },
    lineClamp: {
        lineClamp: 5,
        display: "-webkit-box",
        overflow: "hidden",
        boxOrient: "vertical",
        marginBottom: 0,
        [breakpoints.small]: {
            lineClamp: 2
        }
    },
}))
export const LargeCardText = ({readMode=false, onToggleReadMode, text, title}) => {
    const classes = useStyles()
    const ref = useRef()
    const isOverflow = useIsOverflow(ref)

    const handleToggleReadMode = e => {
        e.preventDefault()
        onToggleReadMode()
    }

    const readModeLink = () => {
        if(isOverflow) {
            return (
                <a
                    href={""}
                    onClick={handleToggleReadMode}
                >
                    {readMode ? "Skjul tekst" : "Les mer"}
                </a>
            )
        } else {
            return ""
        }
    }

    return (
        <CardContentStack
            large={true}
        >
            <Typography
                tag="h3"
                bold
                className={classes.title}
            >
                {title}
            </Typography>
            <Typography
                tag="p"
                size={"base"}
                className={readMode ? "" : classes.lineClamp}
                tagRef={ref}
            >
                {text}
            </Typography>
            {readModeLink()}
        </CardContentStack>
    )
}