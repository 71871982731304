import {useEffect, useState} from "react";

export const useMediaQuery = mq => {
    const [match, setMatch] = useState(false)

    useEffect(
        () => {

            const media = window.matchMedia(mq);
            if(media.matches !== match) {
                setMatch(media.matches)
            }

            const listener = () => setMatch(media.matches)
            media.addEventListener("change", listener)

            return () => media.removeEventListener("change", listener)

        }, [mq]
    )

    return match
}