import React from "react";
import {createUseStyles} from "react-jss";
import { animated } from "@react-spring/web";

const useStyles = createUseStyles(() => ({
    animatedHero: {
        width: "100%",
        height: "100%",
        position: "absolute",
    },
    hero: {
        display: "flex",
        height: "100%",
        overflow: "hidden",
        position: "relative",
    },
}))

export const Hero = ({
    classname = "",
    style,
    children
}) => {

    const classes = useStyles()

    return (
        <animated.div
            style={style}
            className={classes.animatedHero}
        >
            <div className={`${classes.hero} ${classname}`}>
                {children}
            </div>
        </animated.div>
    );
};
