import React, { useState } from "react";
import { Chip } from "../chip/Chip";
import { createUseStyles } from "react-jss";
import { XCircle } from "react-feather";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useAppTranslation } from "../app/appContext";
import { Typography } from "../typography/Typography";

const useStyles = createUseStyles({
    filterWrapper: {},
    filter: { display: "flex", justifyContent: "space-between" },
    filterType: { margin: "0 12px" },
    clearButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 28,
        cursor: "pointer",
        fontSize: 12,
        textTransform: "uppercase",
        "&:hover": {
            opacity: "0.7",
        },
    },
    clearButtonIcon: {
        width: 10,
        height: 10,
        marginLeft: 7,
    },
});

export const FilterTags = ({ initialData, onToggleType, onClear }) => {
    const classes = useStyles();
    const [types, setTypes] = useState([]);
    const t = useAppTranslation();

    useDeepCompareEffect(() => {
        const unique = initialData
            .flatMap((item) =>
                item.exhibitionContents.map((ec) => ec.exhibitionType?.type)
            )
            .filter((value, index, self) => self.indexOf(value) === index)
            .map((t) => ({ type: t, checked: false }));
        setTypes(unique);
    }, [initialData]);

    const toggleType = (type) => {
        const updatedTypes = types.map((t) =>
            t.type === type ? { type: t.type, checked: !t.checked } : t
        );
        setTypes(updatedTypes);
        onToggleType(updatedTypes);
    };

    const clearFilter = () => {
        setTypes(types.map((t) => ({ ...t, checked: false })));
    };
    return (
        <div className={classes.filterWrapper}>
            <div className={classes.filter}>
                {types.map((type, i) => (
                    <Chip
                        key={`type-${i}`}
                        label={t(`filterCardLabel.${type.type}`)}
                        checked={type.checked}
                        onClick={() => toggleType(type.type)}
                        className={classes.filterType}
                    />
                ))}
            </div>
            {types.some((t) => t.checked) && (
                <div
                    className={classes.clearButton}
                    onClick={() => {
                        clearFilter();
                        onClear();
                    }}
                >
                    <Typography tag="span" size="xs">
                        {t("filterClearButton", "Fjern filtre")}
                    </Typography>
                    <XCircle className={classes.clearButtonIcon} />
                </div>
            )}
        </div>
    );
};
