import { kitFetch } from "@ekultur/fetch";
import { useEffect, useState } from "react";
import { getDmsUrl } from "@ekultur/dms-url-generator";

export const useExhibition = (exhibitionUniqueId) => {
    const [exhibition, setExhibition] = useState(null);

    useEffect(() => {
        setExhibition(null);

        if (exhibitionUniqueId) {
            kitFetch(`/vm-public/exhibitions/${exhibitionUniqueId}/`).then(
                (json) => {
                    setExhibition({
                        ...json,
                        exhibitionContents: json.exhibitionContents.map(
                            (ec) => ({
                                ...ec,
                                data: {
                                    ...ec.data,
                                    preview: getDmsUrl(ec.previewDmsId),
                                },
                            })
                        ),
                        background: json.background
                            ? {
                                ...json.background,
                                url: getDmsUrl(json.background.dmsId)
                            }
                            : null,
                        preview: json.preview
                            ? {
                                ...json.preview,
                                url: getDmsUrl(json.preview.dmsId)
                            } : null,
                        hero: json.hero
                            ? {
                                ...json.hero,
                                url: getDmsUrl(json.hero.dmsId)
                            } : null
                    });
                }
            );
        }
    }, [exhibitionUniqueId, setExhibition]);

    return exhibition;
};
