import {useCssBreakpoints} from "../layout/breakpoints";
import {theme} from "../globalJss";

export const useTheme = () => {
    const breakpoints = useCssBreakpoints()

    return {
        ...theme,
        breakpoints: breakpoints
    }
}