import React from "react";
import { createUseStyles } from "react-jss";
import { ChevronDown } from "react-feather";
import { Typography } from "../typography/Typography";

const useStyles = createUseStyles((theme) => ({
    scrollButton: {
        color: theme.colors.black,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        cursor: "pointer",
        textDecoration: "none",
        transition: "margin-top 0.5s",
        zIndex: 1000,
        "&:hover": {
            marginTop: "18px",
        },
    },
}));

export const ScrollButton = ({ content = "", href, className = "" }) => {
    const classes = useStyles();
    return (
        <Typography
            tag="a"
            className={`${classes.scrollButton} ${className}`}
            href={href}
        >
            <span>{content}</span>
            <ChevronDown />
        </Typography>
    );
};
