import {useMatch} from "react-router";
import {useFolderImages} from "./useFolderImages";
import {RoomWithImages} from "./RoomWithImages";

export const DmFolderExhibition = () => {
    const match = useMatch({
        path: "/dm-folder/:folderId"
    })
    const images = useFolderImages(match?.params.folderId)

    if(match && images.length > 29) {
        if(images.length > 29) {
            return <RoomWithImages images={images} />
        } else {
            return <div>Angi UUID til mappe med minst 30 objekter med bilder</div>
        }
    } else {
        return null
    }
}