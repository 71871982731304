export const skeleton = {
    "directionOfView": {
        "x": 0,
        "y": 0,
        "z": -0.001
    },
    "dome": {
        "skyBox": "https://dms-cf-01.dimu.org/image/0136NvAQy2GR?dimension=max"
    },
    "id": 7,
    "images": [
        {
            "position": {
                "x": 4.3,
                "y": 1.5,
                "z": -6.02
            },
            "rotation": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "scale": 1.5,
            "url": "https://dms-cf-04.dimu.org/image/032yhzAN47jq?dimension=1200x1200",
            "viewerData": {
                "annotations": [],
                "metaData": {
                    "artist": "Backer, Harriet",
                    "title": "Interiør fra bondestue, Strålsjøhaugen",
                    "url": "https://digitaltmuseum.no/011041108535",
                    "year": 1893
                },
                "model": {
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms-cf-04.dimu.org/image/032yhzAN47jq?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": 6.6,
                "y": 1.5,
                "z": -6.02
            },
            "rotation": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "scale": 1.5,
            "url": "https://dms-cf-01.dimu.org/image/022ykyZvZZq5?dimension=1200x1200",
            "viewerData": {
                "annotations": [],
                "metaData": {
                    "artist": "Backer, Harriet",
                    "title": "Fra Strålsjøen, høst",
                    "url": "https://digitaltmuseum.no/011041107816",
                    "year": 1894
                },
                "model": {
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms-cf-01.dimu.org/image/022ykyZvZZq5?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": 4.3,
                "y": 1.5,
                "z": -6.285
            },
            "rotation": {
                "x": 0,
                "y": 3.141592653589793,
                "z": 0
            },
            "scale": 1.5,
            "url": "https://dms-cf-01.dimu.org/image/0331vyU1w4P1?dimension=1200x1200",
            "viewerData": {
                "annotations": [],
                "metaData": {
                    "artist": "Backer, Harriet",
                    "title": "Gamlestua på Kolbotn",
                    "url": "https://digitaltmuseum.no/011041108191",
                    "year": 1896
                },
                "model": {
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms-cf-01.dimu.org/image/0331vyU1w4P1?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": 6.6,
                "y": 1.5,
                "z": -6.285
            },
            "rotation": {
                "x": 0,
                "y": 3.141592653589793,
                "z": 0
            },
            "scale": 1.5,
            "url": "https://dms-cf-05.dimu.org/image/0231wTu7qX5t?dimension=1200x1200",
            "viewerData": {
                "annotations": [],
                "model": {
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms-cf-05.dimu.org/image/0231wTu7qX5t?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": 8.5,
                "y": 1.5,
                "z": -1.04
            },
            "rotation": {
                "x": 0,
                "y": 3.141592653589793,
                "z": 0
            },
            "scale": 1.5,
            "url": "https://dms-cf-03.dimu.org/image/022yhzAN47Mj?dimension=1200x1200",
            "viewerData": {
                "annotations": [],
                "metaData": {
                    "artist": "Backer, Harriet",
                    "title": "Fra Foldalen",
                    "url": "https://digitaltmuseum.no/011041108341",
                    "year": 1894
                },
                "model": {
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms-cf-03.dimu.org/image/022yhzAN47Mj?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": 6,
                "y": 1.5,
                "z": -1.04
            },
            "rotation": {
                "x": 0,
                "y": 3.141592653589793,
                "z": 0
            },
            "scale": 1.5,
            "url": "https://dms-cf-06.dimu.org/image/0371B4DJG?dimension=1200x1200",
            "viewerData": {
                "annotations": [],
                "model": {
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms-cf-06.dimu.org/image/0371B4DJG?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": 3.5,
                "y": 1.5,
                "z": -1.04
            },
            "rotation": {
                "x": 0,
                "y": 3.141592653589793,
                "z": 0
            },
            "scale": 1.5,
            "url": "https://dms-cf-03.dimu.org/image/032yhzAN4Sqf?dimension=1200x1200",
            "viewerData": {
                "annotations": [],
                "metaData": {
                    "artist": "Backer, Harriet",
                    "title": "Kortspillere",
                    "url": "https://digitaltmuseum.no/011041107451",
                    "year": 1897
                },
                "model": {
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms-cf-03.dimu.org/image/032yhzAN4Sqf?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": -0.26,
                "y": 1.9,
                "z": -2.14
            },
            "rotation": {
                "x": 0,
                "y": -1.5707963267948966,
                "z": 0
            },
            "scale": 0.4,
            "url": "https://dms07.dimu.org/image/02349SYBET27?dimension=250x250",
            "viewerData": {
                "annotations": [],
                "metaData": {
                    "title": "Vykort med motiv över Skölemon i Matfors",
                    "url": "https://digitaltmuseum.org/021018470938"
                },
                "model": {
                    "backsideUrl": "https://dms01.dimu.org/image/02349SYBET2B?dimension=1200x1200",
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms07.dimu.org/image/02349SYBET27?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": -0.26,
                "y": 1.9,
                "z": -1.39
            },
            "rotation": {
                "x": 0,
                "y": -1.5707963267948966,
                "z": 0
            },
            "scale": 0.4,
            "url": "https://dms06.dimu.org/image/02349SY8Gonf?dimension=250x250",
            "viewerData": {
                "annotations": [],
                "model": {
                    "backsideUrl": "https://dms01.dimu.org/image/03349SY8Gorx?dimension=1200x1200",
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms06.dimu.org/image/02349SY8Gonf?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": -0.26,
                "y": 1.9,
                "z": -0.64
            },
            "rotation": {
                "x": 0,
                "y": -1.5707963267948966,
                "z": 0
            },
            "scale": 0.4,
            "url": "https://dms01.dimu.org/image/02349SCUCLgk?dimension=250x250",
            "viewerData": {
                "annotations": [],
                "model": {
                    "backsideUrl": "https://dms02.dimu.org/image/03349SCUCLm5?dimension=1200x1200",
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms01.dimu.org/image/02349SCUCLgk?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": -0.26,
                "y": 1.9,
                "z": 0.11
            },
            "rotation": {
                "x": 0,
                "y": -1.5707963267948966,
                "z": 0
            },
            "scale": 0.4,
            "url": "https://dms07.dimu.org/image/03349SCUCLgp?dimension=250x250",
            "viewerData": {
                "annotations": [],
                "model": {
                    "backsideUrl": "https://dms02.dimu.org/image/03349SCUCLm8?dimension=1200x1200",
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms07.dimu.org/image/03349SCUCLgp?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": -0.26,
                "y": 1.4,
                "z": -2.14
            },
            "rotation": {
                "x": 0,
                "y": -1.5707963267948966,
                "z": 0
            },
            "scale": 0.4,
            "url": "https://dms07.dimu.org/image/02349SCUCLm6?dimension=250x250",
            "viewerData": {
                "annotations": [],
                "model": {
                    "backsideUrl": "https://dms02.dimu.org/image/03349SCUCLmE?dimension=1200x1200",
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms07.dimu.org/image/02349SCUCLm6?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": -0.26,
                "y": 1.4,
                "z": -1.39
            },
            "rotation": {
                "x": 0,
                "y": -1.5707963267948966,
                "z": 0
            },
            "scale": 0.4,
            "url": "https://dms05.dimu.org/image/03349SCUCLP6?dimension=250x250",
            "viewerData": {
                "annotations": [],
                "model": {
                    "backsideUrl": "https://dms07.dimu.org/image/03349SCUCLTS?dimension=1200x1200",
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms05.dimu.org/image/03349SCUCLP6?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": -0.26,
                "y": 1.4,
                "z": -0.64
            },
            "rotation": {
                "x": 0,
                "y": -1.5707963267948966,
                "z": 0
            },
            "scale": 0.4,
            "url": "https://dms07.dimu.org/image/03349SCUCLXv?dimension=250x250",
            "viewerData": {
                "annotations": [],
                "model": {
                    "backsideUrl": "https://dms10.dimu.org/image/02349SCUCLXy?dimension=1200x1200",
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms07.dimu.org/image/03349SCUCLXv?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": -0.26,
                "y": 1.4,
                "z": 0.11
            },
            "rotation": {
                "x": 0,
                "y": -1.5707963267948966,
                "z": 0
            },
            "scale": 0.4,
            "url": "https://dms04.dimu.org/image/03349SCUCLTX?dimension=250x250",
            "viewerData": {
                "annotations": [],
                "model": {
                    "backsideUrl": "https://dms03.dimu.org/image/03349SCUCLXr?dimension=1200x1200",
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms04.dimu.org/image/03349SCUCLTX?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": -0.26,
                "y": 0.9,
                "z": -2.14
            },
            "rotation": {
                "x": 0,
                "y": -1.5707963267948966,
                "z": 0
            },
            "scale": 0.4,
            "url": "https://dms09.dimu.org/image/02349SCUCLXw?dimension=250x250",
            "viewerData": {
                "annotations": [],
                "metaData": {
                    "title": "Vykort med motiv över Sörfors bruk i Matfors",
                    "url": "https://digitaltmuseum.org/021018451851"
                },
                "model": {
                    "backsideUrl": "https://dms07.dimu.org/image/03349SCUCLXz?dimension=1200x1200",
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms09.dimu.org/image/02349SCUCLXw?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": -0.26,
                "y": 0.9,
                "z": -1.39
            },
            "rotation": {
                "x": 0,
                "y": -1.5707963267948966,
                "z": 0
            },
            "scale": 0.4,
            "url": "https://dms06.dimu.org/image/03349SCUCLcH?dimension=250x250",
            "viewerData": {
                "annotations": [],
                "model": {
                    "backsideUrl": "https://dms03.dimu.org/image/02349SCUCLcP?dimension=1200x1200",
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms06.dimu.org/image/03349SCUCLcH?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": -0.26,
                "y": 0.9,
                "z": -0.64
            },
            "rotation": {
                "x": 0,
                "y": -1.5707963267948966,
                "z": 0
            },
            "scale": 0.4,
            "url": "https://dms09.dimu.org/image/02349SCUCLcJ?dimension=250x250",
            "viewerData": {
                "annotations": [],
                "model": {
                    "backsideUrl": "https://dms02.dimu.org/image/02349SCUCLcN?dimension=1200x1200",
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms09.dimu.org/image/02349SCUCLcJ?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": -0.26,
                "y": 0.9,
                "z": 0.11
            },
            "rotation": {
                "x": 0,
                "y": -1.5707963267948966,
                "z": 0
            },
            "scale": 0.4,
            "url": "https://dms06.dimu.org/image/02349SCUCLcM?dimension=250x250",
            "viewerData": {
                "annotations": [],
                "model": {
                    "backsideUrl": "https://dms09.dimu.org/image/03349SCUCLgh?dimension=1200x1200",
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms06.dimu.org/image/02349SCUCLcM?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": -2.75,
                "y": 1.9,
                "z": -6.03
            },
            "rotation": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "scale": 0.4,
            "url": "https://dms03.dimu.org/image/02349SCUCLgg?dimension=250x250",
            "viewerData": {
                "annotations": [],
                "metaData": {
                    "title": "Vykort med motiv över bruket i Matfors",
                    "url": "https://digitaltmuseum.org/021018451862"
                },
                "model": {
                    "backsideUrl": "https://dms01.dimu.org/image/03349SCUCLgi?dimension=1200x1200",
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms03.dimu.org/image/02349SCUCLgg?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": -2,
                "y": 1.9,
                "z": -6.03
            },
            "rotation": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "scale": 0.4,
            "url": "https://dms01.dimu.org/image/02349SCSfzt2?dimension=250x250",
            "viewerData": {
                "annotations": [],
                "model": {
                    "backsideUrl": "https://dms04.dimu.org/image/02349SCSfzt7?dimension=1200x1200",
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms01.dimu.org/image/02349SCSfzt2?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": -1.25,
                "y": 1.9,
                "z": -6.03
            },
            "rotation": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "scale": 0.4,
            "url": "https://dms01.dimu.org/image/02349SCSfzoi?dimension=250x250",
            "viewerData": {
                "annotations": [],
                "model": {
                    "backsideUrl": "https://dms04.dimu.org/image/02349SCSfzoh?dimension=1200x1200",
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms01.dimu.org/image/02349SCSfzoi?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": -2.75,
                "y": 1.4,
                "z": -6.03
            },
            "rotation": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "scale": 0.4,
            "url": "https://dms08.dimu.org/image/02349SCSfzt8?dimension=250x250",
            "viewerData": {
                "annotations": [],
                "model": {
                    "backsideUrl": "https://dms07.dimu.org/image/02349SCSfzxU?dimension=1200x1200",
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms08.dimu.org/image/02349SCSfzt8?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": -2,
                "y": 1.4,
                "z": -6.03
            },
            "rotation": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "scale": 0.4,
            "url": "https://dms07.dimu.org/image/02349SCSg12t?dimension=250x250",
            "viewerData": {
                "annotations": [],
                "model": {
                    "backsideUrl": "https://dms04.dimu.org/image/02349SCSg17D?dimension=1200x1200",
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms07.dimu.org/image/02349SCSg12t?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": -1.25,
                "y": 1.4,
                "z": -6.03
            },
            "rotation": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "scale": 0.4,
            "url": "https://dms09.dimu.org/image/03348xLPmtjf?dimension=250x250",
            "viewerData": {
                "annotations": [],
                "model": {
                    "backsideUrl": "https://dms07.dimu.org/image/02349SCSfzVv?dimension=1200x1200",
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms09.dimu.org/image/03348xLPmtjf?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": -2.75,
                "y": 0.9,
                "z": -6.03
            },
            "rotation": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "scale": 0.4,
            "url": "https://dms10.dimu.org/image/02349SCSfzog?dimension=250x250",
            "viewerData": {
                "annotations": [],
                "metaData": {
                    "title": "Vykort med motiv över Lucksta sett från Sörforsåsen i Matfors",
                    "url": "https://digitaltmuseum.org/021018450796"
                },
                "model": {
                    "backsideUrl": "https://dms09.dimu.org/image/02349SCSfzt5?dimension=1200x1200",
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms10.dimu.org/image/02349SCSfzog?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": -2,
                "y": 0.9,
                "z": -6.03
            },
            "rotation": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "scale": 0.4,
            "url": "https://dms07.dimu.org/image/03349SCSfzod?dimension=250x250",
            "viewerData": {
                "annotations": [],
                "model": {
                    "backsideUrl": "https://dms04.dimu.org/image/02349SCSfzoh?dimension=1200x1200",
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms07.dimu.org/image/03349SCSfzod?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        },
        {
            "position": {
                "x": -1.25,
                "y": 0.9,
                "z": -6.03
            },
            "rotation": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "scale": 0.4,
            "url": "https://dms02.dimu.org/image/03349SCSfzaN?dimension=250x250",
            "viewerData": {
                "annotations": [],
                "model": {
                    "backsideUrl": "https://dms07.dimu.org/image/03349SCSfzaT?dimension=1200x1200",
                    "position": [
                        0,
                        0,
                        0
                    ],
                    "rotation": [
                        0,
                        0,
                        0
                    ],
                    "scale": 2,
                    "type": "ImageSingle",
                    "url": "https://dms02.dimu.org/image/03349SCSfzaN?dimension=1200x1200"
                },
                "position": [
                    0,
                    0,
                    1.5
                ],
                "rotation": [
                    0,
                    1.5707963267948966,
                    0
                ]
            }
        }
    ],
    "lights": [
        {
            "intensity": 1.7,
            "type": "ambient"
        },
    ],
    "models": [],
    "roomUrl": "https://dms-cf-10.dimu.org/file/013AjPGU83FN",
    "startPosition": {
        "x": -2,
        "y": 1.75,
        "z": 0
    },
    "texts": [],
    "videos": []
}
