import {useAppState} from "../app/appContext";

export const useMuseum = (museumId, museumOverride) => {
    const { museums } = useAppState();
    if(museumOverride) {
        return museumOverride
    } else {
        return museums.find((m) => m.id === Number(museumId))
    }
}
