import React from "react";
import { createUseStyles } from "react-jss";
import { useExhibitionTranslation } from "../exhibition/exhibitionContext";
import { Shield, Copy, Command } from "react-feather";
import { Typography } from "../typography/Typography";

const useStyles = createUseStyles((theme) => ({
    menu: {
        display: "none",
        height: "0",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        overflow: "hidden",
        "&.open": {
            display: "flex",
            height: "100%",
        },
    },
    verticalList: {
        padding: "0",
        listStyle: "none",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        "& li, & li a": {
            color: theme.colors.white,
            fontSize: "5vw",
            textAlign: "center",
            textDecoration: "none",
            "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
            },
        },
    },
    horizontalList: {
        margin: "7vh",
        padding: "0",
        display: "flex",
        //justifyContent: "space-between",
        listStyle: "none",
        "& li": {
            margin: "0 25px",
            display: "flex",
            "&:first-child": { marginRight: 25 },
            "&:last-child": { marginRight: 25 },
            "&:hover": {
                cursor: "pointer",
                textDecoration: "underline",
            },
            "& a": {
                color: theme.colors.white,
                textDecoration: "none",
                textAlign: "center",
            },
            "@media (max-width: 1024px)": {
                margin: "0 12px",
                "&:first-child": { marginRight: 12 },
                "&:last-child": { marginRight: 12 },
            },
        },
        "& li a svg": {
            marginRight: "8px",
        },
    },
}));

export const Menu = ({ isOpen }) => {
    const classes = useStyles();
    const t = useExhibitionTranslation();
    return (
        <div className={`${classes.menu} ${isOpen ? "open" : ""}`}>
            <ul className={classes.verticalList}>
                <li>
                    <Typography tag="a" href="/" bold>
                        {t("menuItem.explore", "Utforsk")}
                    </Typography>
                </li>
                <li>
                    <Typography tag="a" href="/search" bold>
                        {t("menuItem.search", "Søk")}
                    </Typography>
                </li>
                <li>
                    <Typography tag="a" href="/museums" bold>
                        {t("menuItem.museums", "Museene")}
                    </Typography>
                </li>
                <li>
                    <Typography tag="a" href="/about" bold>
                        {t("menuItem.about", "Om virtuelt museum")}
                    </Typography>
                </li>
            </ul>
            <ul className={classes.horizontalList}>
                <li>
                    <Typography tag="a" href="/privacy">
                        <Shield />
                        {t("menuItem.privacy", "Personvern og cookies")}
                    </Typography>
                </li>
                <li>
                    <Typography tag="a" href="/copyright">
                        <Copy />
                        {t("menuItem.copyright", "Opphavsrett")}
                    </Typography>
                </li>
                <li>
                    <Typography tag="a" href="/">
                        <Command />
                        {t("menuItem.dissemination", "Formidling")}
                    </Typography>
                </li>
            </ul>
        </div>
    );
};
