import React from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({breakpoints}) => ({
    exhibitionContentsRow: {
        display: "flex",
        width: "1080px",
        margin: "24px auto",
        [breakpoints.large]: {
            width: "calc(100% - 48px)",
            margin: "24px auto",
        },
        gap: 24
    },}))

export const MediumExperienceLayoutRow = ({children}) => {
    const classes = useStyles()
    return (
        <div className={classes.exhibitionContentsRow}>
            {children}
        </div>
    )
};
