import React from "react";
import {createUseStyles} from "react-jss";
import cx from "classnames";
import {ThreesixtyProvider, With360MicroFrontend} from '@ekultur/360-microfrontend'

const useStyles = createUseStyles({
    container: {},
});

export const With360 = ({ classname, children }) => {
    const classes = useStyles();

    return (
        <ThreesixtyProvider>
            <With360MicroFrontend>
                <div className={cx(classes.container, classname)}>
                    {children}
                </div>
            </With360MicroFrontend>
        </ThreesixtyProvider>
    );
};
