import {ExhibitionCardSizes} from "../exhibition/helpers";
import React from "react";
import {SmallCardText} from "./SmallCardText";
import {MediumCardText} from "./MediumCardText";
import {LargeCardText} from "./LargeCardText";

export const ExperienceCardText = ({size, text, name, readMode, onToggleReadMode}) => {

    const cardText = {
        [ExhibitionCardSizes.SMALL]: (
            <SmallCardText
                text={text}
                readMode={readMode}
                onToggleReadMode={onToggleReadMode}
            />
        ),
        [ExhibitionCardSizes.MEDIUM]: (
            <MediumCardText
                text={text}
                title={name}
            />
        ),
        [ExhibitionCardSizes.LARGE]: (
            <LargeCardText
                text={text}
                title={name}
                readMode={readMode}
                onToggleReadMode={onToggleReadMode}
            />
        )
    }


    return cardText[size]
}
