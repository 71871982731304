import {BrowserRouter as Router} from "react-router-dom";
import {AppProvider} from "./appContext";
import {Page} from "../page/Page";
import React from "react";
import {ThemeProvider} from "../theme/themeContext";
import {Theme} from "../theme/Theme";

export const App = () => {
    return (
        <Router>
            <ThemeProvider>
                <Theme>
                    <AppProvider>
                        <Page />
                    </AppProvider>
                </Theme>
            </ThemeProvider>
        </Router>
    );
};
