import {createContext, useContext, useReducer} from "react";
import {getDmsUrl} from "@ekultur/dms-url-generator";

const AdminHeroPropsContext = createContext(undefined)
const AdminHeroStateContext = createContext(undefined)
const AdminHeroDispatchContext = createContext(undefined)
const initialState = {
}

const adminHeroReducer = (state, action) => {
    switch(action.type) {
        default:
            throw new Error(`Unhandled action type ${action.type}`)
    }
}

export const AdminHeroProvider = ({
    onMediaSelect,
    onChange,
    data,
    onEffectSelect,
    onTextColorSelect,
    mobileView=false,
    children,
}) => {

    const [state, dispatch] = useReducer(adminHeroReducer, {
        ...initialState,
    })

    return (
        <AdminHeroPropsContext.Provider value={{
            onMediaSelect: onMediaSelect,
            onChange: onChange,
            onTextColorSelect: onTextColorSelect,
            onEffectSelect: onEffectSelect,
            mobileView: mobileView,
            data: {
                ...data,
                background: data.background
                    ? {
                        ...data.background,
                        url: getDmsUrl(data.background.dmsId, "01")
                    }
                    : null,
                preview: data.preview
                    ? {
                        ...data.preview,
                        url: getDmsUrl(data.preview.dmsId, "01")
                    } : null,
                hero: data.hero
                    ? {
                        ...data.hero,
                        url: getDmsUrl(data.hero.dmsId, "01")
                    } : null
            }
        }}>
            <AdminHeroStateContext.Provider value={state}>
                    <AdminHeroDispatchContext.Provider value={dispatch}>
                {children}
                </AdminHeroDispatchContext.Provider>
            </AdminHeroStateContext.Provider>
        </AdminHeroPropsContext.Provider>
    )
}

export const useAdminHeroProps = () => {
    const context = useContext(AdminHeroPropsContext)
    if(!context) {
        throw new Error("useAdminHeroProps must be used within a AdminHeroProvider")
    } else {
        return context
    }
}

export const useAdminHeroState = () => {
    const context = useContext(AdminHeroStateContext)
    if(!context) {
        throw new Error("useAdminHeroState must be used within a AdminHeroProvider")
    } else {
        return context
    }
}

export const useAdminHeroDispatch = () => {
    const context = useContext(AdminHeroDispatchContext)
    if(!context) {
        throw new Error("useAdminHeroDispatch must be used within a AdminHeroProvider")
    } else {
        return context
    }
}
