import React from "react";
import { createUseStyles } from "react-jss";
import { useAppState, useAppTranslation } from "../app/appContext";
import { Typography } from "../typography/Typography";
import { Categories } from "./Categories";

const useStyles = createUseStyles(({ colors, fontSize, lineHeight }) => ({
    categoriesPage: {
        marginTop: "10vh",
        backgroundColor: `${colors.black}0A`,
        minHeight: "40vh",
    },
    content: {
        display: "flex",
        padding: "8rem 0 12rem 0",
        flexDirection: "column",
        alignItems: "center",
        width: "1200px",
        margin: "0 auto",
        "@media (max-width: 1264px)": {
            margin: "0 32px",
            width: "initial",
        },
        "@media (max-width: 1024px)": {
            margin: "0 32px",
        },
        "@media (max-width: 375px)": {
            margin: "0 24px",
            padding: "4rem 0 6rem 0",
        },
        "& p": {
            textAlign: "center",
        },
    },
    title: {
        "@media (max-width: 375px)": {
            fontSize: fontSize.xl2,
            lineHeight: lineHeight.leadingSnug,
        },
    },
    description: {
        "@media (max-width: 375px)": {
            fontSize: fontSize.base,
        },
    },
    categories: {
        marginTop: "3.5rem",
        "@media (max-width: 375px)": {
            marginTop: "1.5rem",
        },
    },
}));

export const FrontPageCategories = () => {
    const classes = useStyles();
    const { museums } = useAppState();
    const t = useAppTranslation();

    const categories = [
        {
            title: "Arkeologi",
            image: "https://source.unsplash.com/featured/?archeology",
            color: "#4b3f11",
        },
        {
            title: "Industri",
            image: "https://source.unsplash.com/featured/?industrial",
            color: "#fdca96",
        },
        {
            title: "Skipsfart",
            image: "https://source.unsplash.com/featured/?ship",
            color: "#ACCBB1",
        },
        {
            title: "Naturhistorie",
            image: "https://source.unsplash.com/featured/?naturalhistory",
            color: "#F2AC64",
        },
        {
            title: "Møbler",
            image: "https://source.unsplash.com/featured/?furniture",
            color: "#AEA386",
        },
        {
            title: "Moderne kunst",
            image: "https://source.unsplash.com/featured/?modernart",
            color: "#112C21",
        },
        {
            title: "Fiskeri",
            image: "https://source.unsplash.com/featured/?fish",
            color: "#B16D52",
        },
        {
            title: "Håndverk",
            image: "https://source.unsplash.com/featured/?crafts",
            color: "#7C7FC9",
        },
        {
            title: "Arkitektur",
            image: "https://source.unsplash.com/featured/?architecture",
            color: "#282530",
        },
    ];

    if (museums) {
        return (
            <div id="categories" className={classes.categoriesPage}>
                <div className={classes.content}>
                    <Typography
                        tag="h2"
                        size="xl5"
                        bold
                        className={classes.title}
                    >
                        {t("frontpageCategoriesTitle", "Hva liker du?")}
                    </Typography>
                    <Typography
                        tag="p"
                        size="xl"
                        type="mono"
                        className={classes.description}
                    >
                        {t(
                            "frontpageCategoriesDescription",
                            "Finn utstillinger som passer til dine interesser"
                        )}
                    </Typography>
                    <Categories
                        categories={categories}
                        className={classes.categories}
                    />
                </div>
            </div>
        );
    } else {
        return null;
    }
};
