import React from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    crumbCurrent: {
        fontSize: "14px",
        lineHeight: "20px",
        display: "flex",
        alignItems: "center",
        fontWeight: "bold",
        color: ({ color }) => color,
    },
    crumbLink: {
        fontWeight: "bold",
        color: ({ color }) => color,
        fontSize: "14px",
        lineHeight: "20px",
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        },
    },
    delimiter: {
        height: "12px",
    },
});

export const Crumb = ({
    isCurrent,
    isFirst,
    flipArrow,
    color,
    className,
    path,
    children,
}) => {
    const classes = useStyles({ color });
    return isCurrent ? (
        <div className={`${classes.crumbCurrent} ${className}`}>
            {!isFirst &&
                (flipArrow ? (
                    <ChevronLeft className={classes.delimiter} />
                ) : (
                    <ChevronRight className={classes.delimiter} />
                ))}
            {children}
        </div>
    ) : (
        <a className={`${classes.crumbLink} ${className}`} href={path}>
            {!isFirst &&
                (flipArrow ? (
                    <ChevronLeft className={classes.delimiter} />
                ) : (
                    <ChevronRight className={classes.delimiter} />
                ))}
            {children}
        </a>
    );
};
