import React from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({breakpoints}) => ({
    exhibitionContentsRow: {
        display: "flex",
        width: "1080px",
        margin: "24px auto",
        [breakpoints.large]: {
            width: "calc(100% - 48px)",
            margin: "24px auto",
        },
        gap: 24
    },
}))

export const LargeExperienceLayoutRow = ({children}) => {
    const classes = useStyles()
    children.filter(child => child !== null).forEach(child => console.debug({child}))
    return children.filter(child => child !== null)
        .map((child) => (
            <div
                className={classes.exhibitionContentsRow}
                key={`exhibitionCard-${child.props ? child.props.contentId : 1337}`}
            >
                {child}
            </div>
        )
    )
};
