import React from "react";
import { Box, RotateCw, BookOpen, Image } from "react-feather";

export const truncate = (string, maxCharacters, useWordBoundary = true) => {
    if (string.length <= maxCharacters) {
        return string;
    }
    const subString = string.substr(0, maxCharacters - 1); // the original check
    return (
        (useWordBoundary
            ? subString.substr(0, subString.lastIndexOf(" "))
            : subString) + "..."
    );
};

export const ExhibitionCardSizes = Object.freeze({
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large",
});

export const getIconByType = (type) => {
    switch (type) {
        case "3droom":
            return <Box />;
        case "360":
            return <RotateCw />;
        case "scrollytell":
            return <BookOpen />;
        case "photoSeries":
            return <Image />;
    }
};
