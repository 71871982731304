import {Typography} from "../typography/Typography";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({
    exhibitionDescription: {
        maxWidth: "640px",
        margin: "5px 0 50px 0",
        textAlign: "center",
    }
}));

export const ExhibitionDescription = ({description}) => {
    const classes = useStyles()

    return (
        <Typography
            size="xl"
            className={classes.exhibitionDescription}
        >
            {description}
        </Typography>
    )
}