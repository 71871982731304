import {useMuseum} from "../museum/useMuseum";
import {Typography} from "../typography/Typography";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({
    museum: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    museumLogo: {
        width: "32px",
        borderRadius: "1332px",
    },
    museumName: {
        marginTop: "13px",
    }
}));

export const MuseumHeader = ({museumOverride=null, museumId}) => {
    const museum = useMuseum(museumId, museumOverride)
    const classes = useStyles()

    if(museum) {
        return (
            <div className={classes.museum}>
                {museum.profile && (
                    <img
                        className={classes.museumLogo}
                        src={`https://${museum.profile}`}
                        alt="logo"
                    />
                )}
                <Typography
                    tag="span"
                    size="xl"
                    bold
                    className={classes.museumName}
                >
                    {museum.name}
                </Typography>
            </div>
        )
    } else {
        return null
    }
}