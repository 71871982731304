import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    smallContent: {
        padding: ({ siblings }) =>
            siblings === 0 ? "24px 72px" : "16px 16px 20px",
        alignItems: ({ siblings }) => (siblings > 0 ? "center" : ""),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "& p": {
            margin: "0",
        },
    }
})

export const ExperienceSmallContent = ({siblings, children}) => {
    const classes = useStyles({
        siblings: siblings
    })
    return (
        <div
            className={classes.smallContent}
        >
            {children}
        </div>
    )
}