import React from "react";
import {createUseStyles} from "react-jss";
import {Menu} from "../menu/Menu";
import {useScrollHideMenu} from "./useScrollMenuClass";
import {useAppState} from "../app/appContext";

const useStyles = createUseStyles((theme) => ({
    header: {
        position: ({ isSticky }) => (isSticky ? "fixed" : "absolute"),
        left: 0,
        width: "100%",
        zIndex: "999",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        transition: "transform 0.4s",
        "&.menuOpen": {
            backgroundColor: `${theme.colors.vmGray900}`,
            height: "100vh",
        },
    },
    loginButton: {
        marginRight: 16,
    },
    "@global": {
        body: {
            "&.scrollDown $header": {
                transform: "translate3d(0, -100%, 0)",
            },
            "&.scrollUp $header": {
                transform: "none",
            },
            "&.hidden": { overflow: "hidden" },
        },
    },
}));

export const Header = ({
    isSticky = false,
    children
}) => {
    const {menuOpen} = useAppState()
    useScrollHideMenu(!isSticky, menuOpen);

    const classes = useStyles({ menuOpen, isSticky });


    return (
        <header className={`${classes.header} ${menuOpen ? "menuOpen" : ""}`}>
            {children}
            <Menu isOpen={menuOpen} />
        </header>
    );
};
