import {Typography} from "../typography/Typography";
import {createUseStyles} from "react-jss";
import {useState} from "react";
import {useAppTranslation} from "../app/appContext";
import {useAdminProps} from "../admin/adminContext";

const useStyles = createUseStyles(({
    exhibitionDescription: {
        maxWidth: "640px",
        margin: "5px 0 50px 0",
        textAlign: "center",
        border:"1.5708px dashed",
        borderColor: ({error}) => error ? "#d32f2f" : "#9E9E9E",
        '&:hover': {
            borderColor: "#3F51B5",
        },
        width: "100%"
    },
    editDescription: {
        maxWidth: "640px",
        margin: "5px 0 50px 0",
        textAlign: "center",
        background: "none",
        resize: "none",
        outline: "none",
        border:"2px solid",
        borderColor: ({error}) => error ? "#d32f2f" : "#3F51B5",
        width: "100%",
        color: "inherit"
    }
}));

export const EditExhibitionDescription = ({description, error}) => {
    const [editing, setEditing] = useState(false)
    const classes = useStyles({error: error})
    const {onChange} = useAdminProps()
    const t = useAppTranslation()
    const placeholder = t(
        'adminDescriptionHelper',
        "Skriv en kort introduksjon om utstillingen, maks {{max}} tegn.",
        {
            max: 120
        })

    const descriptionChanged = event => {
        onChange({
            key: "ingress",
            value: event.target.value
        })
    }

    if(editing) {
        return (
            <Typography
                size="xl"
                className={classes.editDescription}
                value={description}
                maxLength={180}
                autoFocus={true}
                rows={4}
                tag={"textarea"}
                onChange={descriptionChanged}
                onBlur={() => setEditing(false)}
                placeholder={placeholder}
            />
        )
    } else {
        return (
            <Typography
                size="xl"
                className={classes.exhibitionDescription}
                onClick={() => setEditing(!editing)}
            >
                {description || placeholder}
            </Typography>
        )
    }
}