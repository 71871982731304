import {useThemeState} from "../theme/themeContext";

export const useBreakpoints = () => {
    const {forceMobile} = useThemeState()
    const largerThanLife = 400000
    return {
        extraSmall: forceMobile ? largerThanLife+1 : 600,
        small: forceMobile ? largerThanLife+2 : 800,
        medium: forceMobile ? largerThanLife+3 : 900,
        large: forceMobile ? largerThanLife+4 : 1160
    }
}