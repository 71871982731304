import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({
    leftSide: {
        marginRight: "auto",
        display: "flex",
        alignItems: "center",
    },
}));

export const NavLeftSide = ({children}) => {
    const classes = useStyles()
    return (
        <div className={classes.leftSide}>
            {children}
        </div>
    )
}