import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({
    nav: {
        display: "flex",
        alignItems: "center",
        padding: "48px",
        "@media (max-width: 1440px)": {
            padding: "48px",
        },
        "@media (max-width: 1024px)": {
            padding: "32px",
        },
        "@media (max-width: 375px)": {
            padding: "24px",
        },
    },
}))

export const Nav = ({children}) => {
    const classes = useStyles()
    return (
        <nav className={classes.nav}>
            {children}
        </nav>
    )
}