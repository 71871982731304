import {ColorLens} from "@mui/icons-material";
import {Button as MuiButton, Menu, MenuItem} from "@mui/material";
import React from "react";
import {useAppTranslation} from "../app/appContext";

export const EditFontColor = ({blackFont, onFontColorSelected}) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const t = useAppTranslation()

    const handleClick = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleMenuItemClick = blackFont => {
        onFontColorSelected(blackFont)
        handleClose()
    }

    return (
        <div>
            <MuiButton
                variant={"contained"}
                startIcon={<ColorLens />}
                onClick={handleClick}
            >
                {t("editFontColor", 'Velg skriftfarge')}
            </MuiButton>
            <Menu
                open={open}
                id={"font-color-menu"}
                anchorEl={anchorEl}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'font-color-menu'
                }}
            >
                <MenuItem
                    selected={!blackFont}
                    onClick={() => handleMenuItemClick(false)}
                >
                    {t('editFontColorWhite', 'Hvit')}
                </MenuItem>
                <MenuItem
                    selected={blackFont}
                    onClick={() => handleMenuItemClick(true)}
                >
                    {t('editFontColorBlack', 'Svart')}
                </MenuItem>
            </Menu>
        </div>
    )
}
