import {useMatch} from "react-router";
import {Header} from "../header/Header";
import {Suspense} from "react";
import {LoadingLogo} from "../loading/LoadingLogo";
import {createUseStyles} from "react-jss";
import {useAppTranslation} from "../app/appContext";
import {Typography} from "../typography/Typography";
import {PortalNav} from "../header/PortalNav";

const useStyles = createUseStyles({
    privacyPage: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "163px",
        maxWidth: "1024px",
        margin: "0 auto 30px auto",
        "@media (max-width: 1040px)": {
            maxWidth: "751px",
        },
        "@media (max-width: 770px)": {
            maxWidth: "480px",
        },
        "@media (max-width: 500px)": {
            maxWidth: "240px",
        },
    },
    header: {},
    FetText: {
        fontWeight: "bold",
    },
});

export const PrivacyPage = () => {
    const classes = useStyles();
    const t = useAppTranslation();
    const match = useMatch({
        path: "/privacy",
        exact: true,
    });

    if (match) {
        return (
            <Suspense fallback={<LoadingLogo />}>
                <Header>
                    <PortalNav
                        logoMode="black"
                        includeBreadcrumb
                        match={match}
                        rootsOnly
                    />
                </Header>
                <div className={classes.privacyPage}>
                    <Typography
                        tag="h1"
                        size="xl5"
                        bold
                        className={classes.header}
                    >
                        {t("privacyTitle", "Personvern og cookies")}
                    </Typography>
                    <Typography tag="p" size="base">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse sit amet ligula vel diam venenatis semper.
                        Ut ultrices euismod tortor vel gravida. Duis a efficitur
                        dui. Sed sed diam mauris. Duis tristique aliquam velit.
                        Quisque dapibus volutpat euismod. Phasellus vitae sem
                        auctor, varius tellus eget, ultricies dolor. Vivamus
                        suscipit sodales consectetur. Proin id ex quis arcu
                        cursus vulputate. Pellentesque in lectus consequat,
                        maximus elit a, sodales massa. Mauris dapibus nisl et
                        luctus vestibulum. Vestibulum convallis risus libero, ut
                        facilisis sem vulputate eu. Proin vehicula ac ipsum sed
                        feugiat. Mauris pulvinar nisl ac congue dapibus.
                    </Typography>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Typography tag="p" size="xs" type="regular">
                            XS tekst
                        </Typography>
                        <Typography tag="p" size="sm">
                            SM tekst
                        </Typography>
                        <Typography tag="p" size="base">
                            base tekst
                        </Typography>
                        <Typography tag="p" size="lg">
                            LG tekst
                        </Typography>
                        <Typography tag="p" size="xl">
                            XL tekst
                        </Typography>
                        <Typography tag="p" size="xl2">
                            2XL tekst
                        </Typography>
                        <Typography tag="p" size="xl3">
                            3XL tekst
                        </Typography>
                        <Typography tag="p" size="xl4">
                            4XL tekst
                        </Typography>
                        <Typography tag="p" size="xl5">
                            5XL tekst
                        </Typography>
                        <Typography tag="p" size="xl6">
                            6XL tekst
                        </Typography>
                        <Typography tag="p" size="xl7">
                            7XL tekst
                        </Typography>
                        <Typography tag="p" size="xl8">
                            8XL tekst
                        </Typography>
                    </div>
                </div>
            </Suspense>
        );
    } else {
        return null;
    }
};
