import React from "react";
import {createUseStyles} from "react-jss";
import {useContrastApproved} from "../contrast/useContrast";
import {theme} from "../globalJss";

const useStyles = createUseStyles(({ colors, breakpoints }) => ({
    content: {
        minHeight: "50vh",
        display: "flex",
        alignItems: "center",
        maxWidth: "1080px",
        margin: "0 auto",
        color: ({ contrastApproved }) =>
            contrastApproved ? colors.black : colors.white,
        [breakpoints.large]: {
            padding: "0 48px",
        },
        [breakpoints.medium]: {
            padding: "48px 32px",
            flexDirection: "column",
        },
    },
    contentForceMobileView: {
        padding: "48px 32px",
        flexDirection: "column",
    },
}))

export const ExhibitionPageContent = ({color, forceMobileView=false, children}) => {
    const contrastApproved = useContrastApproved(
        color || theme.colors.satinLinen,
        theme.colors.black
    );
    const classes = useStyles({
        contrastApproved: contrastApproved,
        forceMobileView: forceMobileView
    })
    return (
        <div
            className={`${classes.content} ${forceMobileView ? classes.contentForceMobileView : ""}`}
            id="content"
        >
            {children}
        </div>
    );
};
