import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({colors}) => ({
    exhibitionCard: {
        width: "100%",
        minHeight: 581,
        display: "grid",
        gridAutoRows: "1fr",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "2fr 1fr"
    },
}))

export const MediumExperienceCard = ({children}) => {
    const classes = useStyles()

    return (
        <div className={`${classes.exhibitionCard}`}>
            {children}
        </div>
    )
}