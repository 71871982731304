import {Box, Button, Paper} from "@mui/material";
import {Add} from "@mui/icons-material";
import {useAppTranslation} from "../app/appContext";
import {useAdminProps} from "../admin/adminContext";

export const AddExperiences = ({hasExperiences}) => {
    const t = useAppTranslation()
    const {
        onExperienceSelect,
        mobileView,
        adminMode
    } = useAdminProps()

    const addButton = (
        <Button
            variant={"contained"}
            startIcon={<Add />}
            size={"large"}
            onClick={onExperienceSelect}
        >
            {t('adminAddExperiences', "Legg til opplevelser")}
        </Button>
    )
    if(mobileView || !adminMode) {
        return null
    } else if(hasExperiences) {
        return (
            <Box
                sx={{
                    display: "flex",
                    width: "960px",
                    margin: "0px auto",
                    padding: "2px 0",
                    "@media (max-width: 960px)": {
                        width: "calc(100% - 48px)",
                        margin: "0px auto",
                        padding: "2px 0",
                    },
                    justifyContent: "center",
                    mt: 2,
                    mb: 2
                }}
            >
                {addButton}
            </Box>
        )
    } else {
        return (
            <Paper
                sx={{
                    display: "flex",
                    width: "960px",
                    margin: "0px auto",
                    padding: "24px 0",
                    "@media (max-width: 960px)": {
                        width: "calc(100% - 48px)",
                        margin: "0px auto",
                        padding: "24px 0",
                    },
                    justifyContent: "center",
                }}
            >
                {addButton}
            </Paper>
        )
    }

}
