import {ThemeProvider} from "react-jss";
import {useTheme} from "./useTheme";

export const Theme = ({children}) => {
    const theme = useTheme()
    console.debug({theme})
    return (
        <ThemeProvider
            theme={theme}
        >
            {children}
        </ThemeProvider>
    )
}