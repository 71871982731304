import {
    EXHIBITIONS_RECEIVED,
    useExhibitionDispatch,
    useExhibitionState,
} from "./exhibitionContext";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import { kitFetch } from "@ekultur/fetch";
import { getDmsUrl } from "@ekultur/dms-url-generator";

export const useExhibitions = () => {
    const { exhibitionTypes } = useExhibitionState();
    const dispatch = useExhibitionDispatch();

    useDeepCompareEffectNoCheck(() => {
        if (null == exhibitionTypes) {
            kitFetch("/vm-public/exhibitions/").then((json) =>
                dispatch({
                    type: EXHIBITIONS_RECEIVED,
                    exhibitionTypes: json.map((exhibition) => ({
                        ...exhibition,
                        background: exhibition.background
                            ? {
                                ...exhibition.background,
                                url: getDmsUrl(exhibition.background.dmsId)
                            }
                            : null,
                        preview: exhibition.preview
                            ? {
                                ...exhibition.preview,
                                url: getDmsUrl(exhibition.preview.dmsId)
                            } : null,
                        hero: exhibition.hero
                            ? {
                                ...exhibition.hero,
                                url: getDmsUrl(exhibition.hero.dmsId)
                            } : null
                    })),
                })
            );
        }
    }, [exhibitionTypes, dispatch]);

    return exhibitionTypes || [];
};
