import cx from "classnames";
import {ArrowLeftIcon} from "../arrowIcons/ArrowLeftIcon";
import {ArrowRightIcon} from "../arrowIcons/ArrowRightIcon";
import React from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(() => ({
    navigationButton: {
        zIndex: 100,
        position: "absolute",
        top: "calc(50% - 13px)",
        "&:hover": {
            opacity: 0.7,
        },
        "@media (max-width: 1024px)": {
            display: "none",
        },
    },
    prev: {
        left: 0,
        cursor: "pointer",
        marginLeft: 48,
        "@media (max-width: 1024px)": {
            marginLeft: 32,
        },
    },
    next: {
        right: 0,
        cursor: "pointer",
        marginRight: 48,
        "@media (max-width: 1024px)": {
            marginRight: 32,
        },
    },
}));

export const HeroNavigationButtons = ({previousSlide, nextSlide}) => {
    const classes = useStyles()
    return (
        <>
            <div
                className={cx(classes.navigationButton, classes.prev)}
                onClick={previousSlide}
            >
                <ArrowLeftIcon />
            </div>
            <div
                className={cx(classes.navigationButton, classes.next)}
                onClick={nextSlide}
            >
                <ArrowRightIcon />
            </div>
        </>
    )
}