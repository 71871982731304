import React, { createContext, useContext, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { PageLoader } from "../loading/PageLoader";

const ExhibitionStateContext = createContext(undefined);
const ExhibitionDispatchContext = createContext(undefined);
const ExhibitionTranslateContext = createContext(undefined);

export const EXHIBITIONS_RECEIVED = "exhibitionsReceived";

const initialState = {
    exhibitions: null,
}

const exhibitionReducer = (state, action) => {
    switch (action.type) {
        case EXHIBITIONS_RECEIVED:
            return {
                ...state,
                exhibitionTypes: action.exhibitionTypes,
            };
        default:
            throw new Error(`Unhandled action type ${action.type}`);
    }
};

export const ExhibitionProvider = ({ children }) => {
    const [state, dispatch] = useReducer(
        exhibitionReducer,
        { ...initialState },
        undefined
    );
    const { t, ready } = useTranslation("vm-portal.exhibition", {
        useSuspense: false,
    });

    if (!ready) {
        return <PageLoader />;
    } else {
        return (
            <ExhibitionStateContext.Provider value={state}>
                <ExhibitionDispatchContext.Provider value={dispatch}>
                    <ExhibitionTranslateContext.Provider value={t}>
                        {children}
                    </ExhibitionTranslateContext.Provider>
                </ExhibitionDispatchContext.Provider>
            </ExhibitionStateContext.Provider>
        );
    }
};

export const useExhibitionState = () => {
    const context = useContext(ExhibitionStateContext);

    if (undefined === context) {
        throw new Error(
            "useExhibitionState must be used within a ExhibitionProvider"
        );
    } else {
        return context;
    }
};

export const useExhibitionDispatch = () => {
    const context = useContext(ExhibitionDispatchContext);

    if (undefined === context) {
        throw new Error(
            "useExhibitionDispatch must be used within a ExhibitionProvider"
        );
    } else {
        return context;
    }
};

export const useExhibitionTranslation = () => {
    const context = useContext(ExhibitionTranslateContext);

    if (undefined === context) {
        throw new Error(
            "useExhibitionTranslation must be used within a ExhibitionProvider"
        );
    } else {
        return context;
    }
};
