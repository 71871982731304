import React, { useEffect } from "react";
import { MUSEUMS_RECEIVED, useAppDispatch } from "../app/appContext";
import { kitFetch } from "@ekultur/fetch";

export const WithMuseums = ({fetchEkulturData, children}) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(fetchEkulturData) {
            kitFetch("/museum-api/museums").then((json) =>
                dispatch({
                    type: MUSEUMS_RECEIVED,
                    museums: json.museums,
                })
            );
        }
    }, [dispatch, fetchEkulturData]);

    return (
        <>
            {children}
        </>
    )
};
