import React, {useState} from "react";
import {createUseStyles} from "react-jss";
import {useAppState} from "../app/appContext";
import {useMatch} from "react-router";
import useDeepCompareEffect from "use-deep-compare-effect";
import {useExhibition} from "../exhibition/useExhibition";
import {RoutesAnonymous} from "../routing/RoutesAnonymous";
import {PageFooter} from "../footer/PageFooter";
import {theme} from "../globalJss";

const useStyles = createUseStyles(({ colors }) => ({
    page: {
        backgroundColor: (props) => props?.color || colors.satinLinen,
        overflow: "hidden",
    },
}));

export const Page = () => {
    const [color, setColor] = useState(theme.colors.satinLinen);

    const matchMuseumPage = useMatch({
        path: "/museum/:id",
        exact: true,
    });
    const matchExhibitionPage = useMatch({
        path: "/museum/:museumId/exhibition/:exhibitionId",
        exact: true,
    });

    const { museums } = useAppState();
    const exhibition = useExhibition(matchExhibitionPage?.params?.exhibitionId);
    const classes = useStyles({ color });
    useDeepCompareEffect(() => {
        if (matchMuseumPage) {
            const museum = museums.find(
                (m) => m.id === Number(matchMuseumPage.params.id)
            );
            setColor(museum?.light_color);
        } else if (matchExhibitionPage && exhibition) {
            setColor(exhibition.color);
        } else {
            setColor(theme.colors.satinLinen);
        }
    }, [museums, exhibition]);
    return (
        <div className={classes.page}>
            <RoutesAnonymous />
            <PageFooter color={color} />
        </div>
    );
};
