import React from "react";

export const ArrowRightIcon = () => {
    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="24" cy="24" r="23" fill="#F5F5F7" fillOpacity="0.9" />
            <circle cx="24" cy="24" r="23" stroke="#0C0B0F" strokeWidth="2" />
            <path
                d="M23.2929 16.7071C22.9024 16.3166 22.9024 15.6834 23.2929 15.2929C23.6834 14.9024 24.3166 14.9024 24.7071 15.2929L31.7071 22.2929C32.0976 22.6834 32.0976 23.3166 31.7071 23.7071L24.7071 30.7071C24.3166 31.0976 23.6834 31.0976 23.2929 30.7071C22.9024 30.3166 22.9024 29.6834 23.2929 29.2929L28.5858 24H17C16.4477 24 16 23.5523 16 23C16 22.4477 16.4477 22 17 22H28.5858L23.2929 16.7071Z"
                fill="#0C0B0F"
            />
        </svg>
    );
};
