import {useEffect, useState} from "react";

export const useIsMobileView = (forceMobileView=false) => {
    const [isMobile, setIsMobile] = useState(
        window.matchMedia("(max-width: 375px)").matches
    )

    useEffect(() => {
        const onResize = () =>
            setIsMobile(window.matchMedia("(max-width: 375px)").matches);
        window.addEventListener("resize", () => onResize());
        return () =>
            window.removeEventListener("resize", () => () => onResize());
    })

    return isMobile || forceMobileView
}
