import {QuizProvider, WithQuizMicroFrontend, Quiz} from '@ekultur/quiz-microfrontend'

export const QuizExperience = ({exhibition, onClose}) => {
    const data = {
        quiz: exhibition.content,
    }

    return (
        <QuizProvider>
            <WithQuizMicroFrontend>
                <Quiz
                    data={data}
                    onClose={onClose}
                />
            </WithQuizMicroFrontend>
        </QuizProvider>
    )
}