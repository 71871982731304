import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";

const useStyles = createUseStyles(({ fontSize, lineHeight }) => ({
    typography: {
        margin: ({ tag }) => (tag === "p" ? "1rem 0" : 0),
        padding: 0,
        fontFamily: ({ type }) =>
            type === "mono" ? "Akkurat Mono" : "Akkurat",
        fontWeight: ({ bold }) => (bold ? "700" : "400"),
    },
    xs: { fontSize: fontSize.xs, lineHeight: lineHeight.leadingSnug },
    sm: { fontSize: fontSize.sm },
    base: { fontSize: fontSize.base },
    lg: { fontSize: fontSize.lg },
    xl: { fontSize: fontSize.xl, lineHeight: lineHeight.leadingRelaxed },
    xl2: { fontSize: fontSize.xl2, lineHeight: lineHeight.leadingSnug },
    xl3: { fontSize: fontSize.xl3, lineHeight: lineHeight.leadingTight },
    xl4: { fontSize: fontSize.xl4, lineHeight: lineHeight.leadingTight },
    xl5: { fontSize: fontSize.xl5, lineHeight: lineHeight.leadingTight },
    xl6: { fontSize: fontSize.xl6, lineHeight: lineHeight.leadingTight },
    xl7: { fontSize: fontSize.xl7, lineHeight: lineHeight.leadingTight },
    xl8: { fontSize: fontSize.xl8, lineHeight: lineHeight.leadingTight },
}));

export const Typography = ({
    tag: Tag = "p",
    size = "base",
    type = "regular",
    bold = false,
    tagRef=null,
    children,
    className = "",
    ...rest
}) => {
    const classes = useStyles({ type, bold, tag: Tag });

    return (
        <Tag
            className={`${classes.typography} ${classes[size]} ${className}`}
            ref={tagRef}
            {...rest}
        >
            {children}
        </Tag>
    );
};

Typography.propTypes = {
    tag: PropTypes.string,
    size: PropTypes.oneOf([
        "xs",
        "sm",
        "base",
        "lg",
        "xl",
        "xl2",
        "xl3",
        "xl4",
        "xl5",
        "xl6",
        "xl7",
        "xl8",
    ]),
    type: PropTypes.oneOf(["regular", "mono"]),
};
