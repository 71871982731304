import React from "react";
import {createUseStyles} from "react-jss";
import {useAppTranslation} from "../app/appContext";

const useStyles = createUseStyles(({
    imageCard: {
        maxWidth: 540,
        objectFit: "cover",
        borderRadius: 32,
        zIndex: 20,
        display: "flex",
        margin: "42px 0",
        "@media (max-width: 1024px)": {
            width: "100%",
        },
    },
    forceMobileView: {
        width: "100%",
    }
}))

export const ExhibitionInfoCard = ({preview, forceMobileView=false}) => {
    const classes = useStyles()
    const t = useAppTranslation()

    return (
        <img
            className={`${classes.imageCard} ${forceMobileView ? classes.forceMobileView : ""}`}
            src={preview?.url}
            alt={t("exhibitionPreview", "Utstilling Forhåndsvisning")}
        />
    )
}