import React from "react";
import { createUseStyles } from "react-jss";
import { useContrastApproved } from "../contrast/useContrast";
import { theme } from "../globalJss";

const useStyles = createUseStyles(({ colors }) => ({
    category: {
        minHeight: "100px",
        borderRadius: "16px",
        display: "grid",
        justifyContent: "center",
        alignContent: "center",
        backgroundImage: ({ backgroundImage, backgroundColor }) =>
            `linear-gradient(to bottom, ${backgroundColor}CC 0%, ${backgroundColor}CC 100%), url(${backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        color: ({ isContrastApproved }) =>
            isContrastApproved ? colors.black : colors.white,
        cursor: "pointer",
        transition: "transform 0.15s ease-out",
        "&:hover": {
            transform: "scale(1.05)",
        },
    },
}));

export const Category = ({
    title,
    backgroundImage,
    backgroundColor,
    className,
}) => {
    const isContrastApproved = useContrastApproved(
        backgroundColor,
        theme.colors.black
    );
    const classes = useStyles({
        backgroundImage,
        backgroundColor,
        isContrastApproved,
    });
    return <div className={`${classes.category} ${className}`}>{title}</div>;
};
