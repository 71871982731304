import {createContext, useContext, useReducer} from "react";
import {getDmsUrl} from "@ekultur/dms-url-generator";

const AdminPropsContext = createContext(undefined)
const AdminStateContext = createContext(undefined)
const AdminDispatchContext = createContext(undefined)

export const LAYOUT_SETTINGS_OPENED = 'layoutSettingsOpened'
export const LAYOUT_SETTINGS_CLOSED = 'layoutSettingsClosed'

const initialState = {
    editingLayout: null
}

const adminReducer = (state, action) => {
    switch (action.type) {
        case LAYOUT_SETTINGS_OPENED:
            return {
                ...state,
                editingLayout: action.layoutType
            }
        case LAYOUT_SETTINGS_CLOSED:
            return {
                ...state,
                editingLayout: null
            }
        default:
            throw new Error(`Unhandled action type ${action.type}`)
    }
}

export const AdminProvider = ({
                                  onMediaSelect,
                                  onChange,
                                  onExperienceSelect,
                                  data,
                                  children,
                                  onExperienceRemove,
                                  mobileView,
                                  errors = {},
                                    adminMode = true,
                                    museum = null
                              }) => {
    if (
        !onMediaSelect
        || !onChange
        || !data
        || !onExperienceRemove
    ) {
        throw new Error("onMediaSelect, onChange, onExperienceRemove, and data props are mandatory")
    }

    const [state, dispatch] = useReducer(adminReducer, {
        ...initialState,
    })

    return (
        <AdminPropsContext.Provider value={{
            onMediaSelect: onMediaSelect,
            onChange: onChange,
            onExperienceSelect: onExperienceSelect,
            onExperienceRemove: onExperienceRemove,
            mobileView: mobileView,
            errors: errors,
            adminMode: adminMode,
            museum: museum,
            data: {
                ...data,
                background: data.background
                    ? {
                        ...data.background,
                        url: getDmsUrl(data.background.dmsId, "01")
                    }
                    : null,
                preview: data.preview
                    ? {
                        ...data.preview,
                        url: getDmsUrl(data.preview.dmsId, "01")
                    } : null,
                hero: data.hero
                    ? {
                        ...data.hero,
                        url: getDmsUrl(data.hero.dmsId, "01")
                    } : null

            }
        }}>
            <AdminStateContext.Provider value={state}>
                <AdminDispatchContext.Provider value={dispatch}>
                    {children}
                </AdminDispatchContext.Provider>
            </AdminStateContext.Provider>
        </AdminPropsContext.Provider>
    )
}

export const useAdminProps = () => {
    const context = useContext(AdminPropsContext)
    if (!context) {
        throw new Error("useAdminProps must be used within a AdminProvider")
    } else {
        return context
    }
}

export const useAdminState = () => {
    const context = useContext(AdminStateContext)
    if (!context) {
        throw new Error("useAdminState must be used within a AdminProvider")
    } else {
        return context
    }
}

export const useAdminDispatch = () => {
    const context = useContext(AdminDispatchContext)
    if (!context) {
        throw new Error("useAdminDispatch must be used within a AdminProvider")
    } else {
        return context
    }
}
