import {createUseStyles} from "react-jss";
import {Typography} from "../typography/Typography";
import React, {useRef} from "react";
import {CardContentStack} from "./CardContentStack";
import {useIsOverflow} from "../overflow/useIsOverflow";

const useStyles = createUseStyles({
    ingress: {
        textAlign: "center",
    },
    lineClamp: {
        lineClamp: 2,
        display: "-webkit-box",
        overflow: "hidden",
        boxOrient: "vertical"
    }
})

export const SmallCardText = ({readMode=false, onToggleReadMode, text}) => {
    const classes = useStyles()
    const ref = useRef()
    const isOverflow = useIsOverflow(ref)

    const handleToggleReadMode = e => {
        e.preventDefault()
        onToggleReadMode()
    }

    const readModeLink = () => {
        if(isOverflow) {
            return (
                <a
                    href={""}
                    onClick={handleToggleReadMode}
                >
                    {readMode ? "Skjul tekst" : "Les mer"}
                </a>
            )
        } else {
            return ""
        }
    }

    return (
        <CardContentStack>
            <Typography
                tag="p"
                size={"sm"}
                className={`${classes.ingress} ${readMode ? "" : classes.lineClamp}`}
                tagRef={ref}
            >
                {text}
            </Typography>
            {readModeLink()}
        </CardContentStack>
    )
}