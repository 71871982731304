import {createUseStyles} from "react-jss";
import {animated, useSpring} from "@react-spring/web";
import {useBreakpoints} from "../layout/useBreakpoints";
import {useMediaQuery} from "../layout/useMediaQuery";
import {down} from "../layout/breakpoints";

const useStyles = createUseStyles(({breakpoints}) => ({
    exhibitionCard: {
        width: "100%",
        display: "grid",
        gridAutoRows: "1fr",
        gridTemplateColumns: "20fr 10fr",
        minHeight: 384,
        [breakpoints.small]: {
            height: "100%",
            gridTemplateColumns: "1fr",
            gridTemplateRows: "4fr 3fr"
        }
    },
}))

export const LargeExperienceCard = ({readMode, children}) => {
    const classes = useStyles()
    const breakpoints = useBreakpoints()
    const matchSmallDown = useMediaQuery(down(breakpoints.small))

    const toColumns = readMode ? "10fr 10fr" : "20fr 10fr"
    const fromColumns = readMode ? "20fr 10fr": "10fr 10fr"

    const toRows = readMode ? "0fr 3fr" : "4fr 3fr"
    const fromRows = readMode ? "4fr 3fr" : "0fr 3fr"

    const propsDesktop = useSpring({
        to: { gridTemplateColumns: toColumns },
        from: { gridTemplateColumns: fromColumns }
    })

    const propsMobile = useSpring({
        to: { gridTemplateRows: toRows },
        from: { gridTemplateRows: fromRows }
    })

    return (
        <animated.div
            className={`${classes.exhibitionCard}`}
            style={matchSmallDown ? propsMobile : propsDesktop}
        >
            {children}
        </animated.div>
    )
}