import React from "react";

export const ArrowLeftIcon = () => {
    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="24" cy="24" r="23" fill="#F5F5F7" fillOpacity="0.9" />
            <circle cx="24" cy="24" r="23" stroke="#0C0B0F" strokeWidth="2" />
            <path
                d="M24.7071 16.7071C25.0976 16.3166 25.0976 15.6834 24.7071 15.2929C24.3166 14.9024 23.6834 14.9024 23.2929 15.2929L16.2929 22.2929C15.9024 22.6834 15.9024 23.3166 16.2929 23.7071L23.2929 30.7071C23.6834 31.0976 24.3166 31.0976 24.7071 30.7071C25.0976 30.3166 25.0976 29.6834 24.7071 29.2929L19.4142 24H31C31.5523 24 32 23.5523 32 23C32 22.4477 31.5523 22 31 22H19.4142L24.7071 16.7071Z"
                fill="#0C0B0F"
            />
        </svg>
    );
};
