import React from "react";
import {ExhibitionRoom} from "../room/ExhibitionRoom";
import {skeleton} from "./skeleton";
import {getDmsUrl} from "@ekultur/dms-url-generator";

export const RoomWithImages = ({images}) => {
    const yo = skeleton

    const content = {
        ...yo,
        images: yo.images.map((image, index) => ({
            ...image,
            viewerData: {
                ...image.viewerData,
                model: {
                    ...image.viewerData.model,
                    url:`${getDmsUrl(images[index])}?dimension=1200x1200`
                }
            },
            url: `${getDmsUrl(images[index])}?dimension=${image.scale == 0.4 ? '250x250' : '1200x1200'}`
        }))
    }



    return (
        <ExhibitionRoom
            content={content}
            onClose={() => console.log('close')}
        />
    )
}