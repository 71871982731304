import React from "react";
import { Room } from "@ekultur/3d-microfrontend";
import { With3D } from "../3d/With3D";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
    roomContainer: {
        display: "flex",
        height: "100vh",
        maxHeight: "fill-available"
    },
    container: {
        width: "100%",
    },
});

export const ExhibitionRoom = ({ content, onClose }) => {
    const classes = useStyles();
    return (
        <div className={classes.roomContainer}>
            <With3D classname={classes.container}>
                <Room data={content} onClose={onClose} />
            </With3D>
        </div>
    );
};
