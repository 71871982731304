import React from "react";
import {createUseStyles} from "react-jss";
import {useContrastApproved} from "../contrast/useContrast";
import {theme} from "../globalJss";

const useStyles = createUseStyles(({ colors }) => ({
    header: {
        minHeight: "100vh",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: ({ exhibitionColor }) =>
            exhibitionColor || colors.satinLinen,
        color: ({ contrastApproved }) =>
            contrastApproved ? colors.black : colors.white,
    },
    backgroundImage: {
        height: "40vh",
        width: "100%",
        backgroundImage: ({ backgroundImage, exhibitionColor }) =>
            `linear-gradient(to bottom, ${exhibitionColor}B3 0%, ${
                exhibitionColor || colors.satinLinen
            } 100%), url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: ({ backgroundPositionX }) =>
            `${backgroundPositionX}%` || "50%",
        backgroundPositionY: ({ backgroundPositionY }) =>
            `${backgroundPositionY}%` || "50%",
        position: "absolute",
    },
    content: {
        position: "relative",
        display: "flex",
        paddingTop: "7rem",
        flexDirection: "column",
        justifyContent: "center",
        zIndex: 100,
    },
}));

const backgroundPosition = mediaRef => {
    if(mediaRef) {
        const x = mediaRef.focusX || 0
        const y = mediaRef.focusY || 0
        const backgroundPositionX = ((x + 1) * 100) / 2;
        const backgroundPositionY = ((-y + 1) * 100) / 2;
        return { backgroundPositionX, backgroundPositionY };
    } else {
        return {
            backgroundPositionX: 0,
            backgroundPositionY: 0
        }
    }
}

const headerBackground = (background, preview) => {
   if(background) {
        return background
    } else {
        return preview
    }
}

export const ExhibitionPageHeader = ({
    exhibitionColor="#ffffff",
    background,
    preview,
    children
}) => {
    const contrastApproved = useContrastApproved(
        exhibitionColor || theme.colors.satinLinen,
        theme.colors.black
    )
    const finalBackground = headerBackground(background, preview)

    const classes = useStyles({
        backgroundImage: finalBackground?.url,
        exhibitionColor: exhibitionColor,
        contrastApproved,
        ...backgroundPosition(finalBackground)
    })

    return (
        <div className={classes.header} id="header">
            <div className={classes.backgroundImage} />
            <div className={classes.content}>
                {children}
            </div>
        </div>
    );
};
