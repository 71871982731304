import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import { kitFetch } from "@ekultur/fetch";
import { getDmsUrl } from "@ekultur/dms-url-generator";
import {HIGHLIGHTED_EXHIBITIONS_RECEIVED, useHeroDispatch, useHeroState} from "./heroContext";

export const useHighlightedExhibitions = () => {
    const { exhibitions } = useHeroState()
    const heroDispatch = useHeroDispatch()

    useDeepCompareEffectNoCheck(() => {
        if (null == exhibitions || exhibitions.length < 1) {
            kitFetch("/vm-public/highlighted-exhibitions/")
                .then((json) => {
                    heroDispatch({
                        type: HIGHLIGHTED_EXHIBITIONS_RECEIVED,
                        exhibitions: json
                            .map((exhibition) => ({
                                ...exhibition,
                                exhibition: {
                                    ...exhibition.exhibition,
                                    background: exhibition.exhibition.background
                                        ? {
                                            ...exhibition.exhibition.background,
                                            url: getDmsUrl(exhibition.exhibition.background.dmsId)
                                        }
                                        : null,
                                    preview: exhibition.exhibition.preview
                                        ? {
                                            ...exhibition.exhibition.preview,
                                            url: getDmsUrl(exhibition.exhibition.preview.dmsId)
                                        } : null,
                                    hero: exhibition.exhibition.hero
                                        ? {
                                            ...exhibition.exhibition.hero,
                                            url: getDmsUrl(exhibition.exhibition.hero.dmsId)
                                        } : null
                                },
                            }))
                            .sort((a, b) => a.order - b.order),
                    })
                }
            );
        }
    }, [exhibitions, heroDispatch]);

    return exhibitions || [];
};
