import { FrontPage } from "../front/FrontPage";
import { MuseumPage } from "../museum/MuseumPage";
import { ExhibitionPage } from "../exhibition/ExhibitionPage";
import { MuseumsPage } from "../museums/MuseumsPage";
import { SearchPage } from "../search/SearchPage";
import { PrivacyPage } from "../privacy/PrivacyPage";
import { CopyrightPage } from "../copyright/CopyrightPage";
import { AboutPage } from "../about/AboutPage";
import {DmFolderExhibition} from "../dm/DmFolderExhibition";

export const RoutesAnonymous = () => {
    return (
        <>
            <FrontPage />
            <MuseumPage />
            <ExhibitionPage />
            <MuseumsPage />
            <SearchPage />
            <PrivacyPage />
            <CopyrightPage />
            <AboutPage />
            <DmFolderExhibition />
        </>
    );
};
