import {useQueryParams} from "../app/useQueryParams";

export const useRunExperience = () => {
    const [queryParams, setQueryParams] = useQueryParams()

    const withoutNullValues = newParams => Object.entries(newParams).reduce((previous, [key, value]) => {
        if(value) {
            return {
                ...previous,
                [key]: value
            }
        } else {
            return previous
        }
    }, {})

    const update = selectedContentId => {
        const queryParamsAsObject = Array.from(queryParams.entries()).reduce((previous, [param, value]) => ({
            ...previous,
            [param]: value
        }), {})

        setQueryParams(withoutNullValues({
            ...queryParamsAsObject,
            experienceId: selectedContentId
        }))
    }

    return {
        setActiveExperienceId: update,
        activeExperienceId: queryParams.get('experienceId')
    }
}