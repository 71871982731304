import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme) => ({
    progressBar: {
        marginTop: 10,
        height: 4,
        background: "rgba(255,255,255,0.4)",
        borderRadius: 1,
    },
    progress: {
        height: 4,
        color: theme.colors.white,
        background: theme.colors.white,
        width: 0,
    },
}));

export const ProgressBar = ({ duration, showLabel }) => {
    const classes = useStyles();
    const [progress, setProgress] = useState(0);
    useEffect(() => {
        const timeout = setTimeout(function () {
            if (progress < 100) {
                setProgress(progress + 1);
            }
        }, duration / 100);
        return () => clearTimeout(timeout);
    }, [progress, setProgress, duration]);
    return (
        <div className={classes.progressBar}>
            <div
                className={classes.progress}
                style={{ width: `${progress}%` }}
            />
            {showLabel && <label>{`${progress}%`}</label>}
        </div>
    );
};
