import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({breakpoints}) => ({
    root: {
        display: "flex",
        alignItems: ({large}) => large ? "flex-start" : "center",
        flexDirection: "column",
        [breakpoints.medium]: {
            alignItems: "center",
            flexDirection: "column"
        }

    },
}))
export const CardContentStack = ({large=false, children}) => {
    const classes = useStyles({
        large: large,
    })

    return (
        <div className={classes.root}>
            {children}
        </div>
    )
}