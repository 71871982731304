import React, {useEffect} from "react";
import {createUseStyles} from "react-jss";
import {HeroCarousel} from "../hero/HeroCarousel";
import {useAppTranslation} from "../app/appContext";
import {ScrollButton} from "../scrollButton/ScrollButton";
import {useHighlightedExhibitions} from "../hero/useHightlightedExhibition";
import {useFadeHero} from "../hero/useFadeHero";
import {Hero} from "../hero/Hero";
import {HeroImage} from "../hero/HeroImage";
import {HeroContent} from "../hero/HeroContent";
import {Hero3dModel} from "../hero/Hero3dModel";
import {useHeroState} from "../hero/heroContext";

const useStyles = createUseStyles({
    heroPage: {
        height: "90vh",
    },
    bottomContent: {
        height: "10vh",
        display: "flex",
        justifyContent: "center",
    },
});

export const FrontPageHero = ({ onSetBlackFont }) => {
    const classes = useStyles();
    const {
        currentIndex,
        duration,
        blackFont
    } = useHeroState()

    const t = useAppTranslation();
    const exhibitions = useHighlightedExhibitions();

    const transitions = useFadeHero({
        currentIndex: currentIndex,
        animationDuration: 800
    })

    useEffect(() => {
        onSetBlackFont(blackFont)
    }, [blackFont]);

    if(exhibitions) {
        return (
            <div className={classes.heroPage}>
                <HeroCarousel>
                    {transitions((style, item) => (
                        <Hero
                            key={item}
                            style={style}
                        >
                            <HeroImage
                                exhibition={exhibitions[item]?.exhibition}
                                duration={duration}
                            />
                            <HeroContent
                                exhibitionName={exhibitions[item]?.exhibition?.name}
                                museumId={exhibitions[item]?.exhibition?.museumId}
                                exhibitionId={exhibitions[item]?.exhibition?.uniqueId}
                                blackFont={exhibitions[item]?.exhibition?.blackFont}
                            />
                            <Hero3dModel
                                model3d={exhibitions[item]?.exhibition?.model3d}
                            />
                        </Hero>
                    ))}
                </HeroCarousel>
                <div className={classes.bottomContent}>
                    <ScrollButton
                        content={t("moreExhibitions", "Flere utstillinger")}
                        href="/#body"
                    />
                </div>
            </div>
        )
    } else {
        return null
    }
};
