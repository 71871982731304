import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({
    largeContent: {
        justifyContent: "center",
        width: "100%",
        minHeight: "280px",
    },
    paddedContent: {
        padding: 40
    }
}))

export const ExperienceLargeContent = ({children}) => {
    const classes = useStyles()
    return (
        <div
            className={classes.largeContent}
        >
            <div
                className={classes.paddedContent}
            >
                {children}
            </div>
        </div>
    )
}