import { ExhibitionRoom } from "../room/ExhibitionRoom";
import { ExhibitionThreeSixty } from "../three-sixty/ExhibitionThreeSixty";
import { ExhibitionScrollytelling } from "../scrollytelling/ExhibitionScrollytelling";
import { useAppState } from "../app/appContext";
import {QuizExperience} from "../quiz/QuizExperience";

export const Exhibition = ({ exhibition, exhibitionType, onClose }) => {
    const { museumId, content } = exhibition;
    const { museums } = useAppState();
    const museum = museums.find((m) => m.id === Number(museumId));
    const exhibitionTypes = () => ({
        "3droom": <ExhibitionRoom content={content} onClose={onClose} />,
        "360": <ExhibitionThreeSixty content={content} onClose={onClose} />,
        "scrollytell": (
            <ExhibitionScrollytelling
                exhibition={exhibition}
                museum={museum}
                content={content}
                onClose={onClose}
            />
        ),
        "photoSeries": (
            <ExhibitionScrollytelling
                exhibition={exhibition}
                museum={museum}
                content={content}
                onClose={onClose}
            />
        ),
        "quiz": (
            <QuizExperience
                exhibition={exhibition}
                onClose={onClose}
            />
        )
    });

    if (content) {
        return exhibitionTypes()[exhibitionType?.type];
    } else {
        return null;
    }
};
