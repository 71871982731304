import {ExhibitionCardSizes} from "../exhibition/helpers";
import {SmallExperienceLayoutRow} from "./SmallExperienceLayoutRow";
import {MediumExperienceLayoutRow} from "./MediumExperienceLayoutRow";
import {LargeExperienceLayoutRow} from "./LargeExperienceLayoutRow";

export const SizedExperienceLayoutRow = ({size, children}) => {
    if(ExhibitionCardSizes.SMALL === size) {
        return (
            <SmallExperienceLayoutRow>
                {children}
            </SmallExperienceLayoutRow>
        )
    } else if (ExhibitionCardSizes.MEDIUM === size) {
        return (
            <MediumExperienceLayoutRow>
                {children}
            </MediumExperienceLayoutRow>
        )
    } else if (ExhibitionCardSizes.LARGE === size) {
        return (
            <LargeExperienceLayoutRow>
                {children}
            </LargeExperienceLayoutRow>
        )
    } else {
        throw new Error(`Unhandled size ${size}`)
    }
}