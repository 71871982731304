import {Nav} from "./Nav";
import {NavLeftSide} from "./NavLeftSide";
import {BackgroundButton} from "./BackgroundButton";
import {Box} from "@mui/material";
import {useAdminProps} from "../admin/adminContext";

export const AdminNav = () => {
    const {
        mobileView,
        adminMode
    } = useAdminProps()

    return (
        <Nav>
            <NavLeftSide>
                <Box
                    sx={mobileView || !adminMode ? {display: "none"} : {}}
                >
                    <BackgroundButton />
                </Box>
            </NavLeftSide>
        </Nav>
    )
}