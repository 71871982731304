import {IconButton, Paper, Stack} from "@mui/material";
import {ArrowDownward, ArrowUpward, Settings} from "@mui/icons-material";
import {LAYOUT_SETTINGS_OPENED, useAdminDispatch, useAdminProps} from "../admin/adminContext";

export const EditLayoutRow = ({layoutType, layoutsWithContent}) => {
    const {
        data,
        onChange
    } = useAdminProps()


    const dispatch = useAdminDispatch()

    const handleSettingsClick = () => {
        dispatch({
            type: LAYOUT_SETTINGS_OPENED,
            layoutType: layoutType
        })
    }

    const sortedWithContent = layoutsWithContent.sort((a, b) => a.priority - b.priority)
    const currentIndex = sortedWithContent.findIndex(swc => swc.type === layoutType)

    const onMoveLayoutUp = () => {
        const updatedLayout = data.layout.map(l => {
            if(l.type === layoutType) {
                return {
                    ...l,
                    priority: sortedWithContent[currentIndex - 1].priority
                }
            } else if(l.priority === sortedWithContent[currentIndex - 1].priority) {
                return {
                    ...l,
                    priority: sortedWithContent[currentIndex].priority
                }
            } else {
                return l
            }
        })

        onChange({
            key: 'layout',
            value: updatedLayout
        })
    }

    const onMoveLayoutDown = () => {
        const updatedLayout = data.layout.map(l => {
            if(l.type === layoutType) {
                return {
                    ...l,
                    priority: sortedWithContent[currentIndex + 1].priority
                }
            } else if(l.priority === sortedWithContent[currentIndex + 1].priority) {
                return {
                    ...l,
                    priority: sortedWithContent[currentIndex].priority
                }
            } else {
                return l
            }
        })

        onChange({
            key: 'layout',
            value: updatedLayout
        })
    }

    return (
        <Stack
            spacing={1}
            sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                mt: "-24px",
                ml: "1110px"
            }}
        >
            <Paper
                sx={{
                    width: 34,
                    ':hover': {
                        boxShadow: theme => theme.shadows[4]
                    },
                }}
                elevation={1}
            >
                <IconButton
                    size={"small"}
                    color={"primary"}
                    aria-label={"move row up"}
                    disabled={0 === currentIndex}
                    onClick={() => onMoveLayoutUp()}
                >
                    <ArrowUpward />
                </IconButton>
            </Paper>
            <Paper
                sx={{
                    width: 34,
                    ':hover': {
                        boxShadow: theme => theme.shadows[4]
                    },
                }}
                elevation={1}
            >
                <IconButton
                    size={"small"}
                    color={"primary"}
                    aria-label={"move row down"}
                    disabled={currentIndex + 1  === sortedWithContent.length}
                    onClick={() => onMoveLayoutDown()}
                >
                    <ArrowDownward />
                </IconButton>
            </Paper>
            <Paper
                sx={{
                    width: 34,
                    ':hover': {
                        boxShadow: theme => theme.shadows[4]
                    },
                }}
                elevation={1}
            >
                <IconButton
                    size={"small"}
                    color={"primary"}
                    aria-label={"row settings"}
                    onClick={handleSettingsClick}
                >
                    <Settings />
                </IconButton>
            </Paper>
        </Stack>
    )
}