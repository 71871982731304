import {LAYOUT_SETTINGS_CLOSED, useAdminDispatch, useAdminProps, useAdminState} from "../admin/adminContext";
import {Box, FormControl, IconButton, InputLabel, MenuItem, Radio, Select, Stack, Typography} from "@mui/material";
import {useAppTranslation} from "../app/appContext";
import {Close, Info, ViewColumn, ViewStream} from "@mui/icons-material";
import {LayoutSettingsAccordion} from "./LayoutSettingsAccordion";

export const LayoutSettings = ({drawerWidth}) => {
    const t = useAppTranslation()
    const adminDispatch = useAdminDispatch()
    const {
        editingLayout,
    } = useAdminState()

    const {
        data,
        onChange
    } = useAdminProps()

    const handleClose = () => {
        adminDispatch({
            type: LAYOUT_SETTINGS_CLOSED
        })
    }

    const experiencesInLayoutCount = data.exhibitionContents.filter(ec => ec.exhibitionType.type === editingLayout).length
    const currentLayout = data.layout.find(layout => layout.type === editingLayout)
    const desktopHorizontalDisabled = experiencesInLayoutCount < 5 || "small" !== currentLayout.size

    const updatedLayout = (key, value) => {
        return data.layout.map(l => {
            if(l.type === editingLayout) {
                return {
                    ...l,
                    [key]: value
                }
            } else {
                return l
            }
        })
    }

    const handleChangeSize = event => {
        onChange({
            key: 'layout',
            value: updatedLayout('size', event.target.value)
        })
    }

    const handleChangeMobileDirection = event => {
        onChange({
            key: 'layout',
            value: updatedLayout('mobileVertical', event.target.value === 'true')
        })
    }

    const handleChangeDesktopDirection = event => {
        onChange({
            key: 'layout',
            value: updatedLayout('desktopVertical', event.target.value === 'true')
        })
    }

    if(drawerWidth) {
        return (
            <Box
                sx={{
                    width: drawerWidth,
                    backgroundColor: "white",
                    flexShrink: 0,
                }}
            >
                <Box
                    sx={{
                        position: "sticky",
                        top: 0,
                    }}
                >
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{
                            padding: 2
                        }}
                    >
                        <Typography
                            variant={"h5"}
                        >
                            {t(`adminLayoutSettingsHeader.${editingLayout}`, editingLayout)}
                        </Typography>
                        <IconButton
                            onClick={handleClose}
                        >
                            <Close />
                        </IconButton>
                    </Stack>
                    <LayoutSettingsAccordion
                        id={"entrances"}
                        summaryLabel={t('adminLayoutSettingsEntrancesHeader', 'Innganger')}
                    >
                        <FormControl fullWidth>
                            <InputLabel id="select-size-label">
                                {t('adminLayoutChooseSize', 'Velg størrelse')}
                            </InputLabel>
                            <Select
                                labelId="select-size-label"
                                id="select-size"
                                value={currentLayout.size}
                                label={t('adminLayoutChooseSize', 'Velg størrelse')}
                                onChange={handleChangeSize}
                            >
                                <MenuItem value={"small"}>
                                    {t('adminLayoutSizeSmall', 'Små innganger')}
                                </MenuItem>
                                <MenuItem value={"medium"}>
                                    {t('adminLayoutSizeMedium', 'Medium innganger')}
                                </MenuItem>
                                <MenuItem value={"large"}>
                                    {t('adminLayoutSizeLarge', 'Store innganger')}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </LayoutSettingsAccordion>
                    <LayoutSettingsAccordion
                        id={"directionMobile"}
                        summaryLabel={t('adminLayoutSettingsMobileDirectionHeader', 'Visningsretning på mobil')}
                    >
                        <Stack
                            direction={"row"}
                            alignItems={'center'}
                            justifyContent={"flex-start"}
                        >
                            <Radio
                                checked={!currentLayout.mobileVertical}
                                value={false}
                                name={"directionMobile"}
                                onChange={handleChangeMobileDirection}
                            />
                            <Typography>
                                {t('adminLayoutSettingsMobileDirectionHorizontal', 'Horisontalt med scroll')}
                            </Typography>
                            <Box flexGrow={1} />
                            <ViewColumn
                                color={!currentLayout.mobileVertical ? 'primary' : 'disabled'}
                            />
                        </Stack>
                        <Stack
                            direction={"row"}
                            alignItems={'center'}
                            justifyContent={"flex-start"}
                        >
                            <Radio
                                checked={currentLayout.mobileVertical}
                                value={true}
                                name={"directionMobile"}
                                onChange={handleChangeMobileDirection}
                            />
                            <Typography>
                                {t('adminLayoutSettingsMobileDirectionVertical', 'Vertikalt')}
                            </Typography>
                            <Box flexGrow={1} />
                            <ViewStream
                                color={currentLayout.mobileVertical ? 'primary' : 'disabled'}
                            />
                        </Stack>
                    </LayoutSettingsAccordion>
                    <LayoutSettingsAccordion
                        id={"directionDesktop"}
                        summaryLabel={t('adminLayoutSettingsDesktopDirectionHeader', 'Visningsretning på stor skjerm')}
                    >
                        <Stack
                            direction={"row"}
                            alignItems={'center'}
                            justifyContent={"flex-start"}
                        >
                            <Radio
                                checked={!currentLayout.desktopVertical}
                                value={false}
                                name={"directionDesktop"}
                                onChange={handleChangeDesktopDirection}
                                disabled={desktopHorizontalDisabled}
                            />
                            <Typography>
                                {t('adminLayoutSettingsDesktopDirectionHorizontal', 'Horisontalt')}
                            </Typography>
                            <Box flexGrow={1} />
                            <ViewColumn
                                color={!currentLayout.desktopVertical ? 'primary' : 'disabled'}
                            />
                        </Stack>
                        <Stack
                            direction={"row"}
                            alignItems={'center'}
                            justifyContent={"flex-start"}
                        >
                            <Radio
                                checked={currentLayout.desktopVertical}
                                value={true}
                                name={"directionDesktop"}
                                onChange={handleChangeDesktopDirection}
                                disabled={desktopHorizontalDisabled}
                            />
                            <Typography>
                                {t('adminLayoutSettingsDesktopDirectionVertical', 'Vertikalt')}
                            </Typography>
                            <Box flexGrow={1} />
                            <ViewStream
                                color={currentLayout.desktopVertical ? 'primary' : 'disabled'}
                            />
                        </Stack>
                        <Stack
                            direction={"row"}
                            alignItems={'flex-start'}
                            justifyContent={"flex-start"}
                            spacing={2}
                            border={1}
                            sx={{
                                mt: 2,
                                padding: 2
                            }}
                        >
                            <Info />
                            <Typography variant={"body2"}>
                                Visningsretningen for store skjermer er låst til det finnes 5 eller flere små innganger av samme type
                            </Typography>
                        </Stack>
                    </LayoutSettingsAccordion>
                </Box>
            </Box>
        )
    } else {
        return null
    }
}