import {useMatch} from "react-router";
import {Header} from "../header/Header";
import {Suspense} from "react";
import {LoadingLogo} from "../loading/LoadingLogo";
import {createUseStyles} from "react-jss";
import {useAppTranslation} from "../app/appContext";
import {Typography} from "../typography/Typography";
import {PortalNav} from "../header/PortalNav";

const useStyles = createUseStyles({
    aboutPage: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "163px",
        maxWidth: "1024px",
        margin: "0 auto 30px auto",
        "@media (max-width: 1040px)": {
            maxWidth: "751px",
        },
        "@media (max-width: 770px)": {
            maxWidth: "480px",
        },
        "@media (max-width: 500px)": {
            maxWidth: "240px",
        },
    },
    header: {},
});

export const AboutPage = () => {
    const classes = useStyles();
    const t = useAppTranslation();
    const match = useMatch({
        path: "/about",
        exact: true,
    });

    if (match) {
        return (
            <Suspense fallback={<LoadingLogo />}>
                <Header>
                    <PortalNav
                        logoMode="black"
                        includeBreadcrumb
                        match={match}
                        rootsOnly
                    />
                </Header>
                <div className={classes.aboutPage}>
                    <Typography
                        tag="h1"
                        size="xl5"
                        bold
                        className={classes.header}
                    >
                        {t("aboutTitle", "Om virtuelt museum")}
                    </Typography>
                    <Typography tag="p">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Suspendisse sit amet ligula vel diam venenatis semper.
                        Ut ultrices euismod tortor vel gravida. Duis a efficitur
                        dui. Sed sed diam mauris. Duis tristique aliquam velit.
                        Quisque dapibus volutpat euismod. Phasellus vitae sem
                        auctor, varius tellus eget, ultricies dolor. Vivamus
                        suscipit sodales consectetur. Proin id ex quis arcu
                        cursus vulputate. Pellentesque in lectus consequat,
                        maximus elit a, sodales massa. Mauris dapibus nisl et
                        luctus vestibulum. Vestibulum convallis risus libero, ut
                        facilisis sem vulputate eu. Proin vehicula ac ipsum sed
                        feugiat. Mauris pulvinar nisl ac congue dapibus.
                    </Typography>
                    <br />
                    <Typography tag="p">
                        Maecenas sodales non turpis ut dignissim. Phasellus eget
                        venenatis ligula. Suspendisse placerat, lectus vitae
                        pretium euismod, nisi tellus porta urna, sit amet semper
                        leo nibh nec justo. Vestibulum condimentum odio a tellus
                        molestie, ut tempor leo maximus. Nunc aliquet arcu ut
                        metus pulvinar gravida. Cras iaculis aliquet sapien nec
                        vulputate. Fusce at metus eget dolor venenatis interdum.
                        Nullam blandit nisl vel eros consectetur vulputate at eu
                        purus. Praesent efficitur pretium magna, at tincidunt
                        nulla accumsan placerat. Phasellus tristique, tortor non
                        vehicula sollicitudin, purus nulla luctus metus, et
                        condimentum lorem leo a erat. Donec ac metus pulvinar,
                        tristique dui convallis, finibus metus. In tincidunt
                        semper magna. Curabitur quis turpis auctor, volutpat est
                        non, ornare sem.
                    </Typography>
                </div>
            </Suspense>
        );
    } else {
        return null;
    }
};
