import {Button} from "../button/Button";
import React from "react";
import {createUseStyles} from "react-jss";
import {useAppTranslation} from "../app/appContext";
import {useIsMobileView} from "../app/useIsMobileView";
import {EditHeroTitle} from "./EditHeroTitle";
import {Stack} from "@mui/material";
import {useAdminHeroProps} from "../admin/adminHeroContext";
import {EditFontColor} from "./EditFontColor";
import {Typography} from "../typography/Typography";
import {HeroBackgroundButton} from "./HeroBackgroundButton";

const useStyles = createUseStyles(({ colors, fontSize, lineHeight }) => ({
    content: {
        display: "flex",
        flexDirection: "column",
        zIndex: "103",
        margin: "0 10% 120px",
        justifyContent: "center",

        "@media (max-width: 1024px)": {
            width: "100%",
            margin: "0 2rem 120px",
        },
        "@media (max-width: 550px)": {
            justifyContent: "flex-end",
        },
        "@media (max-width: 375px)": {
            margin: "0 1.5rem 120px",
        },
    },
    title: {
        color: ({ blackFont }) => (blackFont ? colors.black : colors.white),
        margin: "0 0 20px 0",
        "@media (max-width: 1440px)": {
            fontSize: fontSize.xl7,
            lineHeight: lineHeight.leadingTight,
        },
        "@media (max-width: 1024px)": {
            fontSize: fontSize.xl6,
            lineHeight: lineHeight.leadingTight,
        },
        "@media (max-width: 550px)": {
            fontSize: fontSize.xl4,
            lineHeight: lineHeight.leadingTight,
        },
    },
}))

export const EditHeroContent = ({exhibitionName, blackFont, museumId, exhibitionId}) => {
    const classes = useStyles({
        blackFont: blackFont,
    })

    const t = useAppTranslation()

    const {
        onChange,
        mobileView,
        data
    } = useAdminHeroProps()
    const isMobile = useIsMobileView(mobileView)

    const exhibitionClickHandler = (museumId, exhibitionId) =>
        console.debug(`museum/${museumId}/exhibition/${exhibitionId}`)

    const updatedHero = dmsId => {
        if(data.hero) {
            const {url, ...currentHero} = data.hero
            return {
                ...currentHero,
                dmsId: dmsId
            }
        } else {
            return {
                dmsId: dmsId,
                focusX: 0,
                focusY: 0
            }
        }
    }

    const onFontColorSelected = blackFont => {
        onChange({
            key: "blackFont",
            value: blackFont
        })
    }

    const title = mobileView ?
        (
            <Typography tag="h1" size="xl8" bold className={classes.title}>
                {exhibitionName}
            </Typography>
        )
        :
        (
            <EditHeroTitle
                title={exhibitionName}
                blackFont={blackFont}
            />
        )

    return (
        <div className={classes.content}>
            <Stack
                direction={"row"}
                spacing={2}
                sx={{
                    mt: "-36px",
                    mb: "36px",
                    display: mobileView ? "none" : "flex"
                }}
            >
                <HeroBackgroundButton />
                <EditFontColor
                    blackFont={blackFont}
                    onFontColorSelected={onFontColorSelected}
                />
            </Stack>
            {title}
            <Button
                type={!isMobile ? "large" : "normal"}
                color="white"
                label={t("heroButtonLabel", "Se utstillingen")}
                onClick={() =>
                    exhibitionClickHandler(
                        museumId,
                        exhibitionId
                    )
                }
            />
        </div>
    )
}