import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useAppTranslation} from "../app/appContext";

export const FocusPointDialog = ({open=false, onClose, title=null, children}) => {
    const t = useAppTranslation()
    const dialogTitle = title ? title : t('focusPointDialog.title', 'Trykk for å velge fokuspunkt')
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="focus-point-dialog-title"
        >
            <DialogTitle>
                {dialogTitle}
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="primary"
                    variant={"contained"}
                >
                    {t('closeButton', 'Lukk')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}