import {createContext, useContext, useReducer} from "react";
import {useTranslation} from "react-i18next";
import {ExhibitionProvider} from "../exhibition/exhibitionContext";
import {WithMuseums} from "../museum/WithMuseums";
import {PageLoader} from "../loading/PageLoader";

const AppStateContext = createContext(undefined);
const AppDispatchContext = createContext(undefined);
const AppTranslationContext = createContext(undefined);

export const MUSEUMS_RECEIVED = "museumsReceived";
export const MENU_OPENED = 'menuOpened'
export const MENU_CLOSED = 'menuClosed'

const initialState = {
    museums: [],
    menuOpen: false,
};

const appReducer = (state, action) => {
    console.debug({action})
    switch (action.type) {
        case MUSEUMS_RECEIVED:
            return {
                ...state,
                museums: action.museums,
            }
        case MENU_OPENED:
            return {
                ...state,
                menuOpen: true
            }
        case MENU_CLOSED:
            return {
                ...state,
                menuOpen: false
            }
        default:
            throw new Error(`Unhandled action type ${action.type}`);
    }
};

export const AppProvider = ({ fetchEkulturData=true, children }) => {
    const [state, dispatch] = useReducer(
        appReducer,
        { ...initialState },
        undefined
    );

    const { t, ready } = useTranslation("vm-portal.app", {
        useSuspense: false,
    });

    if (!ready) {
        return (
            <AppTranslationContext.Provider value={t}>
                <PageLoader />
            </AppTranslationContext.Provider>
        );
    } else {
        return (
            <AppStateContext.Provider value={state}>
                <AppDispatchContext.Provider value={dispatch}>
                    <AppTranslationContext.Provider value={t}>
                        <WithMuseums
                            fetchEkulturData={fetchEkulturData}
                        >
                            <ExhibitionProvider>{children}</ExhibitionProvider>
                        </WithMuseums>
                    </AppTranslationContext.Provider>
                </AppDispatchContext.Provider>
            </AppStateContext.Provider>
        );
    }
};

export const useAppState = () => {
    const context = useContext(AppStateContext);
    if (undefined === context) {
        throw new Error(`useAppState must be used witin an AppProvider`);
    } else {
        return context;
    }
};

export const useAppDispatch = () => {
    const context = useContext(AppDispatchContext);
    if (undefined === context) {
        throw new Error(`useAppDispatch must be used within an AppProvider`);
    } else {
        return context;
    }
};

export const useAppTranslation = () => {
    const context = useContext(AppTranslationContext);
    if (undefined === context) {
        throw new Error("useAppTranslation must be used within an AppProvider");
    } else {
        return context;
    }
};
