import {useMatch} from "react-router";
import {FrontPageHero} from "./FrontPageHero";
import {FrontPageBody} from "./FrontPageBody";
import {createUseStyles} from "react-jss";
import {Header} from "../header/Header";
import {FrontPageCategories} from "./FrontPageCategories";
import {useState} from "react";
import {theme} from "../globalJss";
import {PortalNav} from "../header/PortalNav";
import {HeroProvider} from "../hero/heroContext";

const useStyles = createUseStyles({
    frontPage: {},
});

export const FrontPage = () => {
    const classes = useStyles();
    const [blackFont, setBlackFont] = useState(false);
    const match = useMatch({
        path: "/",
        exact: true,
    });

    if (match) {
        return (
            <div className={classes.frontPage}>
                <Header>
                    <PortalNav
                        match={match}
                        color={blackFont ? theme.colors.white : theme.colors.black}
                    />
                </Header>
                <HeroProvider>
                    <FrontPageHero
                        onSetBlackFont={setBlackFont}
                    />
                </HeroProvider>
                <FrontPageBody />
                <FrontPageCategories />
            </div>
        );
    } else {
        return null;
    }
};
