

export const routes = [
    {
        path: "/",
        translation: "breadcrumbs.home",
        defaultTranslation: "Hjem",
    },
    {
        path: "/museum/:museumId",
        translation: "breadcrumbs.museum",
        defaultTranslation: "Museum",
    },
    {
        path: "/museum/:museumId/exhibition/:exhibitionId",
        translation: "breadcrumbs.exhibition",
        defaultTranslation: "Utstilling",
    },
];
