import React, {useState} from "react";
import {createUseStyles} from "react-jss";
import cx from "classnames";
import {a, useSpring} from "@react-spring/web";

import {Button} from "../button/Button";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {ArrowRight} from "react-feather";
import {Typography} from "../typography/Typography";
import {useContrastApproved} from "../contrast/useContrast";
import {theme} from "../globalJss";

const useStyles = createUseStyles(({ colors }) => ({
    infoCardWrapper: {
        display: "flex",
        flexDirection: "column",
    },
    infoCard: {
        display: "flex",
        position: "relative",
        margin: "16px 16px 0 0",
    },
    frontside: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 32,
        zIndex: 2,
        "will-change": "transform",
        transition: "transform 0.1s ease-out 0s",
        "&:hover": {
            transform: "scale(1.05)",
            cursor: "pointer",
        },
    },
    backside: {
        zIndex: 1,
        position: "absolute",
        top: "-16px",
        left: "16px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "calc(100% - 80px)",
        height: "calc(100% - 80px)",
        borderRadius: 32,
        padding: 40,
        backgroundColor: ({ color }) => color || colors.sisal,
        color: ({ isContrastApproved }) =>
            isContrastApproved ? colors.black : colors.white,
    },
    content: {
        margin: "0 0 20px 0",
        textAlign: "center",
    },
    button: {
        width: "100%",
        backgroundColor: colors.white,
    },
    title: {
        margin: "10px 0 0 0",
        textAlign: "center",
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

export const InfoCard = ({
    title,
    content,
    buttonText,
    buttonClick,
    color,
    animationDirection,
    className,
    mediaRef
}) => {
    const [state, toggle] = useState(true);
    const [spring, api] = useSpring(() => ({
        x: 0,
        config: { mass: 1, tension: 280, friction: 60 },
    }));
    const isContrastApproved = useContrastApproved(
        color || theme.colors.sisal,
        theme.colors.black
    );

    const classes = useStyles({ color, isContrastApproved });
    return (
        <div className={classes.infoCardWrapper}>
            <div className={cx(classes.infoCard, className)}>
                <a.div
                    style={{
                        zIndex: spring.x.to({
                            range: [0, 0.49, 0.51, 1],
                            output: [2, 2, 1, 1],
                        }),
                        transform: spring.x.to({
                            range: [0, 0.2, 0.4, 0.5, 0.6, 0.8, 1],
                            output:
                                animationDirection === "vertical"
                                    ? [
                                          "translate(0px,0px)",
                                          "translate(0px,-105px)",
                                          "translate(0px,-200px)",
                                          "translate(10px,-210px)",
                                          "translate(20px,-200px)",
                                          "translate(20px,-105px)",
                                          "translate(20px,-0px)",
                                      ]
                                    : [
                                          "translate(0px,0px)",
                                          "translate(50px,0px)",
                                          "translate(125px,-0px)",
                                          "translate(175px,0px)",
                                          "translate(125px,0px)",
                                          "translate(50px,0px)",
                                          "translate(0px,0px)",
                                      ],
                        }),
                    }}
                    onClick={() => {
                        toggle(!state);
                        api.start({ x: state ? 1 : 0 });
                    }}
                >
                    <LazyLoadImage
                        src={mediaRef?.url}
                        alt="test"
                        className={classes.frontside}
                    />
                </a.div>
                <a.div
                    className={classes.backside}
                    style={{
                        transform: spring.x.to({
                            range: [0, 0.2, 0.4, 0.5, 0.6, 0.8, 1],
                            output:
                                animationDirection === "vertical"
                                    ? [
                                          "translate(0px,0px)",
                                          "translate(0px,105px)",
                                          "translate(0px,200px)",
                                          "translate(-10px,210px)",
                                          "translate(-20px,200px)",
                                          "translate(-20px,105px)",
                                          "translate(-20px,0px)",
                                      ]
                                    : [
                                          "translate(0px,0px)",
                                          "translate(-50px,0px)",
                                          "translate(-125px,-0px)",
                                          "translate(-175px,0px)",
                                          "translate(-125px,0px)",
                                          "translate(-50px,0px)",
                                          "translate(0px,0px)",
                                      ],
                        }),
                    }}
                    onClick={() => {
                        toggle(!state);
                        api.start({ x: state ? 1 : 0 });
                    }}
                >
                    {content && (
                        <Typography
                            tag="p"
                            size="base"
                            className={classes.content}
                        >
                            {content}
                        </Typography>
                    )}
                    {buttonClick && (
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                buttonClick();
                            }}
                            label={buttonText}
                            className={classes.button}
                            type="large"
                            outline
                            Icon={<ArrowRight />}
                            iconRight
                        />
                    )}
                </a.div>
            </div>
            {title && (
                <Typography
                    tag="h2"
                    size="base"
                    bold
                    className={classes.title}
                    onClick={(e) => {
                        e.stopPropagation();
                        buttonClick();
                    }}
                >
                    {title}
                </Typography>
            )}
        </div>
    );
};

InfoCard.defaultProps = {
    animationDirection: "horizontal",
};
