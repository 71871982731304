import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({
    rightContainer: {
        display: "flex",
        flexDirection: "row",
    },
}))

export const NavRightSide = ({children}) => {
    const classes = useStyles()
    return (
        <div className={classes.rightContainer}>
            {children}
        </div>
    )
}