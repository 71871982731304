import React, { useState } from "react";
import cx from "classnames";
import { createUseStyles } from "react-jss";
import { string, bool } from "prop-types";
import { Check, X } from "react-feather";
import { Typography } from "../typography/Typography";

const useStyles = createUseStyles(({ colors }) => ({
    chip: {
        display: "inline-flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: 48,
        minWidth: 111,
        borderRadius: 999,
        color: colors.black,
        border: `2px solid ${colors.vmGray500}`,
        padding: "0 24px",
        cursor: "pointer",
        backgroundColor: ({ checked }) =>
            !checked ? `${colors.black}1A` : colors.peach,
        boxShadow: ({ checked }) =>
            checked ? "2px 2px 0px 0px rgba(39, 38, 43, 0.9)" : "none",
        "&:hover": {
            backgroundColor: ({ checked }) =>
                checked ? `${colors.peach}CC` : `${colors.black}0D`,
            border: `2px solid ${colors.black}`,
        },
        "&:active": {
            backgroundColor: colors.peach,
        },
    },
    label: {
        cursor: "pointer",
        marginLeft: (props) => (props.checked ? "9px" : 0),
    },
}));

export const Chip = ({ label, checked, className, ...rest }) => {
    const classes = useStyles({ checked });
    const [hover, setHover] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    return (
        <div
            className={cx(classes.chip, className)}
            {...rest}
            onMouseEnter={() => {
                if (checked) {
                    if (hasChanged) {
                        setHover(true);
                    } else {
                        setHasChanged(true);
                    }
                }
            }}
            onMouseLeave={() => {
                if (checked) {
                    if (hasChanged) {
                        setHover(false);
                    } else {
                        setHasChanged(true);
                    }
                }
            }}
        >
            {!checked ? null : !hover ? <Check /> : <X />}
            <Typography tag="label" size="base" className={classes.label}>
                {label}
            </Typography>
        </div>
    );
};

Chip.propTypes = {
    label: string,
    checked: bool,
};

Chip.defaultProps = {
    label: "",
};
