import {Typography} from "../typography/Typography";
import {truncate} from "../exhibition/helpers";
import React from "react";
import {CardContentStack} from "./CardContentStack";
import {createUseStyles} from "react-jss";


const useStyles = createUseStyles({
    body: {
        textAlign: "center"
    }
})

export const MediumCardText = ({text, title}) => {
    const classes = useStyles()
    return (
        <CardContentStack>
            <Typography
                tag="h3"
                size={"xl2"}
                bold
            >
                {title}
            </Typography>
            <Typography
                tag="p"
                size={"base"}
                className={classes.body}
            >
                {truncate(text, 100, true)}
            </Typography>
        </CardContentStack>
    )
}