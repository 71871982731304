import React from "react";
import { createUseStyles } from "react-jss";
import { ExperienceCard } from "../experience/ExperienceCard";
import { ExhibitionCardSizes } from "./helpers";

const useStyles = createUseStyles(({breakpoints}) => ({
    exhibitionContentsRow: {
        display: "flex",
        width: "1080px",
        margin: "24px auto",
        [breakpoints.large]: {
            width: "calc(100% - 48px)",
            margin: "24px auto",
        },
        gap: 24
    },
}))

export const SmallExperienceRow = ({
    exhibitionContents,
    startExhibition = () => {},
}) => {
    const classes = useStyles()
    return (
        <div className={classes.exhibitionContentsRow}>
            {exhibitionContents?.map((ec, i) => (
                <ExperienceCard
                    key={`exhibitionCard-${ec.contentId}-${i}`}
                    exhibition={ec}
                    size={ExhibitionCardSizes.SMALL}
                    onClick={startExhibition}
                    siblings={exhibitionContents.length - 1}
                />
            ))}
        </div>
    )
};
