import React, {lazy, Suspense} from "react";
import {useMatch} from "react-router";
import {Exhibition} from "./Exhibition";
import {LoadingLogo} from "../loading/LoadingLogo";
import {Header} from "../header/Header";
import {createUseStyles} from "react-jss";
import {useExhibition} from "./useExhibition";
import {MuseumHeader} from "./MuseumHeader";
import {ExhibitionHeader} from "./ExhibitionHeader";
import {ExhibitionInfo} from "./ExhibitionInfo";
import {ExhibitionName} from "./ExhibitionName";
import {ExhibitionDescription} from "./ExhibitionDescription";
import {Experiences} from "./Experiences";
import {PortalNav} from "../header/PortalNav";
import {ExhibitionInfoCard} from "./ExhibitionInfoCard";
import {ExhibitionInfoText} from "./ExhibitionInfoText";
import {useRunExperience} from "../experience/useRunExperience";

const ExhibitionPageHeader = lazy(() =>
    import("./ExhibitionPageHeader").then((module) => ({
        default: module.ExhibitionPageHeader,
    }))
);
const ExhibitionPageContent = lazy(() =>
    import("./ExhibitionPageContent").then((module) => ({
        default: module.ExhibitionPageContent,
    }))
);
const ExhibitionPageFeedback = lazy(() =>
    import("./ExhibitionPageFeedback").then((module) => ({
        default: module.ExhibitionPageFeedback,
    }))
);
const ExhibitionPageItems = lazy(() =>
    import("./ExhibitionPageItems").then((module) => ({
        default: module.ExhibitionPageItems,
    }))
);

const useStyles = createUseStyles({
    exhibitionWrapper: {
        position: "fixed",
        width: "100%",
        height: "100%",
        top: 0,
        zIndex: 1000,
    },
});

export const ExhibitionPage = () => {
    const {
        setActiveExperienceId,
        activeExperienceId
    } = useRunExperience()

    const match = useMatch({
        path: "/museum/:museumId/exhibition/:exhibitionId",
        exact: true,
    });

    const setSelectedExhibitionContent = exhibition => {
        if(exhibition) {
            setActiveExperienceId(exhibition.contentId)
        } else {
            setActiveExperienceId(null)
        }
    }

    const classes = useStyles({
        dialogOpen: activeExperienceId !== null,
    });
    const exhibition = useExhibition(match?.params.exhibitionId);

    const getActiveExperience = () => {
        if(exhibition && activeExperienceId) {
            return exhibition.exhibitionContents.find(ec => ec.contentId === activeExperienceId)
        } else {
            return null
        }
    }

    if (match) {
        if(getActiveExperience()) {
            return (
                <div className={classes.exhibitionWrapper}>
                    <Exhibition
                        exhibition={getActiveExperience().data}
                        exhibitionType={
                            getActiveExperience()?.exhibitionType
                        }
                        onClose={() => setSelectedExhibitionContent(null)}
                    />
                </div>
            )
        } else {
            return (
                <Suspense fallback={<LoadingLogo />}>
                    <Header>
                        <PortalNav
                            logoMode="black"
                            includeBreadcrumb
                            match={match}
                            color={exhibition?.color}
                        />
                    </Header>
                    <ExhibitionPageHeader
                        exhibitionColor={exhibition?.color}
                        background={exhibition?.background}
                        preview={exhibition?.preview}
                        startExhibition={setSelectedExhibitionContent}
                    >
                        <MuseumHeader museumId={match.params.museumId}/>
                        <ExhibitionHeader
                            exhibition={exhibition}
                        >
                            <ExhibitionInfo>
                                <ExhibitionName name={exhibition?.name} />
                                <ExhibitionDescription description={exhibition?.ingress} />
                            </ExhibitionInfo>
                            <Experiences
                                exhibition={exhibition}
                                startExhibition={setSelectedExhibitionContent}
                            >
                            </Experiences>
                        </ExhibitionHeader>
                    </ExhibitionPageHeader>
                    <ExhibitionPageFeedback exhibition={exhibition} />
                    <ExhibitionPageContent color={exhibition?.color}>
                        <ExhibitionInfoCard preview={exhibition?.preview} />
                        <ExhibitionInfoText moreText={exhibition?.moreText} />
                    </ExhibitionPageContent>
                    <ExhibitionPageItems exhibition={exhibition} />
                    {getActiveExperience() && (
                        <div className={classes.exhibitionWrapper}>
                            <Exhibition
                                exhibition={getActiveExperience().data}
                                exhibitionType={
                                    getActiveExperience()?.exhibitionType
                                }
                                onClose={() => setSelectedExhibitionContent(null)}
                            />
                        </div>
                    )}
                </Suspense>
            );
        }

    } else {
        return null;
    }
};
