import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useExhibitions } from "../exhibition/useExhibitions";
import { useAppState, useAppTranslation } from "../app/appContext";
import { FilterTags } from "../filter/FilterTags";
import { ExhibitionList } from "./ExhibitionList";
import { Typography } from "../typography/Typography";

const useStyles = createUseStyles(({ fontSize, lineHeight }) => ({
    body: {
        marginTop: "10vh",
    },
    content: {
        display: "flex",
        padding: "3rem 0",
        flexDirection: "column",
        alignItems: "center",
        width: "1200px",
        margin: "0 auto",
        "@media (max-width: 1264px)": {
            margin: "0 32px",
            width: "initial",
        },
        "@media (max-width: 1024px)": {
            margin: "0 32px",
        },
        "@media (max-width: 375px)": {
            margin: "0 24px",
        },
        "& p": {
            textAlign: "center",
        },
    },
    frontpageBodyTitle: {
        "@media (max-width: 1440px)": {
            fontSize: fontSize.xl4,
            lineHeight: lineHeight.leadingTight,
        },
        "@media (max-width: 420px)": {
            fontSize: fontSize.xl2,
            lineHeight: lineHeight.leadingSnug,
        },
    },
    cards: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        "@media (max-width: 714px)": {
            justifyContent: "center",
        },
    },
    description: {
        margin: "1rem 0 2rem",
        "@media (max-width: 1440px)": {
            fontSize: fontSize.lg,
        },
        "@media (max-width: 420px)": {
            fontSize: fontSize.base,
        },
    },
}));

export const FrontPageBody = () => {
    const classes = useStyles();
    const { museums } = useAppState();
    const t = useAppTranslation();
    const exhibitions = useExhibitions();
    const [filter, setFilter] = useState([]);

    const filteredExhibitions = filter.length
        ? exhibitions.filter((exhibition) =>
              filter.every((t) => !t.checked)
                  ? true
                  : filter.some(
                        (t) =>
                            exhibition.exhibitionContents.some(
                                (ex) => ex.exhibitionType.type === t.type
                            ) && t.checked
                    )
          )
        : exhibitions;

    if (museums) {
        return (
            <div id="body" className={classes.body}>
                <div className={classes.content}>
                    <Typography
                        tag="h2"
                        size="xl5"
                        bold
                        className={classes.frontpageBodyTitle}
                    >
                        {t("frontpageBodyTitle", "Utforsk opplevelsene")}
                    </Typography>
                    <Typography
                        tag="p"
                        size="xl"
                        type="mono"
                        className={classes.description}
                    >
                        {t(
                            "frontpageBodyDescription",
                            "Utforsk museenes virtuelle ustillinger, historier, gjenstander og museer"
                        )}
                    </Typography>
                    <FilterTags
                        initialData={exhibitions}
                        onToggleType={setFilter}
                        onClear={() => setFilter([])}
                    />

                    <ExhibitionList
                        exhibitions={filteredExhibitions}
                        museums={museums}
                    />
                </div>
            </div>
        );
    } else {
        return null;
    }
};
