import React from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({
    exhibition: {
        display: "flex",
        flexDirection: "column",
        margin: "0 auto",
        width: "100%",
    },

}));

export const ExhibitionHeader = ({exhibition, children}) => {
    const classes = useStyles()

    if (exhibition) {
        return (
            <div className={classes.exhibition}>
                {children}
            </div>
        )
    } else {
        return null
    }
}
