import React from "react";
import { createUseStyles } from "react-jss";
import cx from "classnames";
import {
    ThreeDProvider,
    WithThreeDMicroFrontend,
} from "@ekultur/3d-microfrontend";

const useStyles = createUseStyles({
    container: {},
});

export const With3D = ({ classname, children }) => {
    const classes = useStyles();

    return (
        <ThreeDProvider>
            <WithThreeDMicroFrontend>
                <div className={cx(classes.container, classname)}>
                    {children}
                </div>
            </WithThreeDMicroFrontend>
        </ThreeDProvider>
    );
};
