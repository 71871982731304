import React from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    root: {
        height: "0",
        minHeight: "100%"
    },
    image: {
        objectFit: "cover",
        width: "100%",
        height: "100%",
    },
})

export const ExperienceCardImage = ({previewImage, size, className}) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <img
                src={previewImage}
                className={`${classes.image} ${size} ${className}`}
                alt="exhibition"
            />
        </div>
    )
}