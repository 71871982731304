import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Locize from "i18next-locize-backend";
import LanguageDetector from "i18next-browser-languagedetector";
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Locize)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: "nb-NO",
        debug: process.env.NODE_ENV === "development",

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        saveMissing: true,
        backend: {
            projectId: "e54181f0-c2de-4f0b-90ce-84d5df542424",
            apiKey: process.env.REACT_APP_LOCIZE_API_KEY,

            referenceLng: "nb-NO",
            fallbacklng: "nb-NO",
            allowedAddOrUpdateHosts: ["localhost"],
        },
    });

export default i18n;
