import {useEffect, useState} from "react";
import {kitFetch} from "@ekultur/fetch";

export const useFolderImages = folderId => {
    const [response, setResponse] = useState([])
    useEffect(
        () => {
            if(folderId) {
                kitFetch(`/vm-public/dm/folder/${folderId}/`)
                    .then(json => setResponse(json))
            }

        }, [folderId]
    )

    return response
}