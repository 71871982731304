import React from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(theme => ({
    exhibitionInfo: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0 auto",
        [theme.breakpoints.extraSmall]: {
            paddingLeft: 16,
            paddingRight: 16
        }
    },
}));

export const ExhibitionInfo = ({children}) => {
    const classes = useStyles()

    return (
        <div className={classes.exhibitionInfo}>
            {children}
        </div>
    )
}
