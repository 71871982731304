import React from "react";
import { createUseStyles } from "react-jss";
import { Category } from "./Category";

const useStyles = createUseStyles(() => ({
    categories: {
        width: "100%",
        maxWidth: "1200px",
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gridColumnGap: "8px",
        gridRowGap: "8px",

        "@media (min-width: 800px)": {
            gridTemplateColumns: "repeat(6, 1fr)",
            gridTemplateRows: "repeat(3, 1fr)",
            gridColumnGap: "16px",
            gridRowGap: "16px",
        },

        "& .category1": {
            gridArea: "1 / 1 / 4 / 2",
            "@media (min-width: 800px)": {
                gridArea: "1 / 1 / 4 / 2",
            },
        },
        "& .category2": {
            gridArea: "1 / 2 / 2 / 3",
            "@media (min-width: 800px)": {
                gridArea: "1 / 2 / 2 / 3",
            },
        },
        "& .category3": {
            gridArea: "2 / 2 / 4 / 3",
            borderRadius: "999px",
            "@media (min-width: 800px)": {
                gridArea: "2 / 2 / 4 / 3",
            },
        },
        "& .category4": {
            gridArea: "4 / 1 / 6 / 2",
            "@media (min-width: 800px)": {
                gridArea: "1 / 3 / 3 / 4",
            },
        },
        "& .category5": {
            gridArea: "6 / 1 / 7 / 2",
            "@media (min-width: 800px)": {
                gridArea: "3 / 3 / 4 / 4",
            },
        },
        "& .category6": {
            gridArea: "4 / 2 / 7 / 3",
            borderRadius: "999px",
            "@media (min-width: 800px)": {
                gridArea: "1 / 4 / 4 / 5",
            },
        },
        "& .category7": {
            gridArea: "7 / 1 / 9 / 2",
            "@media (min-width: 800px)": {
                gridArea: "1 / 5 / 3 / 6",
            },
        },
        "& .category8": {
            gridArea: "9 / 1 / 10 / 2",
            borderRadius: "999px",
            "@media (min-width: 800px)": {
                gridArea: "3 / 5 / 4 / 6",
            },
        },
        "& .category9": {
            gridArea: "7 / 2 / 10 / 3",
            "@media (min-width: 800px)": {
                gridArea: "1 / 6 / 4 / 7",
            },
        },
    },
}));

export const Categories = ({ categories, className }) => {
    const classes = useStyles();
    return (
        <div className={`${classes.categories} ${className}`}>
            {categories.map((c, i) => (
                <Category
                    key={c.title}
                    title={c.title}
                    backgroundImage={c.image}
                    backgroundColor={c.color}
                    className={`category${i + 1}`}
                />
            ))}
        </div>
    );
};
