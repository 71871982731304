import React from "react";
import { createUseStyles } from "react-jss";
import { ExperienceCard } from "../experience/ExperienceCard";
import { ExhibitionCardSizes } from "./helpers";

const useStyles = createUseStyles(({breakpoints}) => ({
    exhibitionContentsRow: {
        display: "flex",
        width: "1080px",
        margin: "24px auto",
        [breakpoints.large]: {
            width: "calc(100% - 48px)",
            margin: "24px auto",
        },
        gap: 24
    },
}))

export const LargeExperienceRow = ({
    exhibitionContents,
    startExhibition = () => {},
}) => {
    const classes = useStyles()
    return exhibitionContents.map((ec) => (
        <div
            className={classes.exhibitionContentsRow}
            key={`exhibitionCard-${ec.contentId}`}
        >
            <ExperienceCard
                exhibition={ec}
                size={ExhibitionCardSizes.LARGE}
                onClick={startExhibition}
                siblings={0}
            />
        </div>
    ))

};
