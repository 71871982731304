import {Typography} from "../typography/Typography";
import {Button} from "../button/Button";
import React from "react";
import {createUseStyles} from "react-jss";
import {useNavigate} from "react-router";
import {useAppTranslation} from "../app/appContext";
import {useIsMobileView} from "../app/useIsMobileView";

const useStyles = createUseStyles(({ colors, fontSize, lineHeight }) => ({
    content: {
        display: "flex",
        flexDirection: "column",
        zIndex: "103",
        margin: "0 10% 120px",
        justifyContent: "center",

        "@media (max-width: 1024px)": {
            width: "100%",
            margin: "0 2rem 120px",
        },
        "@media (max-width: 550px)": {
            justifyContent: "flex-end",
        },
        "@media (max-width: 375px)": {
            margin: "0 1.5rem 120px",
        },
    },
    title: {
        color: ({ blackFont }) => (blackFont ? colors.black : colors.white),
        margin: "0 0 20px 0",
        "@media (max-width: 1440px)": {
            fontSize: fontSize.xl7,
            lineHeight: lineHeight.leadingTight,
        },
        "@media (max-width: 1024px)": {
            fontSize: fontSize.xl6,
            lineHeight: lineHeight.leadingTight,
        },
        "@media (max-width: 550px)": {
            fontSize: fontSize.xl4,
            lineHeight: lineHeight.leadingTight,
        },
    },
}))

export const HeroContent = ({exhibitionName, blackFont, museumId, exhibitionId}) => {
    const navigate = useNavigate()
    const classes = useStyles({
        blackFont: blackFont,
    })

    const t = useAppTranslation()
    const isMobile = useIsMobileView()

    const exhibitionClickHandler = (museumId, exhibitionId) =>
        navigate(`museum/${museumId}/exhibition/${exhibitionId}`);

    return (
        <div className={classes.content}>
            <Typography tag="h1" size="xl8" bold className={classes.title}>
                {exhibitionName}
            </Typography>
            <Button
                type={!isMobile ? "large" : "normal"}
                color="white"
                label={t("heroButtonLabel", "Se utstillingen")}
                onClick={() =>
                    exhibitionClickHandler(
                        museumId,
                        exhibitionId
                    )
                }
            />
        </div>
    )
}