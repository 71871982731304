import {ExhibitionCardSizes} from "./helpers";
import {SmallExperienceRow} from "./SmallExperienceRow";
import {MediumExperienceRow} from "./MediumExperienceRow";
import {LargeExperienceRow} from "./LargeExperienceRow";

export const ExperienceRow = ({size, ...rest}) => {

    if(ExhibitionCardSizes.SMALL === size) {
        return <SmallExperienceRow {...rest} />
    } else if(ExhibitionCardSizes.MEDIUM === size) {
        return <MediumExperienceRow  {...rest} />
    } else if(ExhibitionCardSizes.LARGE === size) {
        return <LargeExperienceRow {...rest} />
    } else {
        throw new Error(`Unhandled size ${size}`)
    }
}