import {Exhibition} from "../exhibition/Exhibition";
import React from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles({
    exhibitionWrapper: {
        position: "fixed",
        width: "100%",
        height: "100%",
        top: 0,
        zIndex: 1000,
    },
});

export const ActiveExperienceContent = ({exhibition, activeExperienceId, onClose}) => {


    const classes = useStyles()

    const getActiveExperience = () => {
        if(exhibition && activeExperienceId) {
            return exhibition.exhibitionContents.find(ec => ec.contentId === activeExperienceId)
        } else {
            return null
        }
    }

    const activeExperience = getActiveExperience()

    if(exhibition && activeExperience) {
        return (
            <div className={classes.exhibitionWrapper}>
                <Exhibition
                    exhibition={getActiveExperience().data}
                    exhibitionType={
                        getActiveExperience()?.exhibitionType
                    }
                    onClose={onClose}
                />
            </div>
        )
    } else {
        return null
    }
}