import React, {Suspense, useState} from "react";
import {createUseStyles} from "react-jss";
import {useMatch} from "react-router";
import {LoadingLogo} from "../loading/LoadingLogo";
import {Header} from "../header/Header";
import {FilterTags} from "../filter/FilterTags";
import {SearchField} from "./SearchField";
import {useExhibitions} from "../exhibition/useExhibitions";
import {useAppState, useAppTranslation} from "../app/appContext";
import {Typography} from "../typography/Typography";
import {ExhibitionList} from "../front/ExhibitionList";
import {PortalNav} from "../header/PortalNav";

const useStyles = createUseStyles({
    searchPage: {
        display: "flex",
        minHeight: "40vh",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "163px",
        margin: "0 auto",
    },
    results: {
        display: "flex",
        padding: "20px 0",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "1200px",
        margin: "0 auto",
        "@media (max-width: 1024px)": {
            margin: "0 32px",
        },
        "@media (max-width: 375px)": {
            margin: "0 24px",
        },
    },
    hitsText: {
        marginBottom: "37px",
    },
    description: {
        margin: "11px 0 36px 0",
    },
    resultItems: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        alignItems: "center",
    },
});

export const SearchPage = () => {
    const classes = useStyles();
    const match = useMatch({
        path: "/search",
        exact: true,
    });

    const t = useAppTranslation();
    const exhibitions = useExhibitions();
    const { museums } = useAppState();
    const [query, setQuery] = useState("");
    const [results, setResults] = useState([]);
    const [filter, setFilter] = useState([]);

    if (match) {
        const filteredResults = filter.length
            ? results.filter((exhibition) =>
                  filter.every((t) => !t.checked)
                      ? true
                      : filter.some(
                            (t) =>
                                t.type === exhibition.exhibitionType.type &&
                                t.checked
                        )
              )
            : results;
        return (
            <Suspense fallback={<LoadingLogo />}>
                <Header>
                    <PortalNav
                        logoMode="black"
                        includeBreadcrumb
                        match={match}
                        rootsOnly
                    />
                </Header>
                <div className={classes.searchPage}>
                    <Typography tag="h1" size="xl4" bold>
                        {t("searchPageTitle", "Søk i Virtuelt Museum")}
                    </Typography>
                    <Typography
                        tag="p"
                        size="lg"
                        type="mono"
                        className={classes.description}
                    >
                        {t(
                            "searchPageDescription",
                            "Søk etter ustillinger, historier, gjenstander og museer"
                        )}
                    </Typography>
                    <SearchField
                        onSearch={(q) => {
                            setQuery(q);
                            setResults(
                                q !== ""
                                    ? exhibitions
                                          .map((exhibition) => {
                                              const museum = museums.find(
                                                  (museum) =>
                                                      museum.id ===
                                                      exhibition.museumId
                                              );
                                              return {
                                                  ...exhibition,
                                                  museum: museum.name,
                                              };
                                          })
                                          .filter((exhibition) =>
                                              Object.values(exhibition)
                                                  .toString()
                                                  .toLowerCase()
                                                  .includes(q.toLowerCase())
                                          )
                                    : []
                            );
                        }}
                        onClear={() => {
                            setQuery("");
                            setResults([]);
                        }}
                    />
                    <div className={classes.results}>
                        {query.length > 0 && (
                            <Typography
                                tag="h4"
                                size="xl2"
                                bold
                                className={classes.hitsText}
                            >
                                {`${results.length} ${t(
                                    "searchHits",
                                    "treff på"
                                )} «${query}»`}
                            </Typography>
                        )}
                        {filteredResults.length > 0 && (
                            <>
                                <FilterTags
                                    initialData={exhibitions}
                                    onToggleType={setFilter}
                                    onClear={() => setFilter([])}
                                />
                                <div className={classes.resultItems}>
                                    <ExhibitionList
                                        exhibitions={filteredResults}
                                        museums={museums}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Suspense>
        );
    } else {
        return null;
    }
};
