import {Box} from "@mui/material";
import {HeroCarousel} from "./HeroCarousel";
import React from "react";
import {useAdminHeroProps} from "../admin/adminHeroContext";
import {Header} from "../header/Header";
import {Hero} from "./Hero";
import {HeroImage} from "./HeroImage";
import {Hero3dModel} from "./Hero3dModel";
import {useFadeHero} from "./useFadeHero";
import {HIGHLIGHTED_EXHIBITIONS_RECEIVED, useHeroDispatch, useHeroState} from "./heroContext";
import useDeepCompareEffect from "use-deep-compare-effect";
import {EditHeroContent} from "./EditHeroContent";

export const EditHero = () => {
    const {
        data,
        mobileView
    } = useAdminHeroProps()
    console.debug({data})

    const {
        currentIndex,
        duration,
        exhibitions
    } = useHeroState()
    const heroDispatch = useHeroDispatch()

    useDeepCompareEffect(
        () => {
            heroDispatch({
                type: HIGHLIGHTED_EXHIBITIONS_RECEIVED,
                exhibitions: [
                    {
                        exhibition: data,
                        order: 1
                    }
                ]
            })
        }, [data]
    )

    const transitions = useFadeHero({
        currentIndex: currentIndex,
        animationDuration: 800
    })

    if(exhibitions.length === 0) {
        return null
    }

    return (
        <Box
            sx={{
                width: "100%",
                height: "100%"
            }}
        >
            <Header>
            </Header>
            <HeroCarousel
                hideNavigationButtons={mobileView}
            >
                {transitions((style, item) => (
                    <Hero
                        key={item}
                        style={style}
                    >
                        <HeroImage
                            exhibition={exhibitions[item]?.exhibition}
                            duration={duration}
                        />
                        <EditHeroContent
                            exhibitionName={exhibitions[item]?.exhibition?.heroTitle || exhibitions[item]?.exhibition?.name}
                            museumId={exhibitions[item]?.exhibition?.museumId}
                            exhibitionId={exhibitions[item]?.exhibition?.uniqueId}
                            blackFont={exhibitions[item]?.exhibition?.blackFont}
                        />
                        <Hero3dModel
                            model3d={exhibitions[item]?.exhibition?.model3d}
                        />
                    </Hero>
                ))}
            </HeroCarousel>
        </Box>
    )
}