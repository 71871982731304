import {createContext, useContext, useReducer} from "react";
import {Theme} from "../theme/Theme";

const ThemeStateContext = createContext(undefined);
const ThemeDispatchContext = createContext(undefined);

export const FORCE_MOBILE_ENABLED = 'forceMobilEnabled'
export const FORCE_MOBILE_DISABLED = 'forceMobileDisabled'

const initialState = {
    forceMobile: false
};

const appReducer = (state, action) => {
    console.debug({action})
    switch (action.type) {
        case FORCE_MOBILE_ENABLED:
            return {
                ...state,
                forceMobile: true
        }
        case FORCE_MOBILE_DISABLED:
            return {
                ...state,
                forceMobile: false
            }
        default:
            throw new Error(`Unhandled action type ${action.type}`);
    }
};

export const ThemeProvider = ({ children }) => {
    const [state, dispatch] = useReducer(
        appReducer,
        { ...initialState },
        undefined
    );

    return (
        <ThemeStateContext.Provider value={state}>
            <ThemeDispatchContext.Provider value={dispatch}>
                <Theme>
                    {children}
                </Theme>
            </ThemeDispatchContext.Provider>
        </ThemeStateContext.Provider>
    );
};

export const useThemeState = () => {
    const context = useContext(ThemeStateContext);
    if (undefined === context) {
        throw new Error(`useThemeState must be used witin an ThemeProvider`);
    } else {
        return context;
    }
};

export const useThemeDispatch = () => {
    const context = useContext(ThemeDispatchContext);
    if (undefined === context) {
        throw new Error(`useThemeDispatch must be used within an ThemeProvider`);
    } else {
        return context;
    }
};
