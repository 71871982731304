import {Nav} from "./Nav";
import {NavLeftSide} from "./NavLeftSide";
import {NavRightSide} from "./NavRightSide";
import {Logo} from "../logo/Logo";
import {Breadcrumbs} from "../breadcrumbs/Breadcrumbs";
import {colors, theme} from "../globalJss";
import {Button} from "../button/Button";
import {Menu as MenuIcon, User, X} from "react-feather";
import {useEffect, useState} from "react";
import {useContrastApproved} from "../contrast/useContrast";
import {createUseStyles} from "react-jss";
import {MENU_CLOSED, MENU_OPENED, useAppDispatch, useAppState, useAppTranslation} from "../app/appContext";

const useStyles = createUseStyles({
    loginButton: {
        marginRight: 16,
    }
})

export const PortalNav = ({
    color = theme.colors.satinLinen,
    includeBreadcrumb=false,
    rootsOnly=false,
    match

}) => {
    const {menuOpen} = useAppState()
    const dispatch = useAppDispatch()
    const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 420px)").matches)
    const t = useAppTranslation();
    const contrastApproved = useContrastApproved(color, theme.colors.black);
    const classes = useStyles()

    useEffect(() => {
        const onResize = () =>
            setIsMobile(window.matchMedia("(max-width: 420px)").matches);
        window.addEventListener("resize", () => onResize());
        return () =>
            window.removeEventListener("resize", () => () => onResize());
    })

    const toggleMenu = () => {
        if(menuOpen) {
            dispatch({type: MENU_CLOSED})
        } else {
            dispatch({type: MENU_OPENED})
        }
    }

    return (
        <Nav>
            <NavLeftSide>
                <a href="/">
                    <Logo
                        mode={
                            menuOpen
                                ? "dark"
                                : contrastApproved
                                    ? "light"
                                    : "dark"
                        }
                        size="large"
                        showLabel={false}
                    />
                </a>
                {includeBreadcrumb && (
                    <Breadcrumbs
                        match={match}
                        rootOnly={rootsOnly}
                        color={
                            contrastApproved
                                ? theme.colors.black
                                : theme.colors.white
                        }
                    />
                )}
            </NavLeftSide>
            <NavRightSide>
                {!isMobile && (
                    <Button
                        label={t("headerButtonLogin", "Bli Med")}
                        type="normal"
                        Icon={<User />}
                        color={colors.athensLightGrey}
                        className={classes.loginButton}
                    />
                )}
                <Button
                    label={t("headerButtonMenu", "Meny")}
                    type="normal"
                    Icon={menuOpen ? <X /> : <MenuIcon />}
                    color={colors.athensLightGrey}
                    onClick={toggleMenu}
                />
            </NavRightSide>
        </Nav>
    )
}