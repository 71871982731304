import {Header} from "../header/Header";
import {ExhibitionPageContent} from "./ExhibitionPageContent";
import {ExhibitionPageHeader} from "./ExhibitionPageHeader";
import {MuseumHeader} from "./MuseumHeader";
import {ExhibitionHeader} from "./ExhibitionHeader";
import {ExhibitionInfo} from "./ExhibitionInfo";
import {ExhibitionName} from "./ExhibitionName";
import {EditExhibitionDescription} from "./EditExhibitionDescription";
import {AdminNav} from "../header/AdminNav";
import {EditExperiences} from "./EditExperiences";
import {ExhibitionInfoCard} from "./ExhibitionInfoCard";
import {EditExhibitionInfoText} from "./EditExhibitionInfoText";
import {createUseStyles} from "react-jss";
import {theme} from "../globalJss";
import {AddExperiences} from "./AddExperiences";
import {useAdminProps, useAdminState} from "../admin/adminContext";
import {Box, Stack} from "@mui/material";
import {LayoutSettings} from "../experience/LayoutSettings";
import {ExhibitionDescription} from "./ExhibitionDescription";
import {ExhibitionInfoText} from "./ExhibitionInfoText";
import React, {useEffect} from "react";
import {FORCE_MOBILE_DISABLED, FORCE_MOBILE_ENABLED, useThemeDispatch} from "../theme/themeContext";
import {ActiveExperienceContent} from "../experience/ActiveExperienceContent";
import {useRunExperience} from "../experience/useRunExperience";

const useStyles = createUseStyles(({ colors }) => ({
    page: {
        backgroundColor: (props) => props?.color || colors.satinLinen,
    },
    alternateBackground: {
        backgroundColor: `${colors.black}0A`,
    },
}))

export const EditExhibition = () => {
    const {
        editingLayout
    } = useAdminState()
    const {
        setActiveExperienceId,
        activeExperienceId
    } = useRunExperience()
    const {
        data,
        mobileView,
        adminMode,
        errors,
        museum
    } = useAdminProps()

    const appDispatch = useThemeDispatch()

    useEffect(
        () => {
            if(mobileView) {
                appDispatch({type: FORCE_MOBILE_ENABLED})
            } else {
                appDispatch({type: FORCE_MOBILE_DISABLED})
            }
        }, [appDispatch, mobileView]
    )

    const classes = useStyles({
        color: data?.color || theme.colors.satinLinen
    })

    if(!data) {
        return null
    }

    console.debug({data})
    const drawerWidth = editingLayout ? 300 : 0


    const description = mobileView || !adminMode
        ? <ExhibitionDescription description={data?.ingress} />
        : <EditExhibitionDescription
            description={data?.ingress}
            error={errors.ingress}
        />

    const infoText = () => {
        if (mobileView) {
            return (
                <ExhibitionInfoText moreText={data?.moreText} forceMobileView={true}/>
            )
        } else {
            if (adminMode) {
                return (
                    <EditExhibitionInfoText
                        moreText={data?.moreText}
                        error={errors.moreText}
                    />
                )
            } else {
                return (
                    <ExhibitionInfoText moreText={data?.moreText}/>
                )
            }
        }
    }

    const handleStartExperience = experience => {
        if(experience && !adminMode) {
            setActiveExperienceId(experience.contentId)
        } else {
            setActiveExperienceId(null)
        }
    }

    if(activeExperienceId) {
        return (
            <ActiveExperienceContent
                exhibition={adminMode ? null : data}
                activeExperienceId={activeExperienceId}
                onClose={() => setActiveExperienceId(null)}
            />
        )
    } else {
        return (
            <Stack
                direction={"row"}
            >
                <Box
                    sx={{
                        overflowX: "hidden",
                        overflowY: "hidden",
                        height: "100%",
                        width: `calc(100% - ${drawerWidth}px)`,
                        position: "relative"
                    }}
                >
                    <Header>
                        <AdminNav />
                    </Header>
                    <div className={classes.page}>
                        <ExhibitionPageHeader
                            exhibitionColor={data?.color}
                            background={data?.background}
                            preview={data?.preview}
                            exhibition={data}
                        >
                            <MuseumHeader
                                museumId={data?.museumId}
                                museumOverride={museum}
                            />
                            <ExhibitionHeader
                                exhibition={data}
                            >
                                <ExhibitionInfo>
                                    <ExhibitionName name={data?.name} />
                                    {description}
                                </ExhibitionInfo>
                                <EditExperiences
                                    layout={data?.layout}
                                    exhibitionContents={data?.exhibitionContents}
                                    startExhibition={handleStartExperience}
                                >
                                </EditExperiences>
                                <AddExperiences
                                    hasExperiences={data.exhibitionContents && 0 < data.exhibitionContents.length}
                                />
                            </ExhibitionHeader>
                        </ExhibitionPageHeader>
                        <div className={classes.alternateBackground}>
                            <ExhibitionPageContent
                                color={data?.color}
                                forceMobileView={mobileView}
                            >
                                <ExhibitionInfoCard
                                    preview={data?.preview}
                                    forceMobileView={mobileView}
                                />
                                {infoText()}
                            </ExhibitionPageContent>
                        </div>
                        <ActiveExperienceContent
                            exhibition={adminMode ? null : data}
                            activeExperienceId={activeExperienceId}
                            onClose={() => setActiveExperienceId(null)}
                        />
                    </div>
                </Box>
                <LayoutSettings
                    drawerWidth={drawerWidth}
                />
            </Stack>
        )
    }

}