import React from "react";
import {createUseStyles} from "react-jss";
import {With360} from "./With360";
import {Threesixty} from '@ekultur/360-microfrontend'

const useStyles = createUseStyles({
    roomContainer: {
        display: "flex",
        height: "100vh",
    },
    container: {
        width: "100%",
    },
});

export const ExhibitionThreeSixty = ({ content, onClose }) => {
    const classes = useStyles();
    return (
        <div className={classes.roomContainer}>
            <With360 classname={classes.container}>
                <Threesixty
                    data={content}
                    onClose={onClose}
                />
            </With360>
        </div>
    );
};
