import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { Search, X } from "react-feather";

const useStyles = createUseStyles((theme) => ({
    searchField: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "2px solid #0C0B0F",
        borderRadius: 16,
        padding: "26px",
        "&:focus-within": { boxShadow: "0 0 3px 2px blue" },
    },
    searchIcon: {
        marginRight: 11,
        cursor: "pointer",
    },
    inputField: {
        fontSize: theme.fontSize.lg,
        backgroundColor: "transparent",
        border: "none",
        "&:focus": { outline: "none" },
        marginRight: (props) => (props.addMargin ? "24px" : 0),
    },
    closeIcon: {
        cursor: "pointer",
    },
}));

export const SearchField = ({ onSearch, onClear = () => {} }) => {
    const [query, setQuery] = useState("");
    const classes = useStyles({ addMargin: query.length === 0 });
    return (
        <div className={classes.searchField}>
            <Search
                className={classes.searchIcon}
                onClick={() => onSearch(query)}
            />
            <input
                value={query}
                className={classes.inputField}
                type="text"
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        return onSearch(query);
                    }
                }}
            />
            {query.length > 0 && (
                <X
                    className={classes.closeIcon}
                    onClick={() => {
                        setQuery("");
                        onClear();
                    }}
                />
            )}
        </div>
    );
};
