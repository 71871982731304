import {createUseStyles} from "react-jss";
import {SizedExperienceLayoutRow} from "./SizedExperienceLayoutRow";

const useStyles = createUseStyles(({breakpoints}) => ({
    border: {
        border:"1.5708px dashed #9E9E9E",
        '&:hover': {
            borderColor: "#3F51B5",
        }
    },
    noBorder: {

    }
}))

export const ExperienceLayoutRow = ({size, AdminMenuComponent=null, border=false, children}) => {
    const classes = useStyles()
    const borderClass = border ? classes.border : classes.noBorder


    if(!children || 0 === children.length) {
        return null
    } else {
        return (
            <div className={borderClass}>
                <SizedExperienceLayoutRow
                    size={size}
                >
                    {AdminMenuComponent ? AdminMenuComponent : null}
                    {children}
                </SizedExperienceLayoutRow>
            </div>
        )
    }
}