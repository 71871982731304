import {useTransition} from "@react-spring/web";

export const useFadeHero = ({currentIndex, animationDuration=800}) => {
    return useTransition(currentIndex, {
        key: currentIndex,
        initial: {
            opacity: 0,
        },
        from: {
            opacity: 0
        },
        enter: {
            opacity: 1,
        },
        leave: {
            opacity: 0
        },
        config: {
            duration: animationDuration ,
            tension: 220,
            friction: 120
        },
        trail: animationDuration + 150,
    })
}