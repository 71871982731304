import {Box, IconButton, Menu, MenuItem} from "@mui/material";
import {MoreVert} from "@mui/icons-material";
import {useState} from "react";
import {useAppTranslation} from "../app/appContext";
import {useAdminProps} from "../admin/adminContext";

export const EditExperienceMenu = ({contentId}) => {
    const t = useAppTranslation()
    const [anchorEl, setAnchorEl] = useState(null)
    const {onExperienceRemove} = useAdminProps()

    const handleClick = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null)
    const handleChangeImage = () => {
        handleClose()
    }

    const handleRemove = () => {
        onExperienceRemove({
            contentId: contentId
        })
        handleClose()
    }


    const open = Boolean(anchorEl)

    return (
        <Box>
            <IconButton
                size={"small"}
                color={"primary"}
                aria-label={"experience actions"}
                id={'experience-menu-button'}
                aria-haspopup={"true"}
                aria-expanded={open ? 'true' : 'false'}
                aria-controls={open ? 'experience-menu-list' : undefined}
                onClick={handleClick}
            >
                <MoreVert />
            </IconButton>
            <Menu
                id={"experience-button"}
                MenuListProps={{
                    'aria-labelledby': 'experience-menu-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={handleChangeImage}
                >
                    {t('changeImageMenuItem', "Endre bilde")}
                </MenuItem>
                <MenuItem
                    onClick={handleRemove}
                >
                    {t('removeExperienceMenuItem', "Fjern opplevelsen")}
                </MenuItem>
            </Menu>
        </Box>
    )
}