import React from "react";
import {createUseStyles} from "react-jss";

const useStyles = createUseStyles(({ colors}) => ({
    image: {
        position: "absolute",
        display: "flex",
        backgroundImage: ({ background }) => `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionX: ({ backgroundPositionX }) =>
            `${backgroundPositionX}%` || "50%",
        backgroundPositionY: ({ backgroundPositionY }) =>
            `${backgroundPositionY}%` || "50%",
        width: "100%",
        height: "100%",
        "&.animate": {
            willChange: "transform",
            animationName: "$kenBurns",
            animationDuration: ({duration}) => duration + 1000,
        },
        "@media (max-width: 600px)": {
            backgroundImage: ({ background }) => `url(${background}?dimension=600x600)`,
        },
        "@media (min-width: 600px) and (max-width: 1199px)": {
            backgroundImage: ({ background }) => `url(${background}?dimension=800x800)`,
        },
        "@media (min-width: 1200px) and (max-width: 1900px)": {
            backgroundImage: ({ background }) => `url(${background}?dimension=1200x1200)`,
        },
        "@media (min-width: 1901px)": {
            backgroundImage: ({ background }) => `url(${background}?dimension=max)`,
        },
        "@media (max-width: 375px)": {
            "&.animate": {
                transform: "none",
            },
        },
    },
    imageOverlay: {
        position: "absolute",
        display: "flex",
        width: "100%",
        height: "100%",
        zIndex: 99,
        backgroundColor: ({ blackFont }) =>
            `${blackFont ? colors.white : colors.black}99`,
    },
    "@keyframes kenBurns": {
        "0%": {
            transformOrigin: "bottom left",
            transform: "scale(1.0)",
        },
        "100%": {
            transform: "scale(1.2)",
        },
    },
}))

const heroBackground = exhibition => {
    if(!exhibition) {
        return ""
    }
    if(exhibition.hero) {
        return exhibition.hero
    } else if(exhibition.background) {
        return exhibition.background
    } else {
        return exhibition.preview
    }
}

const backgroundPosition = mediaRef => {
    const x = mediaRef.focusX || 0
    const y = mediaRef.focusY || 0
    const backgroundPositionX = ((x + 1) * 100) / 2;
    const backgroundPositionY = ((-y + 1) * 100) / 2;
    return { backgroundPositionX, backgroundPositionY };
}

export const HeroImage = ({exhibition, duration=8000}) => {
    console.debug({exhibition})
    const background = heroBackground(exhibition);
    const classes = useStyles({
        duration,
        background: exhibition ? background.url : "",
        blackFont: exhibition?.blackFont,
        ...backgroundPosition(background)

    })

    return (
        <div>
            <div
                className={`${classes.image} ${
                    exhibition?.model3d === undefined ? "animate" : ""
                }`}
            />
            <div className={classes.imageOverlay} />
        </div>
    )
}