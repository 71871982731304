import {BrowserRouter as Router} from "react-router-dom";
import {AppProvider} from "./appContext";
import React from "react";
import {ThemeProvider} from "../theme/themeContext";
import {Theme} from "../theme/Theme";

export const Microfrontend = ({fetchEkulturData=true, children}) => {
    return (
        <Router>
            <ThemeProvider>
                <Theme>
                    <AppProvider
                        fetchEkulturData={fetchEkulturData}
                    >
                        {children}
                    </AppProvider>
                </Theme>
            </ThemeProvider>
        </Router>
    );
};
