import React from "react";
import { createUseStyles } from "react-jss";
import { Instagram, Facebook, Mail } from "react-feather";
import { Logo } from "../logo/Logo";
import { useAppTranslation } from "../app/appContext";
import { Typography } from "../typography/Typography";
import { useContrastApproved } from "../contrast/useContrast";
import { theme } from "../globalJss";

const useStyles = createUseStyles(({ colors }) => ({
    footer: {
        background: `${colors.black}12`,
        color: ({ isContrastApproved }) =>
            isContrastApproved ? colors.black : colors.white,
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    content: {
        opacity: 0.7,
        margin: "0 auto",
        width: "1200px",
        display: "flex",
        flexDirection: "column",
        "@media (max-width: 1264px)": {
            margin: "0 48px",
            width: "initial",
        },
        "@media (max-width: 1024px)": {
            margin: "0 32px",
        },
    },
    columns: {
        display: "flex",
        justifyContent: "space-between",
        padding: "90px 0",
        "@media (max-width: 375px)": {
            flexDirection: "column",
        },
    },
    leftColumns: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: 355,
        "@media (max-width: 1024px)": {
            width: 241,
            marginRight: 22,
        },
        "@media (max-width: 375px)": {
            width: "100%",
            margin: 0,
            alignItems: "flex-start",
            marginBottom: 40,
        },
    },
    logo: {
        maxWidth: "150px",
        height: "initial !important",
    },
    rightColumns: {
        display: "flex",
        flexWrap: "wrap",
        "& > div": {
            flexBasis: "25%",
            "&:last-child": { flexGrow: 2 },
            "@media (max-width: 1024px)": {
                flexBasis: "50%",
            },
            "@media (max-width: 375px)": {
                flexBasis: "50%",
            },
        },
        justifyContent: "space-between",
        width: "620px",
        "@media (max-width: 375px)": {
            width: "auto",
        },
        "& h3": {
            letterSpacing: ".12em",
            margin: "0 0 20px 0",
        },
    },
    about: {
        margin: "25px 0 50px 0",
        "@media (max-width: 375px)": { margin: "25px 0" },
    },
    soMe: {
        display: "flex",
        justifyContent: "space-between",
        "& svg": {
            color: ({ isContrastApproved }) =>
                isContrastApproved ? colors.black : colors.white,
        },
    },
    listWrapper: {
        display: "flex",
        // justifyContent: "space-between",
    },
    list: {
        listStyle: "none",
        padding: 0,
        "& li": {
            margin: "22px 0",
            "&:first-child": { margin: "0 0 22px 0" },

            "& a": {
                color: ({ isContrastApproved }) =>
                    isContrastApproved ? colors.black : colors.white,
                textDecoration: "none",
                "&:hover": {
                    textDecoration: "underline",
                },
            },
        },
    },
    doubleList: {
        listStyle: "none",
        padding: 0,
        margin: 0,
        flexBasis: "50%",
        "& li": {
            margin: "22px 0",
            "&:first-child": { margin: "0 0 22px 0" },
            "& a": {
                color: ({ isContrastApproved }) =>
                    isContrastApproved ? colors.black : colors.white,
                textDecoration: "none",
                "&:hover": {
                    textDecoration: "underline",
                },
            },
        },
    },
    copyright: {
        borderTop: `1px solid ${colors.black}`,
        padding: "24px 0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

export const PageFooter = ({ color }) => {
    const t = useAppTranslation();
    const isContrastApproved = useContrastApproved(
        color || theme.colors.satinLinen,
        theme.colors.black
    );
    const classes = useStyles({ isContrastApproved });

    return (
        <div className={classes.footer}>
            <div className={classes.content}>
                <div className={classes.columns}>
                    <div className={classes.leftColumns}>
                        <div>
                            <Logo
                                mode={isContrastApproved ? "black" : "white"}
                                showLabel
                                className={classes.logo}
                            />
                            <Typography
                                tag="p"
                                size="sm"
                                className={classes.about}
                            >
                                {t(
                                    "footerDescription",
                                    "Vi publiserer nye digitale opplevelser kuratert og presentert av alle museene i Norge."
                                )}
                            </Typography>
                        </div>
                        <div className={classes.soMe}>
                            <a href="https://www.instagram.com/">
                                <Instagram />
                            </a>
                            <a href="https://www.facebook.com/">
                                <Facebook />
                            </a>
                            <a href="mailto:mail@virtueltmuseum.net">
                                <Mail />
                            </a>
                        </div>
                    </div>
                    <div className={classes.rightColumns}>
                        <div>
                            <Typography tag="h3" size="sm">
                                INFO
                            </Typography>
                            <ul className={classes.list}>
                                <li>
                                    <Typography
                                        tag="a"
                                        size="base"
                                        href="/privacy"
                                    >
                                        {t("footerPrivacyLink", "Personvern")}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        tag="a"
                                        size="base"
                                        href="/copyright"
                                    >
                                        {t(
                                            "footerCopyrightLink",
                                            "Opphavsrett"
                                        )}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography
                                        tag="a"
                                        size="base"
                                        href="/about"
                                    >
                                        {t("footerAboutLink", "Om oss")}
                                    </Typography>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <Typography tag="h3" size="sm">
                                FORMATER
                            </Typography>
                            <ul className={classes.list}>
                                <li>
                                    <Typography tag="a" size="base" href="/">
                                        360-omvisning
                                    </Typography>
                                </li>
                                <li>
                                    <Typography tag="a" size="base" href="/">
                                        3D-objekter
                                    </Typography>
                                </li>
                                <li>
                                    <Typography tag="a" size="base" href="/">
                                        Rike artikler
                                    </Typography>
                                </li>
                                <li>
                                    <Typography tag="a" size="base" href="/">
                                        VR-opplevelser
                                    </Typography>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <Typography tag="h3" size="sm">
                                KATEGORIER
                            </Typography>
                            <div className={classes.listWrapper}>
                                <ul className={classes.doubleList}>
                                    <li>
                                        <Typography
                                            tag="a"
                                            size="base"
                                            href="/"
                                        >
                                            Kulturhistorie
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            tag="a"
                                            size="base"
                                            href="/"
                                        >
                                            Naturhistorie
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            tag="a"
                                            size="base"
                                            href="/"
                                        >
                                            Friluft
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            tag="a"
                                            size="base"
                                            href="/"
                                        >
                                            Kunst
                                        </Typography>
                                    </li>
                                </ul>
                                <ul className={classes.doubleList}>
                                    <li>
                                        <Typography
                                            tag="a"
                                            size="base"
                                            href="/"
                                        >
                                            Arkeologi
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            tag="a"
                                            size="base"
                                            href="/"
                                        >
                                            Nasjonalparker
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            tag="a"
                                            size="base"
                                            href="/"
                                        >
                                            Teknikk og viten
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography
                                            tag="a"
                                            size="base"
                                            href="/"
                                        >
                                            Håndverk
                                        </Typography>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <Typography tag="div" size="base" className={classes.copyright}>
                    {t(
                        "footerCopyright",
                        "Mange bilder, motiver og gjenstander er beskyttet av copyright. Disse kan ikke publiseres videre uten tillatelse fra eieren."
                    )}
                </Typography>
            </div>
        </div>
    );
};
