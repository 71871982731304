import {useMatch} from "react-router";
import {Header} from "../header/Header";
import {useAppState, useAppTranslation} from "../app/appContext";
import {Suspense} from "react";
import {LoadingLogo} from "../loading/LoadingLogo";
import {createUseStyles} from "react-jss";
import {Typography} from "../typography/Typography";
import {PortalNav} from "../header/PortalNav";

const useStyles = createUseStyles((theme) => ({
    museumsPage: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "163px",
        maxWidth: "1024px",
        margin: "0 auto",
        "@media (max-width: 1040px)": {
            maxWidth: "751px",
        },
        "@media (max-width: 770px)": {
            maxWidth: "480px",
        },
        "@media (max-width: 500px)": {
            maxWidth: "240px",
        },
    },
    museums: { display: "flex", flexWrap: "wrap" },
    museum: {
        width: "207px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "32.5px",
        textAlign: "center",
        textDecoration: "none",
        "@media (min-width: 1040px)": {
            "&:nth-child(4n - 3)": {
                marginLeft: "0",
            },
            "&:nth-child(4n)": {
                marginRight: "0",
            },
        },
        "@media (max-width: 1040px) and (min-width: 770px)": {
            "&:nth-child(3n - 2)": {
                marginLeft: "0",
            },
            "&:nth-child(3n)": {
                marginRight: "0",
            },
        },
        "@media (max-width: 770px) and (min-width: 500px)": {
            "&:nth-child(2n - 1)": {
                marginLeft: "0",
            },
            "&:nth-child(2n)": {
                marginRight: "0",
            },
        },
        "&:hover $museumName": {
            textDecoration: "underline",
        },
    },
    header: {
        textAlign: "center",
    },
    description: {
        textAlign: "center",
    },
    logo: {
        maxWidth: "100%",
        maxHeight: "100%",
        borderRadius: 999
    },
    logoCircle: {
        height: "207px",
        width: "207px",
        borderRadius: "999px",
        backgroundColor: theme.colors.coolGray300,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "12.5px",
    },
    initials: {
        color: theme.colors.black,
        textDecoration: "none",
    },
    museumName: {
        color: theme.colors.black,
    },
}));

export const MuseumsPage = () => {
    const classes = useStyles();
    const t = useAppTranslation();
    const { museums } = useAppState();
    const match = useMatch({
        path: "/museums",
        exact: true,
    });

    if (match) {
        return (
            <Suspense fallback={<LoadingLogo />}>
                <Header>
                    <PortalNav
                        logoMode="black"
                        includeBreadcrumb
                        match={match}
                        rootsOnly
                    />
                </Header>
                <div className={classes.museumsPage}>
                    <Typography
                        tag="h1"
                        size="xl5"
                        bold
                        className={classes.header}
                    >
                        {t(
                            "museumPageHeader",
                            "Her er museene du kan besøke i Virtuelt Museum"
                        )}
                    </Typography>
                    <Typography
                        tag="p"
                        size="lg"
                        type="mono"
                        className={classes.description}
                    >
                        {t(
                            "museumPageDescription",
                            "Stadig flere museer legger til virtuelle ustillinger, historier og gjenstander"
                        )}
                    </Typography>
                    <div className={classes.museums}>
                        {museums
                            .sort((a, b) => ("" + a.name).localeCompare(b.name))
                            .map((museum) => (
                                <a
                                    key={`museum-${museum.id}`}
                                    className={classes.museum}
                                    href={`/museum/${museum.id}`}
                                >
                                    <div className={classes.logoCircle}>
                                        {museum.profile && museum.profile !== "" ? (
                                            <img
                                                className={classes.logo}
                                                src={`https://${museum.profile}`}
                                                alt={`${museum.name} logo`}
                                            />
                                        ) : (
                                            <Typography
                                                tag="span"
                                                size="xl7"
                                                className={classes.initials}
                                            >
                                                {museum?.name &&
                                                    museum.name
                                                        .toUpperCase()
                                                        .split(" ")
                                                        .slice(-2)
                                                        .map((n) => n[0])
                                                        .join("")}
                                            </Typography>
                                        )}
                                    </div>
                                    <Typography
                                        tag="span"
                                        size="sm"
                                        type="mono"
                                        className={classes.museumName}
                                    >
                                        {museum.name}
                                    </Typography>
                                </a>
                            ))}
                    </div>
                </div>
            </Suspense>
        );
    } else {
        return null;
    }
};
