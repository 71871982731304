import { useEffect, useState } from "react";
import colorContrast from "color-contrast";

export const useContrastApproved = (color1, color2, ratio = 4.5) => {
    const [isApproved, setIsAproved] = useState(false);

    useEffect(() => {
        setIsAproved(colorContrast(color1, color2) >= ratio);
    }, [color1, color2, ratio, setIsAproved, colorContrast]);

    return isApproved;
};
