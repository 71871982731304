import {createUseStyles} from "react-jss";
import {animated, useSpring} from "@react-spring/web";

const useStyles = createUseStyles(() => ({
    exhibitionCard: {
        width: "100%",
        minHeight: 384,
        display: "grid",
        gridAutoRows: "1fr",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "2fr 1fr"
    },
}))
export const SmallExperienceCard = ({readMode, children}) => {
    const classes = useStyles()

    const toRows = readMode ? "0fr 1fr" : "2fr 1fr"
    const fromRows = readMode ? "2fr 1fr": "0fr 1fr"

    const props = useSpring({
        to: { gridTemplateRows: toRows },
        from: { gridTemplateRows: fromRows }
    })

    return (
        <animated.div
            className={`${classes.exhibitionCard}`}
            style={props}
        >
            {children}
        </animated.div>
    )
}