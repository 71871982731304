import React from "react";
import { createUseStyles } from "react-jss";
import { useAppState, useAppTranslation } from "../app/appContext";
import { Crumb } from "./Crumb";
import { routes } from "./routesConfig";
import { useExhibitions } from "../exhibition/useExhibitions";

const useStyles = createUseStyles({
    breadcrumbs: {
        display: "flex",
        margin: "0 8.5px",
        "@media (max-width: 600px)": {
            display: "none",
        },
    },
    separator: {},
    crumb: {
        color: ({ color }) => color,
    },
});

export const Breadcrumbs = ({ hideRoot, rootOnly, color, match }) => {
    const t = useAppTranslation();
    const { museums } = useAppState();
    const exhibitions = useExhibitions();
    const classes = useStyles({ color });

    const paths = routes
        .filter(({ path }) => match.pattern.path.includes(path))
        .map(({ path, ...rest }) => ({
            originalPath: path,
            path: Object.keys(match.params).length
                ? Object.keys(match.params).reduce(
                      (path, param) =>
                          path.replace(`:${param}`, match.params[param]),
                      path
                  )
                : path,
            ...rest,
        }));
    const crumbs = hideRoot ? paths.filter((p, i) => i !== 0) : paths;
    const getRouteTranslation = (crumb) => {
        const originalParts = crumb.originalPath.split("/");
        const urlParts = crumb.path.split("/");

        if (originalParts[originalParts.length - 1] === ":museumId") {
            return (
                museums.find((m) => {
                    return m.id === Number(urlParts[urlParts.length - 1]);
                })?.name || crumb.translation
            );
        } else if (
            originalParts[originalParts.length - 1] === ":exhibitionId"
        ) {
            return (
                exhibitions.find(
                    (e) => e.uniqueId === urlParts[urlParts.length - 1]
                )?.name || crumb.translation
            );
        } else {
            return t(crumb.translation, crumb.defaultTranslation);
        }
    };
    return (
        <div className={classes.breadcrumbs}>
            {rootOnly ? (
                <Crumb
                    className={classes.crumb}
                    path={crumbs[0].path}
                    flipArrow
                    color={color}
                >
                    {t(crumbs[0].translation, crumbs[0].defaultTranslation)}
                </Crumb>
            ) : (
                crumbs.map((c, i) => (
                    <Crumb
                        key={i}
                        className={classes.crumb}
                        isFirst={i === 0}
                        path={c.path}
                        isCurrent={i === paths.length - 1}
                    >
                        {getRouteTranslation(c)}
                    </Crumb>
                ))
            )}
        </div>
    );
};
