import {Typography} from "../typography/Typography";
import {createUseStyles} from "react-jss";
import {useBreakpoints} from "../layout/useBreakpoints";
import {useMediaQuery} from "../layout/useMediaQuery";
import {down} from "../layout/breakpoints";

const useStyles = createUseStyles(({
    exhibitionName: {
        textAlign: "center",
        margin: "16px 0 9px 0",
    }
}));

export const ExhibitionName = ({name}) => {
    const classes = useStyles()
    const breakpoints = useBreakpoints()
    const matchSmallDown = useMediaQuery(down(breakpoints.small))

    return (
        <Typography
            tag="h1"
            size={matchSmallDown ? "xl5" : "xl6"}
            bold
            className={classes.exhibitionName}
        >
            {name}
        </Typography>
    )
}