import React, {useCallback} from "react";
import {FocusPicker} from "image-focus";
import {Box, debounce} from "@mui/material";
import {getDmsUrl} from "@ekultur/dms-url-generator";

export const FocusImgPicker = (
    {
        debounceTime = 200,
        onFocusChanged,
        mediaRef
    }
) => {
    const imageRef = useCallback(node => {
        if(node !== null) {
            const debouncedOnFocusChanged = debounce(onFocusChanged, debounceTime);
            new FocusPicker(node, {
                onChange: debouncedOnFocusChanged,
                focus: {
                    x: mediaRef?.focusX || 0,
                    y: mediaRef?.focusY || 0
                }
            })
        }
    }, [mediaRef?.focusX, mediaRef?.focusY])

    if(mediaRef) {
        return (
            <Box
                sx={{width: 200, height: 200}}
            >
                <img alt="Focus picker" ref={imageRef} src={getDmsUrl(mediaRef.dmsId)} width={200} height={200}/>
            </Box>
        )
    } else {
        return null
    }
}