import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";

export const LayoutSettingsAccordion = ({id, summaryLabel, children}) => {
    
    return (
        <Accordion
            square={true}
            variant={"outlined"}
            disableGutters={true}
        >
            <AccordionSummary
                expandIcon={<ExpandMore />}
                id={`${id}-header`}
                aria-controls={`${id}-content`}
            >
                <Typography
                    variant={"subtitle1"}
                >
                    {summaryLabel}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}