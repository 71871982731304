import React from "react";
import { createUseStyles } from "react-jss";
import { LoadingLogo } from "./LoadingLogo";
import { useAppTranslation } from "../app/appContext";

const useStyles = createUseStyles((theme) => ({
    loadingPage: {
        display: "flex",
        height: "100vh",
        backgroundColor: theme.colors.satinLinen,
        justifyContent: "center",
        alignItems: "center",
    },
    loader: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    logo: {
        margin: "0 0 14px 0",
    },
    loadingText: {
        textAlign: "center",
        color: theme.colors.vmGray500,
    },
}));

export const PageLoader = () => {
    const classes = useStyles();
    const t = useAppTranslation();
    return (
        <div className={classes.loadingPage}>
            <div className={classes.loader}>
                <LoadingLogo className={classes.logo} />
                <p className={classes.loadingText}>
                    {t("loadingPage", "Laster Virtuelt Museum")}
                </p>
            </div>
        </div>
    );
};
