import {Typography} from "../typography/Typography";
import React from "react";
import {createUseStyles} from "react-jss";
import {useAppTranslation} from "../app/appContext";

const useStyles = createUseStyles(({
    textSide: {
        marginLeft: "48px",
        "& h3": {
            fontSize: 36,
            lineHeight: "40px",
            fontWeight: "bold",
        },
        "@media (max-width: 1024px)": {
            margin: "24px 0 0 0",
        },
    },
    forceMobileView: {
        margin: "24px 0 0 0",
    }
}))

export const ExhibitionInfoText = ({moreText, forceMobileView}) => {
    const classes = useStyles()
    const t = useAppTranslation()
    return (
        <div
            className={`${classes.textSide} ${forceMobileView ? classes.forceMobileView : ""}`}
        >
            <Typography tag="h2" size="xl4" bold>
                {t("exhibitionMoreTitle", "Mer om utstillingen")}
            </Typography>
            <Typography tag="p" size="lg">
                {moreText}
            </Typography>
        </div>
    )
}