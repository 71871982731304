import {
    ScrollytellingProvider,
    WithScrollytellingMicroFrontend,
    Scrollytelling,
} from "@ekultur/scrollytelling-microfrontend";
import { createUseStyles } from "react-jss";
import { useEffect } from "react";

const useStyles = createUseStyles({
    root: {
        display: "flex",
        height: "100vh",
        overflowY: "auto",
    },
});

export const ExhibitionScrollytelling = ({
    exhibition,
    museum,
    content,
    onClose,
}) => {
    const classes = useStyles();
    const data = {
        ...content,
        appBar: {
            ...content?.appBar,
            exhibition: {
                name: exhibition?.name,
                description: exhibition?.description,
                url: `/museum/${exhibition?.museumId}/exhibition/${exhibition?.uniqueId}`,
            },
            museum: {
                name: museum?.name,
                url: `/museum/${exhibition?.museumId}`,
            },
        },
    };
    useEffect(() => {
        return onClose;
    }, []);

    const wrapperId = "scrollytelling-wrapper-container";
    return (
        <ScrollytellingProvider>
            <WithScrollytellingMicroFrontend>
                <div className={classes.root} id={wrapperId}>
                    <Scrollytelling
                        data={data}
                        rootId={wrapperId}
                        onClose={onClose}
                    />
                </div>
            </WithScrollytellingMicroFrontend>
        </ScrollytellingProvider>
    );
};
