import {ExhibitionCardSizes} from "../exhibition/helpers";
import React from "react";
import {ExperienceMediumContent} from "./ExperienceMediumContent";
import {ExperienceSmallContent} from "./ExperienceSmallContent";
import {ExperienceLargeContent} from "./ExperienceLargeContent";

export const ExperienceCardContent = ({siblings, size, children}) => {

    const sizedCardContent = {
        [ExhibitionCardSizes.SMALL]: (
            <ExperienceSmallContent
                siblings={siblings}
            >
                {children}
            </ExperienceSmallContent>
        ),
        [ExhibitionCardSizes.MEDIUM]: (
            <ExperienceMediumContent siblings={siblings}>
                {children}
            </ExperienceMediumContent>
        ),
        [ExhibitionCardSizes.LARGE]: (
            <ExperienceLargeContent>
                {children}
            </ExperienceLargeContent>
        )
    }

    return sizedCardContent[size]
}